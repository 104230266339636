import { Vector2 } from 'three';

import { assertDefined } from 'shared/utils/debug';

import type { TileMap } from './TileMap';
import type TileLoader from './TileLoader';
import type { ItemLodUniforms } from './LodMaterial';
import { computeImageMapSize } from './helpers';
import type { ExtraImageData, ImageData, ImageId, ImageLodData } from './interfaces';

type InitialImageData = Partial<ImageData> & Pick<ImageData, 'id'> & Pick<ImageData, 'extraData'>;
function isLoaded(state: InitialImageData): state is ImageData {
  return state.lodData !== undefined && state.mapPosition !== undefined;
}

export default class ImageRegistry {
  private state: Map<ImageId, InitialImageData> = new Map();

  constructor(private tileMap: TileMap, private tileLoader: TileLoader) {}

  private loading = false;

  isLoading() {
    return this.loading;
  }

  updateImages(
    images: Map<ImageId, ExtraImageData>,
    lodLoader: (ids: number[]) => Promise<ImageLodData[]>,
  ) {
    const staleIds = Array.from(this.state.keys()).filter(id => !images.has(id));
    staleIds.forEach(id => {
      this.disposeState(this.state.get(id)!);
      this.state.delete(id);
    });

    const newImages = Array.from(images.entries()).filter(([id, _]) => !this.state.has(id));
    newImages.forEach(([id, extraData]) => {
      this.state.set(id, { id, extraData });
    });

    images.forEach((extraData, id) => {
      const state = this.state.get(id);
      assertDefined(state, 'Updating unknown image');
      if (
        state.extraData !== undefined &&
        (!extraData.position.isEqual(state.extraData.position) ||
          !extraData.size.equals(state.extraData.size))
      ) {
        this.tileLoader.updateImage(id, extraData);
      }
      state.extraData = extraData;
    });

    if (newImages.length > 0) {
      const uniqueImageIds = Array.from(new Set(newImages.map(([_, it]) => it.lodId)).values());
      this.loading = true;
      lodLoader(uniqueImageIds).then(data => {
        data.forEach(lodData => {
          this.state.forEach(state => {
            if (state.extraData.lodId === lodData.id && !isLoaded(state)) {
              this.finalizeState(state, lodData);
              this.tileLoader.addImage(state);
              this.onImageReadyCallback(state.id);
            }
          });
        });
        this.loading = false;
      });
    }
  }

  private onImageReadyCallback: (id: ImageId) => void = () => {};

  onImageReady(callback: (id: ImageId) => void) {
    this.onImageReadyCallback = callback;
  }

  private finalizeState(
    state: InitialImageData,
    lodData: ImageLodData,
  ): asserts state is ImageData {
    state.lodData = lodData;
    state.mapPosition = this.tileMap.reserveSpace(computeImageMapSize(state.lodData));
  }

  private disposeState(state: InitialImageData) {
    if (isLoaded(state)) {
      this.tileLoader.removeImage(state);
      this.tileMap.releaseSpace(state.mapPosition, computeImageMapSize(state.lodData));
    }
  }

  getImageUniforms(id: ImageId): Partial<ItemLodUniforms> | undefined {
    return this.stateToUniforms(this.state.get(id));
  }

  private stateToUniforms(state?: InitialImageData) {
    if (state === undefined || !isLoaded(state)) return undefined;
    const lodData = state.lodData;
    const itemMapSize = computeImageMapSize(lodData);

    const fullSize = new Vector2(...lodData.full_size);
    const fitSize = new Vector2(...lodData.fit_size);
    const contentRatio = fitSize.divide(fullSize);
    const contentOffset = new Vector2(1.0, 1.0).sub(contentRatio).multiplyScalar(0.5);

    return {
      itemMapCoordinates: { value: state.mapPosition },
      itemMapSize: { value: itemMapSize },
      contentRatio: { value: contentRatio },
      contentOffset: { value: contentOffset },
    };
  }
}
