import {SearchSettings} from '../interfaces/search-setting.interface';
import {FontLoader} from '../font_loader';

export class SearchNoResults {
  private searchSettings: SearchSettings;
  private mainContainer: HTMLElement;

  constructor() {
    this.initNoResults();
  }

  private initNoResults() {
    this.mainContainer = document.getElementById('search-results-container');
    const noResultElement = document.createElement('div');
    const noResultsHtml = this.applySettings();

    if (noResultsHtml) {
      this.mainContainer.append(noResultElement);
      noResultElement.outerHTML = noResultsHtml;
    }
  }

  private applySettings() {
    if (!this.searchSettings) {
      return;
    }

    this.clearNoResults();

    if (this.searchSettings.search_setting?.no_result_search_setting_font) {
      FontLoader.mountCustomFonts([this.searchSettings.search_setting.no_result_search_setting_font]);
    }

    return `
    <div class="no-results ${!this.searchSettings.search_setting?.no_result_search_title ? 'without-title' : ''}">
      ${
        this.searchSettings.search_setting.no_result_image?.url
          ? `<img src="${this.searchSettings.search_setting.no_result_image.url}" aria-hidden="true" alt="no results icon">`
          : ''
      }
      ${
        this.searchSettings.search_setting?.no_result_search_title
          ? `<span>${this.searchSettings.search_setting?.no_result_search_title}</span>`
          : ''
      }
    </div>
    `;
  }

  private clearNoResults() {
    const noResultsContainer = document.querySelector<HTMLElement>('.no-results');
    noResultsContainer?.remove();
  }

  updateSearchSettings(settings: SearchSettings) {
    this.searchSettings = settings;
    this.initNoResults();
  }
}
