import {L10nUtils} from '../utils/l10n_utils';
import {loader} from '../components/loader';
import {EcommerceOverlaySettings} from '../interfaces/planogram.interface';

export const shoppingCartPanelView = (
  settings: EcommerceOverlaySettings,
  isMultipassEnabled: boolean,
  isHarryPotterClient: boolean
) => {
  const closeIconSVG = `
    <svg xmlns="http://www.w3.org/2000/svg" width="34" height="34" viewBox="0 0 34 34">
      <title>${L10nUtils.l10n('shopping-cart.panel.close-button.alt-text')}</title>
        <g fill="none" fill-rule="evenodd">
          <g transform="translate(-1309.000000, -23.000000) translate(1006.000000, 0.000000) translate(304.000000, 24.000000)">
            <circle cx="16" cy="16" r="16" stroke="currentColor" stroke-opacity=".6" stroke-width=".5"/>
            <g fill="currentColor" opacity=".6">
              <path d="M3.104 2.397L8 7.292l4.896-4.895c.196-.196.512-.196.708 0 .195.195.195.512 0 .707l-4.72 4.72L8.709 8l4.896 4.896c.195.195.195.512 0 .707-.095.096-.222.147-.354.147-.132 0-.258-.051-.354-.147L8 8.708l-4.896 4.895c-.095.096-.222.147-.354.147-.131 0-.258-.051-.353-.147-.196-.195-.196-.512 0-.707l4.719-4.72L7.293 8 2.397 3.104c-.196-.195-.196-.512 0-.707.195-.196.512-.196.707 0z"
                        transform="translate(8.000000, 8.000000)"/>
            </g>
          </g>
        </g>
    </svg>`;

  return `
    <div class="app-shopping-cart-panel-wrapper ${isHarryPotterClient ? 'app-shopify-harry-potter-template' : ''}">
      <div class="app-shopping-cart-panel-container">
        <div class="app-shopping-cart-header-wrapper">
          <h2 class="app-shopping-cart-title">${L10nUtils.l10n('shopping-cart.panel.title')}</h2>
          <div class="app-shopping-cart-close-button">${closeIconSVG}</div>
        </div>
        <div class="app-shopping-cart-items-wrapper allow-scroll">
          <div class="app-shopping-cart-empty-cart-wrapper">
            <span class="empty-cart-title">${L10nUtils.l10n('shopping-cart.panel.products-list.empty')}</span>
          </div>
          <div class="app-shopping-cart-items-container"></div>
        </div>
        <div class="app-shopping-cart-footer-wrapper">
          <div class="app-shopping-cart-subtotal-wrapper">
              <div class="app-shopping-cart-subtotal-title-wrapper">
                <span class="app-shopping-cart-subtotal-title">${L10nUtils.l10n(
                  'shopping-cart.panel.footer.subtotal'
                )}</span>
              </div>
              <div class="app-shopping-cart-subtotal-value-wrapper">
                <span class="app-shopping-cart-subtotal-value">$0</span>
              </div>
          </div>
          <div
          class="app-shopping-cart-checkout-button app-disabled
          ${settings?.ecommerce_buttons[0].use_border ? ' with-border' : ''}
          ${settings?.ecommerce_buttons[0].use_border_disabled ? ' disabled-with-border' : ''}">
            <span>${L10nUtils.l10n('shopping-cart.panel.footer.button')}</span>
            ${isMultipassEnabled ? loader({}) : ''}
          </div>
        </div>
        <div class="app-shopping-cart-loader">
          ${loader({message: L10nUtils.l10n('shopping-cart.loader.text')})}
        </div>
      </div>
    </div>
    <div class="app-shopping-cart-panel-backdrop"></div>
  `;
};
