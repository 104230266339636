import {SphereItem} from '../sphere_item';
import {UrlUtils} from '../api/urls';
import {ImageMetaData} from '../interfaces/planogram.interface';
import {Overlay, OverlayOptions} from '../overlay';
import {FullScreenButton} from '../components/overlay-fullscreen-mode';

export class ImageOverlay {
  private fullscreenButton: FullScreenButton;

  constructor(
    private wrapper: HTMLElement,
    options: OverlayOptions | undefined,
    item: SphereItem,
    private closeCallback: Function
  ) {
    const data = item.data as ImageMetaData;
    const thumbnailUrl = (data.picture || data)?.thumbnails?.VARIANT_LARGE_WEBP;

    const imageUrl = UrlUtils.generateThumbnailURL(data.picture?.url || data.url || '', thumbnailUrl);

    const container = document.createElement('div');
    container.classList.add('app-image-overlay-container');
    container.setAttribute('aria-live', 'polite');

    const closeButton = document.createElement('div');
    closeButton.classList.add('overlay-button', 'overlay-button-close');
    closeButton.setAttribute('role', 'button');
    closeButton.setAttribute('tabIndex', '0');
    closeButton.appendChild(document.createElement('span'));
    container.appendChild(closeButton);

    const imageWrapper = document.createElement('div');
    const image = document.createElement('img');
    imageWrapper.classList.add('image-wrapper');
    image.setAttribute('aria-label', data.description ?? '');
    image.setAttribute('src', imageUrl);
    imageWrapper.appendChild(image);
    container.appendChild(imageWrapper);

    const seoTitle = data.accessibility?.title;
    if (options?.title && seoTitle !== undefined) {
      container.classList.add('with-title');
      Overlay.showSeoTitle(imageWrapper, seoTitle);
    }

    if (options?.fullscreen) {
      const fullscreenContainer = document.createElement('div');
      fullscreenContainer.classList.add('image-fullscreen-container');
      imageWrapper.appendChild(fullscreenContainer);
      this.fullscreenButton = new FullScreenButton(fullscreenContainer);
    }

    const backdrop = document.createElement('div');
    backdrop.classList.add('app-overlay-backdrop');

    this.wrapper.replaceChildren(container, backdrop);
  }

  closeOverlay(e: Event): void {
    e.preventDefault();
    e.stopPropagation();
    if (this.closeCallback) {
      this.closeCallback();
    } else {
      console.error('"Close" function is not set up');
    }
  }

  handleClick(e) {
    return e.target.tagName !== 'IMG';
  }
}
