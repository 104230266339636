import { MathUtils } from "three";

import type { PlanogramConfig } from "shared/interfaces/Types";

export const HEIGHT = 2500;
export const WIDTH = 10000;

const planogramConfig: PlanogramConfig = {
  HEIGHT,
  WIDTH,
  EQUATOR_RADIUS: WIDTH / (Math.PI * 2.0),
  SHELL_RADIUS: (WIDTH / (Math.PI * 2.0)) * 3.0,
  ALPHA: MathUtils.degToRad(10.0),
  VIEWABLE_LIMIT_TOP: 3350,
  VIEWABLE_LIMIT_BOTTOM: 1650,
  PAGES_WIDE: 256,
  PAGES_HIGH: 64,
};

export default planogramConfig;
