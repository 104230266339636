import {Options} from '@splidejs/splide';

import {ProductInfoBase} from './product-info-base_overlay';
import {ProductSlides} from '../../product_slides';
import {productInfoSothebysView} from './product-info-sothebys.view';

export class ProductInfoSothebys extends ProductInfoBase {
  protected initProductPrice() {
    if (this.product.price_usd) {
      const priceContainer = document.querySelector<HTMLElement>('.app-product-price');
      const priceEl = priceContainer.querySelector<HTMLElement>('.product-price-value');
      if (priceEl) {
        priceEl.prepend((+this.product.price_usd).toLocaleString('en-US'));
        priceContainer.classList.remove('is-hidden');
      }
    }
  }

  handleClick(e) {
    if (this.isViewGallery()) {
      const tokens = ['app-product-overlay', 'app-product-content-wrapper', 'app-product-carousel'];
      if (e.target.closest('#app-image-carousel')) {
        tokens.push('splide__slide');
      }
      return tokens.some(token => e.target.classList.contains(token));
    } else {
      return e.target === this.mainContainer;
    }
  }

  protected setItemContent() {
    this.container.innerHTML = productInfoSothebysView({...this.product}, this.item.planogram.name);
  }

  protected setThumbsSwitchLength() {
    if (this.gallerySplide && this.thumbnailSplide) {
      const thumbsLength = this.thumbnailSplide.length - 1;
      const maxLength = 5;
      const perPage = Math.min(thumbsLength, maxLength);

      this.thumbnailSplide.options = {perPage, focus: perPage - 1};
      this.gallerySplide.emit('resize', {});
      this.thumbnailSplide.emit('resize', {});
    }
  }

  protected getThumbsCarouselConfig(productSlides: ProductSlides): Options {
    return {
      autoWidth: true,
      autoHeight: true,
      gap: '1.75vw',
      speed: 150,
      rewind: true,
      pagination: false,
      isNavigation: true,
      lazyLoad: 'sequential',
      start: productSlides.imageIndex,
      perPage: Math.min(productSlides.length - 1, 5),
      focus: 4,
      perMove: 1,
      keyboard: false,
      breakpoints: {
        1200: {
          gap: 20
        }
      }
    };
  }
}
