const searchParams = new URL(window.location.href).searchParams;
const debug = searchParams.has('debug');

export function isDebug() {
  return debug;
}

export function hasUrlFlag(flag: string): boolean {
  return searchParams.has(flag);
}

export function hasDebugFlag(flag: string): boolean {
  return debug && searchParams.has(flag);
}

export const debugLog = debug
  ? (...params: unknown[]) => {
      // eslint-disable-next-line no-console
      console.debug(...params);
    }
  : (..._params: unknown[]) => {};

export const assertTrue: (condition: boolean, error: string) => asserts condition is true = debug
  ? function (condition: boolean, error: string): asserts condition is true {
      if (!condition) throw new Error(error);
    }
  : function (_condition: boolean, _error: string): asserts _condition is true {};

export const assertStatement: (statement: () => boolean, error: string) => void = debug
  ? function (statement: () => boolean, error: string) {
      if (!statement()) throw new Error(error);
    }
  : function () {};

export function assertDefined<T>(value: T | undefined, error: string): asserts value is T {
  assertTrue(value !== undefined, error);
}

export function assertNotNull<T>(value: T | null, error: string): asserts value is T {
  assertTrue(value !== null, error);
}

export function debugParameter<T>(key: string, defaultValue: T, parser: (value: string) => T): T {
  if (!debug) return defaultValue;
  const value = searchParams.get(key);
  if (value == null) return defaultValue;
  else return parser(value);
}

export function debugIntParameter(key: string, defaultValue: number): number {
  return debugParameter(key, defaultValue, parseInt);
}

export function debugFloatPrameter(key: string, defaultValue: number): number {
  return debugParameter(key, defaultValue, parseFloat);
}

export const unreachable: (error: string) => never = debug
  ? ((_error => {}) as (error: string) => never)
  : error => {
      throw new Error(error);
    };

const debugCommands: Record<string, Function> = {};
// eslint-disable-next-line @typescript-eslint/no-explicit-any
(window as any).debug = debugCommands;
export const debugCommand: (key: string, command: Function) => void = debug
  ? (key: string, command: Function) => {
      debugCommands[key] = command;
    }
  : () => {};
