import type { Texture, WebGLRenderer } from 'three';
import {
  ClampToEdgeWrapping,
  DataTexture,
  LinearFilter,
  RGBAFormat,
  UVMapping,
  UnsignedByteType,
  Vector2,
} from 'three';

import { assertStatement, assertTrue, debugCommand } from 'shared/utils/debug';

import { TILE_SIZE } from './parameters';

export type SlotIndex = number;

export interface TileStorage {
  slot: SlotIndex;
  cost: number;
  loaded: Promise<void>;
}

interface TextureCopyQueue {
  texture: Texture;
  slot: SlotIndex;
  resolve: () => void;
}

export default class PhysicalTextures {
  // TODO: use DataArrayTexture?
  readonly textures: Array<DataTexture> = []; // indices are texture ids
  private occupied: Array<number> = []; // indices are slot ids, values are amount of times the slot is used
  private slotCache: Map<Texture, number> = new Map(); // maps textures to their slot ids
  private textureCache: Array<Texture | undefined> = []; // maps slot ids to textures, used only to clean slotCache correctly
  private _freeSlots: number;
  private uploadQueue: TextureCopyQueue[] = [];

  public readonly totalSlots: number;
  public get freeSlots(): number {
    return this._freeSlots;
  }

  constructor(
    readonly textureCount: number,
    readonly textureSize: number,
    private renderer: WebGLRenderer,
    private frameUploadLimit: number,
  ) {
    const side = this.textureTileSide();
    this.totalSlots = side * side * textureCount;
    this._freeSlots = this.totalSlots;
    for (let i = 0; i < this.totalSlots; i++) this.occupied.push(0);

    const data = new Uint8Array(4 * textureSize * textureSize).fill(0);
    for (let i = 0; i < textureCount; i++) {
      const texture = new DataTexture(
        data,
        textureSize,
        textureSize,
        RGBAFormat,
        UnsignedByteType,
        UVMapping,
        ClampToEdgeWrapping,
        ClampToEdgeWrapping,
        LinearFilter,
        LinearFilter,
      );
      texture.needsUpdate = true;
      this.textures.push(texture);
    }

    debugCommand('freeSlots', () => this.freeSlots);
  }

  storeTile(texture: Texture): TileStorage {
    if (this._freeSlots === 0) throw new Error('Physical textures are full');
    const usedSlot = this.findUsedSlot(texture);
    if (usedSlot !== undefined) {
      if (this.occupied[usedSlot] === 0) this._freeSlots--;
      this.occupied[usedSlot]++;
      return {
        slot: usedSlot,
        cost: 0,
        loaded: Promise.resolve(),
      };
    }
    const slot = this.findFreeSlot();
    this.occupied[slot]++;
    this._freeSlots--;
    this.slotCache.set(texture, slot);
    this.textureCache[slot] = texture;
    return {
      slot,
      cost: 1,
      loaded: new Promise(resolve => {
        this.uploadQueue.push({ texture, slot, resolve });
      }),
    };
  }

  freeSlot(slot: SlotIndex) {
    this.occupied[slot]--;
    assertTrue(this.occupied[slot] >= 0, 'Freeing a free slot');
    if (this.occupied[slot] === 0) this._freeSlots++;
    const t = this.textureCache[slot];
    if (t !== undefined) this.slotCache.delete(t);
  }

  slotUseCount(slot: SlotIndex): number {
    return this.occupied[slot];
  }

  update() {
    const noWork = this.uploadQueue.length === 0;
    this.uploadQueue.splice(0, this.frameUploadLimit).forEach(({ texture, slot, resolve }) => {
      const physicalTexture = this.textures[this.tileSlotTexture(slot)];
      const position = this.tileSlotCoordinate(slot);
      // in curator, atlas tiles are smaller than TILE_SIZE, and UV offsets count from the bottom of the tile
      const textureHeight = texture.image.naturalHeight;
      assertStatement(() => Number.isInteger(Math.log2(textureHeight)), 'Invalid texture height');
      position.y += TILE_SIZE - textureHeight;
      this.renderer.copyTextureToTexture(position, texture, physicalTexture);
      resolve();
    });
    return noWork;
  }

  private findUsedSlot(texture: Texture): SlotIndex | undefined {
    return this.slotCache.get(texture);
  }

  private findFreeSlot(): SlotIndex {
    const free = this.occupied.findIndex(v => v === 0);
    if (free === -1) throw new Error('Physical textures are full');
    return free;
  }

  private textureTileSide() {
    return Math.floor(this.textureSize / TILE_SIZE);
  }

  tileSlotTexture(slot: number) {
    const side = this.textureTileSide();
    return Math.floor(slot / (side * side));
  }

  tileSlotCoordinate(slot: number) {
    const side = this.textureTileSide();
    const textureSlot = slot % (side * side);
    const slotPosition = new Vector2(textureSlot % side, Math.floor(textureSlot / side));
    return slotPosition.multiplyScalar(TILE_SIZE);
  }

  dispose() {
    this.textures.forEach(it => it.dispose());
    this.uploadQueue = [];
  }
}
