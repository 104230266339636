import { Vector2 } from 'three';

import {
  assertDefined,
  assertStatement,
  assertTrue,
  debugCommand,
  debugLog,
  isDebug,
} from 'shared/utils/debug';
import type PlanogramPoint from 'shared/utils/PlanogramPoint';

import type { TextureCache } from './TextureCache';
import type { TileMap } from './TileMap';
import { TRANSPARENT_TILE } from './TileMap';
import type PhysicalTextures from './PhysicalTextures';
import type { TilePriority } from './TilePriority';
import type { LodLevelMask } from './helpers';
import {
  addLevel,
  bestLevel,
  iterateLevels,
  removeLevel,
  alignMapOffset,
  computeImageMapSize,
  computeTileMapLocation,
  imagePlanogramChunkSize,
  imageChunkPlanogramPosition,
  iterateChunkPoints,
  levelRatio,
  lowestNonAtlasLevel,
  mapOffsetToIndex,
  nearestLevel,
  pixelRatioForLevel,
  computeLevelMapSize,
  unloadedLevelEquivalent,
  isAtlas,
  worstLevel,
} from './helpers';
import type { ExtraImageData, ImageData, ImageId, ImageLodData } from './interfaces';
import ChunkSpatialTree from './ChunkSpatialTree';
import MultiSet from './MultiSet';
import type { SlotIndex } from './PhysicalTextures';

// at least one slot is required to run any operations
const OPERATION_RUNTIME_SLOTS = 1;

interface Operation {
  name: string;
  reservedSlots: number;
  usedSlots: () => number; // Only guaranteed to return correct result after preload
  affectedChunkIds: Array<number>;
  preload: () => Promise<void>;
  cancel: () => void; // Must prevent side-effects if called before execute()
  execute: () => void;
}

async function runOperation(operation: Operation, usedSlotsLimit: number): Promise<boolean> {
  try {
    await operation.preload();
    if (operation.usedSlots() > usedSlotsLimit) {
      operation.cancel();
      return false;
    }
    operation.execute();
    return true;
  } catch (e) {
    if (e.message !== 'Canceled') {
      console.warn('Failed operation', e.toString());
      try {
        operation.cancel();
      } catch (e) {
        console.warn('Failed to cancel operation', e.toString());
      }
    }
    return false;
  }
}

function composeOperations(name: string, operations: Operation[]): Operation {
  const preload = () => Promise.all(operations.map(it => it.preload())).then(_ => {});
  const execute = () => operations.map(it => it.execute());

  return {
    name,
    reservedSlots: operations.reduce((sum, it) => sum + it.reservedSlots, 0),
    usedSlots: () => operations.reduce((sum, it) => sum + it.usedSlots(), 0),
    affectedChunkIds: operations.reduce<number[]>(
      (union, it) => union.concat(it.affectedChunkIds),
      [],
    ),
    preload,
    execute,
    cancel: () => {
      operations.forEach(it => it.cancel());
    },
  };
}

class CancelOperation extends Error {
  constructor() {
    super('Canceled');
  }
}

enum Direction {
  Upgrade = 1,
  Downgrade = -1,
}

interface ChunkBlacklist {
  chunkId: number;
  timestamp: number;
}

// TODO: clean up chunkId vs chunk index vs chunk coords mess

export default class TileLoader {
  private images: Map<ImageId, ImageData> = new Map();
  private currentState: LodLevelMask[] = [];
  private loadingState: LodLevelMask[] = [];
  private slotMap: Array<Map<number, SlotIndex>> = []; // level => (chunkId => slotId)
  private chunkTree: ChunkSpatialTree;
  constructor(
    private tileMap: TileMap,
    private textureCache: TextureCache,
    private physicalTextures: PhysicalTextures,
    private tilePriority: TilePriority,
    private cdnUrl: string,
    private loadingLimit: number,
    private unloadedLevelBias: number,
    private blacklistDuration: number,
  ) {
    this.chunkTree = new ChunkSpatialTree(this.tilePriority);
    debugCommand('lodOperations', () => this.loadingOperations);
    debugCommand('lodLoader', () => this);
  }

  addImage(image: ImageData) {
    this.images.set(image.id, {
      ...image,
      extraData: {
        ...image.extraData,
        position: image.extraData.position.clone(),
        size: image.extraData.size.clone(),
      },
    });
    const unloadedLevel = unloadedLevelEquivalent(image.lodData, this.unloadedLevelBias);
    const defaultPixelRatio = pixelRatioForLevel(unloadedLevel, imagePlanogramChunkSize(image));
    iterateChunkPoints(image, (mapOffset, planogramPosition) => {
      const chunkId = this.tileMap.chunkId(mapOffset);
      this.currentState[chunkId] = 0;
      this.loadingState[chunkId] = 0;
      this.chunkTree.insert(planogramPosition, {
        point: planogramPosition,
        chunkId,
        minPixelPlanogramRatio: defaultPixelRatio,
        maxPixelPlanogramRatio: defaultPixelRatio,
        canUpgrade: true,
        canDowngrade: false,
        someNotLoaded: true,
        someLoaded: false,
        minDistanceToAtlas: 0,
        maxDistanceToAtlas: 0,
        distanceToUnload: 0,
      });
    });
    this.tileMap.storeTileLocation(
      image.mapPosition,
      computeImageMapSize(image.lodData),
      TRANSPARENT_TILE,
    );
  }

  updateImage(id: ImageId, extraData: ExtraImageData) {
    const image = this.images.get(id);
    if (image === undefined) return;
    const newImage = {
      ...image,
      extraData: {
        ...extraData,
        position: extraData.position.clone(),
      },
    };
    iterateChunkPoints(image, (mapOffset, planogramPosition) => {
      const chunkId = this.tileMap.chunkId(mapOffset);
      const treeData = this.chunkTree.find(planogramPosition, it => it.chunkId === chunkId);
      assertDefined(treeData, 'Image chunk is missing from the tree');
      this.chunkTree.remove(planogramPosition, it => it === treeData);
      const newPosition = imageChunkPlanogramPosition(newImage, mapOffset);
      treeData.point = newPosition;
      this.chunkTree.insert(newPosition, treeData);
      this.updateChunkTreeData(newImage, chunkId, newPosition);
    });
    this.images.set(id, newImage);
  }

  removeImage(image: ImageData) {
    iterateChunkPoints(image, (mapOffset, planogramPosition) => {
      const chunkId = this.tileMap.chunkId(mapOffset);
      iterateLevels(
        this.loadingState[chunkId] | this.currentState[chunkId],
        // TODO: does this cancel all loading tiles?
        level => this.cleanSlot(image, level, chunkId),
      );
      this.chunkTree.remove(planogramPosition, it => it.chunkId === chunkId);
      this.loadingState[chunkId] = 0;
      this.currentState[chunkId] = 0;
    });
    this.images.delete(image.id);
  }

  private alignChunkId(image: ImageData, chunkId: number, level: number) {
    return this.tileMap.chunkId(alignMapOffset(image, level, this.tileMap.chunkOffset(chunkId)));
  }

  private coveredTiles(lodData: ImageLodData, alignedChunkId: number, level: number): number[] {
    const result: number[] = [];
    const rootOffset = this.tileMap.chunkOffset(alignedChunkId);
    const offset = new Vector2();
    const size = levelRatio(lodData, level);
    for (let x = 0; x < size; x++) {
      for (let y = 0; y < size; y++) {
        result.push(this.tileMap.chunkId(offset.set(x, y).add(rootOffset)));
      }
    }
    return result;
  }

  private getTile(image: ImageData, level: number, chunkId: number) {
    const lodLevel = image.lodData.curator_lods[level];
    if (isAtlas(lodLevel)) return lodLevel.textures[0];

    const mapOffset = this.tileMap.chunkOffset(chunkId);
    const index = mapOffsetToIndex(image, mapOffset, level);
    const tile = lodLevel.textures[index];
    return tile;
  }

  private loadOperation(image: ImageData, level: number, chunkId: number): Operation {
    const mapOffset = this.tileMap.chunkOffset(chunkId);
    const index = mapOffsetToIndex(image, mapOffset, level);
    const lodLevel = image.lodData.curator_lods[level];
    const tile = this.getTile(image, level, chunkId);
    assertDefined(tile, 'Invalid tile index');

    const tileSize = levelRatio(image.lodData, level);
    const tileMapLocation = computeTileMapLocation(image.lodData, level, index, image.mapPosition);

    const imageId = image.id;

    if (tile === null) {
      return {
        name: 'empty load',
        reservedSlots: 0,
        usedSlots: () => 0,
        affectedChunkIds: this.coveredTiles(image.lodData, chunkId, level),
        preload: () => {
          const image = this.images.get(imageId);
          assertDefined(image, 'Image was removed during operation');
          this.addLevel(image, level, chunkId, true);
          return Promise.resolve();
        },
        execute: () => {
          const image = this.images.get(imageId);
          assertDefined(image, 'Image was removed during operation');
          this.tileMap.storeTileLocation(tileMapLocation, tileSize, TRANSPARENT_TILE);
          this.addLevel(image, level, chunkId, false);
        },
        cancel: () => {},
      };
    }

    const url = `${this.cdnUrl}/${lodLevel.url_start}/${tile.url}.webp`;
    let canceled = false;
    let slot: number | undefined;
    let cost = 0;

    const preload = () => {
      if (canceled) throw new CancelOperation();
      this.addLevel(image, level, chunkId, true);
      return this.textureCache
        .load(url)
        .then(texture => {
          if (canceled) throw new CancelOperation();
          const storage = this.physicalTextures.storeTile(texture);
          slot = storage.slot;
          cost = storage.cost;
          return storage.loaded;
        })
        .catch(e => {
          const image = this.images.get(imageId);
          assertDefined(image, 'Image was removed during operation');
          // TODO: if the level was already loaded, this can erase too much data
          this.removeLevel(image, level, chunkId, true);
          throw e;
        });
    };

    const execute = () => {
      if (canceled) throw new CancelOperation();
      assertDefined(slot, "preload didn't finish");

      const image = this.images.get(imageId);
      assertDefined(image, 'Image was removed during operation');

      this.setTileSlot(level, chunkId, slot);
      const tileSlotCoordinate = this.physicalTextures.tileSlotCoordinate(slot);
      // TODO: use best level from this.currentState
      this.tileMap.storeTileLocation(tileMapLocation, tileSize, {
        textureIndex: this.physicalTextures.tileSlotTexture(slot),
        x: tileSlotCoordinate.x,
        y: tileSlotCoordinate.y,
        size: tileSize,
        ratio: tile.uv?.width ?? 1.0,
        atlasX: tile.uv?.x ?? 0.0,
        atlasY: 1.0 - (tile.uv?.y ?? 1.0) - (tile.uv?.height ?? 0.0),
      });
      this.addLevel(image, level, chunkId, false);
    };

    const cancel = () => {
      canceled = true;
      this.textureCache.cancel(url);
      if (slot !== undefined) {
        this.physicalTextures.freeSlot(slot);
      }
      const image = this.images.get(imageId);
      assertDefined(image, 'Image was removed during operation');
      this.removeLevel(image, level, chunkId, true);
    };

    return {
      name: 'load',
      reservedSlots: 1,
      usedSlots: () => cost,
      affectedChunkIds: this.coveredTiles(image.lodData, chunkId, level),
      preload,
      execute,
      cancel,
    };
  }

  private cleanSlot(image: ImageData, level: number, chunkId: number) {
    const mapOffset = this.tileMap.chunkOffset(chunkId);
    const index = mapOffsetToIndex(image, mapOffset, level);
    const lodLevel = image.lodData.curator_lods[level];
    const tile = lodLevel.textures[index];
    assertDefined(tile, 'Invalid tile index');

    if (tile !== null && !isAtlas(lodLevel)) {
      const url = `${this.cdnUrl}/${lodLevel.url_start}/${tile.url}.webp`;
      this.textureCache.unload(url);
    }

    const oldSlot = this.popTileSlot(level, chunkId);
    if (oldSlot !== undefined) this.physicalTextures.freeSlot(oldSlot);
  }

  private unloadOperation(image: ImageData, level: number, chunkId: number): Operation {
    const mapOffset = this.tileMap.chunkOffset(chunkId);
    const index = mapOffsetToIndex(image, mapOffset, level);
    const lodLevel = image.lodData.curator_lods[level];
    const tile = lodLevel.textures[index];
    assertDefined(tile, 'Invalid tile index');

    const imageId = image.id;
    const tileSize = levelRatio(image.lodData, level);
    const tileMapLocation = computeTileMapLocation(image.lodData, level, index, image.mapPosition);
    const slot = this.getTileSlot(level, chunkId);
    const cantEmptySlot = (slot && this.physicalTextures.slotUseCount(slot) > 1) ?? false;

    const cost = this.isEmptySlot(level, chunkId) ? 0 : -1 + (cantEmptySlot ? 1 : 0);

    return {
      name: 'unload',
      usedSlots: () => cost,
      reservedSlots: 0,
      affectedChunkIds: this.coveredTiles(image.lodData, chunkId, level),
      preload: () => {
        this.removeLevel(image, level, chunkId, true);
        return Promise.resolve();
      },
      execute: () => {
        const image = this.images.get(imageId);
        assertDefined(image, 'Image was removed during operation');
        this.cleanSlot(image, level, chunkId);
        this.removeLevel(image, level, chunkId, false);
        this.tileMap.storeTileLocation(tileMapLocation, tileSize, TRANSPARENT_TILE);
      },
      cancel: () => {
        this.addLevel(image, level, chunkId, true);
      },
    };
  }

  private upgradeOperation(image: ImageData, currentLevel: number, chunkId: number): Operation {
    const newLevel = currentLevel - 1;
    const currentChunkId = this.alignChunkId(image, chunkId, currentLevel);
    const mapOffset = this.tileMap.chunkOffset(currentChunkId);

    const currentLevelRatio = levelRatio(image.lodData, currentLevel);
    const newLevelRatio = levelRatio(image.lodData, newLevel);
    const ratio = currentLevelRatio / newLevelRatio;
    assertTrue(ratio >= 1, 'New level must be smaller');

    const newChunkIds: number[] = [];
    const offset = new Vector2();
    for (let x = 0; x < ratio; x++) {
      for (let y = 0; y < ratio; y++) {
        offset.set(x, y).multiplyScalar(newLevelRatio).add(mapOffset);
        newChunkIds.push(this.tileMap.chunkId(offset));
      }
    }

    const operations = [this.unloadOperation(image, currentLevel, currentChunkId)].concat(
      newChunkIds.map(id => this.loadOperation(image, newLevel, id)),
    );

    return composeOperations('upgrade', operations);
  }

  private downgradeOperation(image: ImageData, currentLevel: number, chunkId: number): Operation {
    const newLevel = currentLevel + 1;
    const mapOffset = alignMapOffset(image, newLevel, this.tileMap.chunkOffset(chunkId));

    const currentLevelRatio = levelRatio(image.lodData, currentLevel);
    const newLevelRatio = levelRatio(image.lodData, newLevel);
    const ratio = newLevelRatio / currentLevelRatio;
    assertTrue(ratio >= 1, 'New level must be larger');

    const offset = new Vector2();
    const currentChunkIds: number[] = [];
    for (let x = 0; x < ratio; x++) {
      for (let y = 0; y < ratio; y++) {
        offset.set(x, y).multiplyScalar(currentLevelRatio).add(mapOffset);
        currentChunkIds.push(this.tileMap.chunkId(offset));
      }
    }

    const newChunkId = this.alignChunkId(image, chunkId, newLevel);

    const operations = currentChunkIds
      .map(id => this.unloadOperation(image, currentLevel, id))
      .concat(this.loadOperation(image, newLevel, newChunkId));

    return composeOperations('downgrade', operations);
  }

  private isEmptySlot(level: number, chunkId: number) {
    return this.slotMap[level]?.get(chunkId) === undefined;
  }

  private getTileSlot(level: number, chunkId: number): SlotIndex | undefined {
    return this.slotMap[level]?.get(chunkId);
  }

  private popTileSlot(level: number, chunkId: number): SlotIndex | undefined {
    const slot = this.getTileSlot(level, chunkId);
    if (slot !== undefined) this.slotMap[level].delete(chunkId);
    return slot;
  }

  private setTileSlot(level: number, chunkId: number, slot: SlotIndex) {
    this.slotMap[level] = this.slotMap[level] ?? new Map();
    this.slotMap[level].set(chunkId, slot);
  }

  private updateChunkTreeData(image: ImageData, chunkId: number, chunkPosition: PlanogramPoint) {
    const existingLevels = new Set(image.lodData.curator_lods.map(level => level.lod));
    const unloadedLevel = unloadedLevelEquivalent(image.lodData, this.unloadedLevelBias);
    let minLevel: number | undefined;
    let maxLevel: number | undefined;
    const mask = this.loadingState[chunkId];
    iterateLevels(mask, it => {
      minLevel = Math.min(minLevel ?? +Infinity, it);
      maxLevel = Math.max(maxLevel ?? -Infinity, it);
    });
    const imageTileSize = imagePlanogramChunkSize(image);
    const minRatio = pixelRatioForLevel(maxLevel ?? unloadedLevel, imageTileSize);
    const maxRatio = pixelRatioForLevel(minLevel ?? unloadedLevel, imageTileSize);

    // avoid up/downgrading empty tiles. there is no quality difference
    // downgrading them "costs" memory, which can result in "dead ends"
    const isEmpty = minLevel !== undefined && this.getTile(image, minLevel, chunkId) === null;
    const canUpgrade = maxLevel === undefined || (existingLevels.has(maxLevel - 1) && !isEmpty);
    const canDowngrade = minLevel !== undefined && existingLevels.has(minLevel + 1) && !isEmpty;
    const atlasLevel = lowestNonAtlasLevel(image.lodData).lod + 1;
    const maxDistanceToAtlas = minLevel === undefined ? 0 : atlasLevel - minLevel;
    const minDistanceToAtlas = maxLevel === undefined ? 0 : atlasLevel - maxLevel;
    this.chunkTree.updateData(chunkPosition, data => {
      if (data.chunkId !== chunkId) return data;
      return {
        minPixelPlanogramRatio: minRatio,
        maxPixelPlanogramRatio: maxRatio,
        canUpgrade: canUpgrade,
        canDowngrade: canDowngrade && maxDistanceToAtlas > 0,
        someNotLoaded: maxLevel === undefined,
        someLoaded: maxLevel !== undefined,
        point: data.point,
        chunkId: data.chunkId,
        minDistanceToAtlas: minDistanceToAtlas,
        maxDistanceToAtlas: maxDistanceToAtlas,
        distanceToUnload: maxLevel === undefined ? 0 : worstLevel(image.lodData) - maxLevel + 1,
      };
    });
  }

  private addLevel(image: ImageData, level: number, chunkId: number, loading: boolean) {
    const state = loading ? this.loadingState : this.currentState;
    const mapOffset = alignMapOffset(image, level, this.tileMap.chunkOffset(chunkId));
    const tileSize = levelRatio(image.lodData, level);

    const offset = new Vector2();
    for (let x = 0; x < tileSize; x++) {
      for (let y = 0; y < tileSize; y++) {
        offset.set(x, y).add(mapOffset);
        const id = this.tileMap.chunkId(offset);
        state[id] = addLevel(state[id], level);

        if (loading)
          this.updateChunkTreeData(image, id, imageChunkPlanogramPosition(image, offset));
      }
    }
  }

  private removeLevel(image: ImageData, level: number, chunkId: number, loading: boolean) {
    const state = loading ? this.loadingState : this.currentState;
    const tileSize = levelRatio(image.lodData, level);
    const mapOffset = alignMapOffset(image, level, this.tileMap.chunkOffset(chunkId));

    const offset = new Vector2();
    for (let x = 0; x < tileSize; x++) {
      for (let y = 0; y < tileSize; y++) {
        offset.set(x, y).add(mapOffset);
        const id = this.tileMap.chunkId(offset);
        state[id] = removeLevel(state[id], level);

        if (loading)
          this.updateChunkTreeData(image, id, imageChunkPlanogramPosition(image, offset));
      }
    }
  }

  private findImage(chunkId: number): ImageData {
    // TODO: get image by chunk id in a more efficient way
    let image: ImageData | undefined;
    for (const it of this.images.values()) {
      const imageSize = computeImageMapSize(it.lodData);
      const chunkOffset = this.tileMap.chunkOffset(chunkId);
      chunkOffset.sub(it.mapPosition);
      if (
        0 <= chunkOffset.x &&
        chunkOffset.x < imageSize &&
        0 <= chunkOffset.y &&
        chunkOffset.y < imageSize
      ) {
        image = it;
        break;
      }
    }
    assertDefined(image, 'Chunk from unknown image');
    assertStatement(() => {
      const baseLod = image.lodData.curator_lods.reduce((base, it) =>
        base.lod < it.lod ? base : it,
      );
      const mapSize = computeLevelMapSize(baseLod);
      const chunkOffset = this.tileMap.chunkOffset(chunkId).sub(image.mapPosition);
      return mapSize > chunkOffset.x && mapSize > chunkOffset.y;
    }, "Image doesn't contain chunk");
    return image;
  }

  private findMatchingLevel(image: ImageData, chunkId: number, direction: Direction) {
    const existingLevels = new Set(image.lodData.curator_lods.map(level => level.lod));
    const mask = this.loadingState[chunkId];
    const loadedLevels = new Set();
    iterateLevels(mask, it => loadedLevels.add(it));
    if (loadedLevels.size === 0 && direction === Direction.Upgrade) {
      return nearestLevel(image.lodData, +Infinity);
    }

    let matchingLevel: number | undefined = undefined;
    iterateLevels(mask, loadedLevel => {
      if (
        // allow unloading when downgrading
        (direction === Direction.Downgrade || existingLevels.has(loadedLevel - direction)) &&
        // downgrade worst tiles, upgrade best tiles
        (matchingLevel === undefined || direction * (matchingLevel - loadedLevel) <= 0)
      )
        matchingLevel = loadedLevel;
    });
    return matchingLevel;
  }

  private findBestChunk(
    canUpgrade: boolean,
  ): { chunkId: number; direction: Direction } | undefined {
    if (canUpgrade) {
      const aliased = this.chunkTree.findAliased(this.blacklistSet);
      if (aliased !== undefined) {
        return {
          chunkId: aliased.chunkId,
          direction: Direction.Downgrade,
        };
      }
    }

    const upgrade = this.chunkTree.findUpgrade(this.blacklistSet);
    if (upgrade === undefined) return undefined;
    if (canUpgrade && upgrade !== undefined) {
      return {
        chunkId: upgrade.chunkId,
        direction: Direction.Upgrade,
      };
    }

    const downgrade = this.chunkTree.findDowngrade(this.blacklistSet);
    if (downgrade === undefined) {
      return undefined;
    }

    const postUpgrade = this.tilePriority.estimateWorstUpgrade(upgrade);
    const postDowngrade = this.tilePriority.estimateBestDowngrade(downgrade);
    const swapThreshold = 1.0 + 1e-3;
    // prevent downgrade-upgrade loops by estimating rating of resulting chunks and requiring them to be stable
    if (postDowngrade < postUpgrade * swapThreshold) return undefined;

    return {
      chunkId: downgrade.chunkId,
      direction: Direction.Downgrade,
    };
  }

  private pickOperationForChunk(chunkId: number, direction: Direction): Operation | undefined {
    const image = this.findImage(chunkId);
    const existingLevels = image.lodData.curator_lods;

    const level = this.findMatchingLevel(image, chunkId, direction);
    if (level === undefined) return undefined;

    const alignedId = this.alignChunkId(image, chunkId, level);
    const loadedMask = this.loadingState[alignedId];
    const noLoadedLevels = bestLevel(loadedMask) === +Infinity;
    const hasUpgrade = existingLevels[level - 1] !== undefined;
    const hasDowngrade = existingLevels[level + 1] !== undefined;
    const isAtlasLevel = isAtlas(existingLevels[level]);

    let operation: Operation | undefined = undefined;
    switch (direction) {
      case Direction.Upgrade:
        if (noLoadedLevels) operation = this.loadOperation(image, level, alignedId);
        else if (hasUpgrade) operation = this.upgradeOperation(image, level, alignedId);
        else throw new Error('unhandled upgrade case');
        break;
      case Direction.Downgrade:
        if (hasDowngrade && !isAtlasLevel)
          operation = this.downgradeOperation(image, level, alignedId);
        else operation = this.unloadOperation(image, level, alignedId);
        break;
    }

    if (isDebug() && operation !== undefined) {
      const idString = alignedId.toString().padStart(7);
      const position = imageChunkPlanogramPosition(image, this.tileMap.chunkOffset(chunkId));
      const debugOperation = operation;
      const preload = debugOperation.preload;
      const execute = debugOperation.execute;
      const cancel = debugOperation.cancel;
      let timeout: NodeJS.Timeout;
      debugOperation.preload = () => {
        timeout = setTimeout(() => {
          console.warn(`Operation didn't finish ${debugOperation.name} ${level} ${alignedId}`);
          debugOperation.cancel();
        }, 1e9);
        return preload();
      };
      const log = (prefix: string = '') => {
        debugLog(
          `${prefix}${debugOperation.name.padEnd(9)} ${image.id}:(${position.x}, ${
            position.y
          })[${level}] ${idString} ${loadedMask} cost: ${debugOperation.usedSlots()}`,
        );
      };
      debugOperation.execute = () => {
        try {
          execute();
          log();
        } finally {
          clearTimeout(timeout);
        }
      };
      debugOperation.cancel = () => {
        log('CANCELED ');
        cancel();
      };
    }

    return operation;
  }

  private loadingOperations: Set<Operation> = new Set();
  private affectedChunks: MultiSet<number> = new MultiSet();
  private reservedSlots: number = 0;
  private blacklistQueue: ChunkBlacklist[] = [];
  private blacklistSet: Set<number> = new Set();
  private cleanBlacklist() {
    const now = Date.now();
    this.blacklistQueue = this.blacklistQueue.filter(it => {
      const stale = now - it.timestamp >= this.blacklistDuration;
      if (stale) this.blacklistSet.delete(it.chunkId);
      return !stale;
    });
  }

  update(): boolean {
    if (this.images.size === 0) return true;
    this.cleanBlacklist();
    debugLog(
      'free slots',
      this.physicalTextures.freeSlots,
      'reserved',
      this.reservedSlots,
      'active operations',
      this.loadingOperations.size,
      'affected chunks',
      this.affectedChunks.size,
    );

    let slots: number = 0;
    const updateSlots = () => {
      slots = this.physicalTextures.freeSlots - this.reservedSlots;
    };
    updateSlots();
    while (this.loadingOperations.size < this.loadingLimit && slots > 0) {
      const bestChunk = this.findBestChunk(slots >= 4);
      if (bestChunk === undefined) break;
      const operation = this.pickOperationForChunk(bestChunk.chunkId, bestChunk.direction);
      if (
        operation === undefined ||
        slots < operation.reservedSlots ||
        operation.affectedChunkIds.some(chunkId => this.affectedChunks.has(chunkId))
      )
        break;

      this.loadingOperations.add(operation);
      this.reservedSlots += operation.reservedSlots;
      operation.affectedChunkIds.forEach(chunkId => this.affectedChunks.add(chunkId));
      runOperation(operation, slots - OPERATION_RUNTIME_SLOTS)
        .then(success => {
          if (!success) {
            const blacklistedChunk = {
              chunkId: bestChunk.chunkId,
              timestamp: Date.now(),
            };
            this.blacklistQueue.push(blacklistedChunk);
            this.blacklistSet.add(blacklistedChunk.chunkId);
          }
        })
        .finally(() => {
          this.loadingOperations.delete(operation);
          this.reservedSlots -= operation.reservedSlots;
          operation.affectedChunkIds.forEach(chunkId => this.affectedChunks.remove(chunkId));
        });
      updateSlots();
    }
    return this.loadingOperations.size === 0;
  }

  dispose() {
    this.loadingOperations.forEach(it => it.cancel());
    this.loadingOperations.clear();
  }
}
