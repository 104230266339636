import pipe from 'callbag-pipe';
import subscribe from 'callbag-subscribe';

import {InputFieldComponent} from '../../components/input-field/input-field-component';
import {InputFieldsBaseComponent} from '../input-fields-base/input-fields-base-component';

export class InputFieldsSeekerTShirtComponent extends InputFieldsBaseComponent {
  private input: InputFieldComponent;
  private selectedVariantValueSubjectRef: Function;

  constructor(container, product, shopifyService) {
    super(container, product);

    const inputFields = this.product.input_fields;
    const inputWrapper = this.container.querySelector<HTMLElement>('.input-field-component') as HTMLElement;

    this.input = new InputFieldComponent(inputWrapper, {
      uppercase: true
    });

    this.selectedVariantValueSubjectRef = pipe(
      shopifyService.selectedVariantValueSubject,
      subscribe({
        next: (val: any) => {
          if (val) {
            this.onVariantSelect(val);
          }
        }
      })
    );

    this.setField(inputFields[0].name, this.input);
  }

  private onVariantSelect(val: string) {
    switch (val) {
      case 'XS': {
        this.input.setMaxLength(7);
        break;
      }

      case 'S':
      case 'M': {
        this.input.setMaxLength(8);
        break;
      }

      default: {
        this.input.setMaxLength(9);
      }
    }
  }

  dispose() {
    this.selectedVariantValueSubjectRef();
  }
}
