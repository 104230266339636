import {InputEventUtils} from '../../../utils/input_event_utils';
import {Metrics} from '../../../metrics';
import {ShareProviderTypes, SharingProviderIcon} from '../../../interfaces/planogram.interface';

export class ShareLink {
  public static shareAPIs = {
    [ShareProviderTypes.WHATSAPP]: 'https://api.whatsapp.com/send?text=',
    [ShareProviderTypes.TWITTER]: 'https://twitter.com/share?url=',
    [ShareProviderTypes.TELEGRAM]: 'https://telegram.me/share/url?url=',
    [ShareProviderTypes.VIBER]: 'viber://forward?text='
  };

  static init(
    config: Array<SharingProviderIcon>,
    getProductUrl: Function,
    getEventString: Function,
    planogramName?: string
  ) {
    config.forEach(item => {
      const api = ShareLink.shareAPIs[item.identifier];
      if (!api) {
        if (item.identifier !== ShareProviderTypes.COPY_LINK) {
          console.error(`Such provider - "${item.identifier}" is not supported`);
        }
        return;
      }
      const elements = document.querySelectorAll<HTMLElement>(`.share-button-${item.identifier}`);

      elements.forEach(el => {
        InputEventUtils.addSelectEvents(el, e => {
          e.stopPropagation();
          this.buildPopupWindow(`${api}${encodeURIComponent(getProductUrl())}`);
          Metrics.storeTheEvent(planogramName, 'click', getEventString(item.identifier));
        });
      });
    });
  }

  public static buildPopupWindow(url: string) {
    const width = 600;
    const height = 400;
    const left = screen.width / 2 - width / 2;
    const top = screen.height / 2 - height / 2;
    const config = `
      toolbar=no,
      location=no,
      directories=no,
      status=no,
      menubar=no,
      scrollbars=no,
      resizable=no,
      copyhistory=no,
      width=${width},
      height=${height},
      top=${top},
      left=${left}
    `;

    return window.open(url, '', config);
  }
}
