import makeBehaviorSubject from 'callbag-behavior-subject';
import {L10nService} from '../api/services/l10n.service';

export class L10nUtils {
  private static loadedDictionaries = new Map<string, Map<string, string>>();
  private static currentLanguage = '';
  private static currentPlanogram = '';
  private static currentPlanogramId;
  private static readonly selectedLanguage = window.sessionStorage.getItem('selectedLanguage');
  static readonly fallbackLanguage = 'en';

  private static localizationService = new L10nService();

  static readonly languageLoadedSubject = makeBehaviorSubject<string>(null);

  static initialize(initialLang = L10nUtils.fallbackLanguage): Promise<void> {
    return L10nUtils.currentLanguage
      ? Promise.resolve()
      : L10nUtils.selectLanguage(L10nUtils.selectedLanguage || initialLang);
  }

  /**
   * Localize text
   * @param key - identifier of text which should be localized
   * @returns {string} - localized text
   */
  static l10n(key: string): string {
    const currentDictionary = L10nUtils.loadedDictionaries.get(L10nUtils.currentLanguage);
    const fallbackDictionary = L10nUtils.loadedDictionaries.get(L10nUtils.fallbackLanguage);

    if (!currentDictionary && !fallbackDictionary) {
      console.error(`l10n > Empty dictionaries for '${L10nUtils.currentLanguage}' and '${L10nUtils.fallbackLanguage}'`);
      return key;
    }

    if (currentDictionary && currentDictionary.has(key)) {
      return currentDictionary.get(key);
    } else if (fallbackDictionary && fallbackDictionary.has(key)) {
      console.warn(`l10n > Fallback set for key '${key}'`);
      return fallbackDictionary.get(key);
    }

    console.error(`l10n > Key '${key}' don't have any translation`);
    return key;
  }

  /**
   * Activate language for whole application.
   * @param langCode - language code ('en', 'it' and etc)
   * @param force - if you would like to load new dictionary even if it's already loaded previously
   * @returns {Promise} - fulfilled Promise when dictionary is loaded
   */
  static selectLanguage(langCode: string, force = false): Promise<void> {
    window.sessionStorage.setItem('selectedLanguage', langCode);
    if (L10nUtils.currentLanguage === langCode && !force) {
      return Promise.resolve();
    }
    let promise = Promise.resolve();
    let fallbackLangPromise = Promise.resolve();
    L10nUtils.currentLanguage = langCode;

    if (force || !L10nUtils.loadedDictionaries.has(langCode)) {
      promise = L10nUtils.loadLanguage(langCode);
    }
    if (L10nUtils.fallbackLanguage !== langCode && !L10nUtils.loadedDictionaries.has(L10nUtils.fallbackLanguage)) {
      fallbackLangPromise = L10nUtils.loadLanguage(L10nUtils.fallbackLanguage);
    }

    const wrappedPromises = [promise, fallbackLangPromise].map(p =>
      Promise.resolve(p).then(
        val => ({status: 'fulfilled', value: val}),
        err => ({status: 'rejected', reason: err})
      )
    );

    return Promise.all(wrappedPromises).then(() => {
      L10nUtils.languageLoadedSubject(1, langCode);
    });
  }

  /**
   * @returns {string} - language code ('en', 'it' and etc)
   */
  static getCurrentLanguage(): string {
    return L10nUtils.currentLanguage;
  }

  static setCurrentLanguage(langCode: string) {
    L10nUtils.currentLanguage = langCode;
  }

  static setCurrentPlanogram(planogramName: string) {
    L10nUtils.currentPlanogram = planogramName;
  }

  static setPlanogramId(id: number) {
    L10nUtils.currentPlanogramId = id;
  }

  static getPlanogramId(): number {
    return L10nUtils.currentPlanogramId;
  }

  static getCurrentPlanogram(): string {
    return L10nUtils.currentPlanogram;
  }

  private static loadLanguage(langCode: string): Promise<any> {
    return L10nUtils.localizationService
      .getL10nDictionary(langCode)
      .then(data => {
        const dictionary: Map<string, string> = new Map(Object.entries(data));
        L10nUtils.loadedDictionaries.set(langCode, dictionary);
      })
      .catch(err => {
        console.error('l10n > Default language cannot be loaded.', err);
      });
  }
}
