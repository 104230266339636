import {InputFieldComponent} from '../../components/input-field/input-field-component';
import {InputFieldsBaseComponent} from '../input-fields-base/input-fields-base-component';
import {Product} from '../../../../../interfaces/product.interface';

export class InputFieldsCrestKeyringComponent extends InputFieldsBaseComponent {
  constructor(container: HTMLElement, product: Product) {
    super(container, product);

    const inputFields = this.product.input_fields;
    const inputWrapper = this.container.querySelector<HTMLElement>('.input-field-component') as HTMLElement;

    this.setField(
      inputFields[0].name,
      new InputFieldComponent(inputWrapper, {
        validationPattern: /[^a-zA-Z0-9 .'/-]/g
      })
    );
  }
}
