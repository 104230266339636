import Splide, {Options} from '@splidejs/splide';
import {Video} from '@splidejs/splide-extension-video';

import {Product} from '../../interfaces/product.interface';
import {PlanogramFonts} from '../../interfaces/planogram.interface';
import {ButtonData} from '../../interfaces/buttons.interface';
import {PurchasingFlowSettingsData} from '../../interfaces/planogram.interface';
import {
  ShareButtonIconTypes,
  ShareProviderTypes,
  SharingButton,
  SharingProviderIcon
} from '../../interfaces/planogram.interface';

import {URLS, UrlUtils} from '../../api/urls';
import {WebUtils} from '../../utils/web_utils';
import {AppUtils} from '../../utils/app_utils';
import {isMacOS} from '../../utils/platform';
import {isCountdownActive} from '../../utils/countdown_utils';
import {InputEventUtils} from '../../utils/input_event_utils';
import {ProductSlides} from '../../product_slides';
import {SphereItem} from '../../sphere_item';
import {Metrics} from '../../metrics';
import {MATOMO_EVENT_NAMES} from '../../metric-events';
import {
  BUTTONS_NAMES,
  CSS_VARIABLE_TYPES,
  PAGE_LOADING_TYPES,
  PRODUCT_GALLERY_ITEM,
  PRODUCT_PAGE_ROUTES
} from '../../shared/constants';
import {PURCHASING_FLOW} from '../../interfaces/planogram.interface';
import Router from '../../router';
import {FontLoader} from '../../font_loader';
import {ShareLink} from './services/share-link';
import {L10nUtils} from '../../utils/l10n_utils';
import {ProductInfoService} from '../../api/services/product_info.service';
import {PageLoadingSpeed} from '../../page_loading_speed';
import {OverlayScrollbar} from '../../components/overlay_scrollbar';
import {overlayEventsHandler} from '../../custom_event_utils';
import {SPHERE_EVENT_NAMES} from '../../event-names';
import {FullScreenButton} from '../../components/overlay-fullscreen-mode';
import {Overlay} from '../../overlay';

export class ProductInfoBase {
  private descriptionScrollArea: HTMLElement;
  productName: string;
  productIdentifier: string | number;
  mainContainer: HTMLElement;
  product: Product;
  private fullscreenButton: FullScreenButton;

  private isGalleryViewDisabled = false;

  private shareTimeout: number;
  protected sharePopupContainer: HTMLElement;

  protected productSlides: ProductSlides;
  protected gallerySplide: Splide;
  protected thumbnailSplide: Splide;

  protected supportGalleryView = true;

  protected changeURLAfterClosingOverlay = true;

  protected purchasingFlow = PURCHASING_FLOW.BUY_BUTTON;

  constructor(
    public container: HTMLElement,
    public item: SphereItem,
    protected options: any,
    protected productInfoService: ProductInfoService,
    private closeCallback: Function
  ) {
    this.resize = this.resize.bind(this);
    this.closeOverlay = this.closeOverlay.bind(this);
    this.showLoader();
    this.productName = UrlUtils.slugify(item.data.product.name || '');
    this.productIdentifier = this.item.identifier;
    this.init().then(() => new OverlayScrollbar('.allow-scroll'));
    overlayEventsHandler.listen(SPHERE_EVENT_NAMES.OVERLAY.FULLSCREEN, () => {
      requestAnimationFrame(() => {
        this.resize();
      });
    });
    overlayEventsHandler.listen(SPHERE_EVENT_NAMES.OVERLAY.JOURNEY_OVERLAY_CLOSE, this.closeOverlay);
  }

  protected async init(): Promise<void> {
    PageLoadingSpeed.startMeasure(PAGE_LOADING_TYPES.PRODUCT, this.item.identifier);

    this.product = await this.productInfoService.getView(this.item.identifier);

    if (this.options) {
      this.initCountDownOverlay();
    }

    const [purchasingFlowSettings, buttons] = await Promise.all([
      this.productInfoService.getPurchasingFlowSettings(),
      this.productInfoService.getCustomButtonsSettings()
    ]);

    PageLoadingSpeed.completeMeasure(PAGE_LOADING_TYPES.PRODUCT, this.item.identifier);

    this.initOverlay();
    this.initProduct();
    this.initPurchasingFlow(purchasingFlowSettings);
    this.setCustomButtons(buttons);
    if (this.options?.fullscreen) {
      const fullscreenButtonContainer = this.container.querySelector<HTMLElement>('.app-product-overlay-controls');
      this.fullscreenButton = new FullScreenButton(fullscreenButtonContainer);
    }
    if (this.options?.title) {
      this.container.classList.add('with-title');
      Overlay.showSeoTitle(this.container.firstElementChild, this.product.seo_title);
    }
  }

  protected initOverlay() {
    this.setItemContent();
  }

  // Virtual function
  protected setItemContent() {}

  initCountDownOverlay() {
    const {release_countdown} = this.product;
    const {showCountdownOverlay} = this.options;
    if (release_countdown && isCountdownActive(release_countdown.server_time, release_countdown.release_time)) {
      ProductInfoService.clearProductInfoCache();
      this.changeURLAfterClosingOverlay = false;
      this.closeOverlay();
      showCountdownOverlay(release_countdown);
      throw new Error('Countdown is active. Cannot open product details.');
    }
  }

  initProduct() {
    if (
      !this.item.planogram.enabledGalleryOverlay ||
      !this.supportGalleryView ||
      this.product.show_product_gallery_view === false
    ) {
      this.isGalleryViewDisabled = true;
    }

    this.initOverlayElements();
    this.initOverlayControls();
    this.initDescriptionControls();
    this.initImageSlider();
    this.initProductPrice();
    this.initGalleryViewControls();
    this.initSharingButton();

    if (
      this.options.initialOverlayToDisplay === PRODUCT_PAGE_ROUTES.DETAILS ||
      (this.options.initialOverlayToDisplay === PRODUCT_PAGE_ROUTES.GALLERY && this.isGalleryViewDisabled)
    ) {
      this.toggleView();
    }
  }

  initPurchasingFlow(setting: PurchasingFlowSettingsData) {
    this.purchasingFlow = setting.purchasing_flow;
    // Default state of overlay is Buy Button flow
    if (this.purchasingFlow === PURCHASING_FLOW.BUY_BUTTON) {
      return;
    }

    this.mainContainer.classList.add('checkout-flow');

    const galleryCheckoutButtonContainer = this.container.querySelector('#checkout-gallery-button');
    const galleryInfoButtonContainer = this.container.querySelector('#info-gallery-button');
    galleryCheckoutButtonContainer?.classList.remove('is-completely-hidden');
    galleryInfoButtonContainer.classList.remove('no-checkout');
  }

  handleClick(e) {
    return e.target === this.mainContainer;
  }

  closeOverlay(e?: Event): void {
    if (e) {
      e.stopPropagation();
    }
    if (this.closeCallback) {
      this.closeCallback(this.changeURLAfterClosingOverlay);
    } else {
      console.error('"Close" function is not set up');
    }
    this.dispose();
  }

  dispose() {
    // Remove resize event listeners from splide sliders
    // TODO: Fix unsubscribe from listeners in splide
    //  (splide video extension not able to destroy with invalid youtube video in slide)
    this.item = undefined;
    this.options = undefined;

    try {
      this.gallerySplide?.destroy(true);
      this.thumbnailSplide?.destroy(true);
    } catch (e) {
      console.error('Gallery destroy error:', e);
    }
  }

  resize() {
    this.setThumbsSwitchLength();
  }

  private showLoader() {
    this.container.innerHTML = `
        <span class="loading-icon absolute is-loading icon-large fill-green">
          <svg viewBox="0 0 32 32">
            <path d="M32 16c-0.040-2.089-0.493-4.172-1.331-6.077-0.834-1.906-2.046-3.633-3.533-5.060-1.486-1.428-3.248-2.557-5.156-3.302-1.906-0.748-3.956-1.105-5.981-1.061-2.025 0.040-4.042 0.48-5.885 1.292-1.845 0.809-3.517 1.983-4.898 3.424s-2.474 3.147-3.193 4.994c-0.722 1.846-1.067 3.829-1.023 5.79 0.040 1.961 0.468 3.911 1.254 5.694 0.784 1.784 1.921 3.401 3.316 4.736 1.394 1.336 3.046 2.391 4.832 3.085 1.785 0.697 3.701 1.028 5.598 0.985 1.897-0.040 3.78-0.455 5.502-1.216 1.723-0.759 3.285-1.859 4.574-3.208 1.29-1.348 2.308-2.945 2.977-4.67 0.407-1.046 0.684-2.137 0.829-3.244 0.039 0.002 0.078 0.004 0.118 0.004 1.105 0 2-0.895 2-2 0-0.056-0.003-0.112-0.007-0.167h0.007zM28.822 21.311c-0.733 1.663-1.796 3.169-3.099 4.412s-2.844 2.225-4.508 2.868c-1.663 0.646-3.447 0.952-5.215 0.909-1.769-0.041-3.519-0.429-5.119-1.14-1.602-0.708-3.053-1.734-4.25-2.991s-2.141-2.743-2.76-4.346c-0.621-1.603-0.913-3.319-0.871-5.024 0.041-1.705 0.417-3.388 1.102-4.928 0.683-1.541 1.672-2.937 2.883-4.088s2.642-2.058 4.184-2.652c1.542-0.596 3.192-0.875 4.832-0.833 1.641 0.041 3.257 0.404 4.736 1.064 1.48 0.658 2.82 1.609 3.926 2.774s1.975 2.54 2.543 4.021c0.57 1.481 0.837 3.064 0.794 4.641h0.007c-0.005 0.055-0.007 0.11-0.007 0.167 0 1.032 0.781 1.88 1.784 1.988-0.195 1.088-0.517 2.151-0.962 3.156z"></path>
          </svg>
        </span>`;
  }

  createButtonItem(button) {
    switch (button.button_type) {
      case BUTTONS_NAMES.AMAZON: {
        const amazonBtnSrc = URLS.OVERLAY_AMAZON_ICON;
        return `
          <div id="amazon-button" class="overlay-btn button-with-icon" role="button" tabindex="0">
            <img src="${amazonBtnSrc}">
            <span>${L10nUtils.l10n('product-details-overlay.amazon-button.text')}</span>
          </div>
        `;
      }

      case BUTTONS_NAMES.EBAY: {
        const ebayBtnSrc = URLS.OVERLAY_EBAY_ICON;
        return `
        <div id="ebay-button" class="overlay-btn button-with-icon" role="button" tabindex="0">
          <img src="${ebayBtnSrc}">
          <span><span id="ebay-button-product-count"></span> ${L10nUtils.l10n(
            'product-details-overlay.ebay-button.text'
          )}</span>
        </div>
        `;
      }

      default: {
        return `
        <div
        role="button" tabindex="0"
        tabindex="0"
        id="${button.button_type}-button"
        class=
        "${button.button_type}-button overlay-btn button-with-icon
        ${button.use_border ? ' with-border' : ''}
        ${!button.icon_url ? ' without-icon' : ''}
        ${!button.title ? ' without-title' : ''}">
          ${button.icon_url ? `<img src="${button.icon_url}">` : ''}
          <span>${button.title ? button.title : ''}</span>
        </div>
        `;
      }
    }
  }

  setButtonFont(button: ButtonData) {
    if (!button.button_font) {
      return;
    }
    const fontSettings = AppUtils.deepClone(button.button_font) as PlanogramFonts;
    if (!fontSettings.assignment) {
      fontSettings.assignment = `${button.button_type}-button`;
    } else {
      fontSettings.assignment = `${fontSettings.assignment}-button`;
    }
    FontLoader.mountCustomFont(fontSettings);
  }

  isViewGallery(): boolean {
    return this.mainContainer?.classList.contains('is-view-gallery');
  }

  getProductUrl(): string {
    return `${window.location.origin}${Router.generateProductUrl(this.item, this.getOverlayView(), true)}`;
  }

  private togglePage() {
    Router.navigateToProduct(this.item, this.getOverlayView(true));
  }

  private getOverlayView(isOpposite = false): PRODUCT_PAGE_ROUTES {
    const isViewGallery = this.isViewGallery();
    const condition = isOpposite ? !isViewGallery : isViewGallery;
    return condition ? PRODUCT_PAGE_ROUTES.GALLERY : PRODUCT_PAGE_ROUTES.DETAILS;
  }

  private toggleView(): void {
    const toggleClassName = 'is-view-gallery';
    this.mainContainer.classList.toggle(toggleClassName);
    this.setThumbsSwitchLength();
    (this.container.querySelector<HTMLElement>('.splide__slide.is-active.is-visible') as HTMLElement).focus();
  }

  // TODO refactor (simplify)
  protected initOverlayElements() {
    this.mainContainer = this.container.querySelector('#product-details-container');
  }

  protected initDescriptionControls() {
    const descriptionContainer = this.container.querySelector<HTMLElement>(
      '#product-details-container .app-product-description'
    );
    const descriptionButton = this.container.querySelector<HTMLElement>(
      '#product-details-container #product-description-button'
    );
    const closeArrowButton = this.container.querySelector<HTMLElement>(
      '#product-details-container .app-description-arrow-close'
    );
    this.descriptionScrollArea = this.container.querySelector<HTMLElement>(
      '#product-details-container .app-product-description-text'
    );

    const toggleDescription = (e: Event): void => {
      e.stopPropagation();

      if (!descriptionContainer) {
        console.error('element not found - ".app-product-description"');
      }
      if (!descriptionContainer.classList.contains('is-description-active')) {
        // Trigger recalculation to hide fade element in case if it is not needed
        Metrics.storeTheEvent(
          this.item.planogram.name,
          'open',
          `${MATOMO_EVENT_NAMES.OPEN_PRODUCT_OVERLAY_DESCRIPTION}-${this.productIdentifier}-${this.productName}`
        );
      }
      descriptionContainer.classList.toggle('is-description-active');
    };

    if (descriptionButton) {
      InputEventUtils.addSelectEvents(descriptionButton, toggleDescription);
    }

    if (closeArrowButton) {
      InputEventUtils.addSelectEvents(closeArrowButton, toggleDescription);
    }
  }

  protected setCustomButtons(buttons: Array<ButtonData>) {
    const buttonsContainer = this.container.querySelector<HTMLElement>('.app-buy-buttons-wrapper');

    const filteredButtons = buttons.filter(item => item.enabled).slice(0, 3);

    filteredButtons.forEach((button: ButtonData) => {
      this.setButtonFont(button);
      const buttonItem = this.createButtonItem(AppUtils.deepClone(button));
      const buttonWrapper = document.createElement('div');
      buttonsContainer.append(buttonWrapper);
      buttonWrapper.outerHTML = buttonItem;

      if (button.color) {
        document.documentElement.style.setProperty(`--${button.button_type}-button-color`, `${button.color}`);
      }
      if (button.use_border) {
        document.documentElement.style.setProperty(
          `--${button.button_type}-button-border-color`,
          `${button.border_color}`
        );
        document.documentElement.style.setProperty(
          `--${button.button_type}-button-border-width`,
          `${button.border_width.replace(/px/gi, '')}px`
        );
      }

      const buttonEl = this.container.querySelector<HTMLElement>(`${button.button_type}-button`);
      const buttonUrl = this.product.buttons[button.button_type] ? this.product.buttons[button.button_type] : null;

      if (button.button_type === BUTTONS_NAMES.EBAY) {
        this.productInfoService.getEbayAffiliateInfo(this.item).then(this.setEbayInfo.bind(this));
      } else if (button.button_type === BUTTONS_NAMES.RAKUTEN) {
        this.setRakutenInfo({
          buy_link: this.product.rakuten_info?.buy_link,
          is_fallback: this.product.rakuten_info?.is_fallback
        });
      } else if (button.button_type === BUTTONS_NAMES.CHECKOUT) {
        this.setCheckoutGalleryImage(button.icon_url);
        this.handleCheckoutButtons();
      } else {
        this.setBuyButtonUrl(buttonEl, buttonUrl, button);
      }
    });
  }

  private setRakutenInfo({buy_link, is_fallback}) {
    const rakutenButton = this.container.querySelector<HTMLElement>('#rakuten-button');

    if (!rakutenButton) {
      return;
    }

    if (!buy_link) {
      rakutenButton.classList.add('is-hidden');
      console.error('Empty link value for Rakuten button');
      return;
    }

    if (is_fallback === true) {
      const span = rakutenButton.querySelector<HTMLElement>('span');
      span.innerText = L10nUtils.l10n('product-details-overlay.rakuten-button.text-fallback');
    }

    InputEventUtils.addSelectEvents(rakutenButton, () => {
      WebUtils.openLink(buy_link);
      Metrics.storeTheEvent(
        this.item.planogram.name,
        'click',
        `${MATOMO_EVENT_NAMES.CLICK_RAKUTEN_BUY_BUTTON}-${this.item.identifier}-${this.productName}`
      );
    });
  }

  private setEbayInfo({ebay}) {
    const ebayButton = this.container.querySelector<HTMLElement>('#ebay-button');

    if (!ebayButton) {
      return;
    }

    if (!ebay || !ebay.search_url) {
      ebayButton.classList.add('is-hidden');
      console.error('Empty link value for Ebay button');
    }

    if (ebay.product_amount) {
      const productCount = this.container.querySelector<HTMLElement>('#ebay-button-product-count');
      productCount.innerText = ebay.product_amount;
    }
    InputEventUtils.addSelectEvents(ebayButton, () => {
      WebUtils.openLink(ebay.search_url);
      Metrics.storeTheEvent(
        this.item.planogram.name,
        'click',
        `${MATOMO_EVENT_NAMES.CLICK_EBAY_BUTTON}-${this.item.identifier}-${this.productName}`
      );
    });
  }

  protected initGalleryViewControls() {
    const infoButton = this.container.querySelector<HTMLElement>('#product-details-container #info-gallery-button');

    if (!infoButton) {
      console.error('Info button not found in template');
      return;
    }

    // Set color
    if (this.item.planogram.infoButtonSetting.color) {
      document.documentElement.style.setProperty(
        `--info-button-color`,
        this.item.planogram.infoButtonSetting.color.toString()
      );
    }

    // Set custom icon with fallback to default
    const infoIcon = infoButton.querySelector<HTMLElement>('img');
    const infoIconUrl =
      this.item.planogram.infoButtonSetting.custom_icon_url || this.item.planogram.infoButtonSetting.default_icon_url;
    infoIcon.onerror = () => {
      infoIcon.setAttribute('src', this.item.planogram.infoButtonSetting.default_icon_url);
    };

    infoIcon.setAttribute('src', infoIconUrl);

    // Handle click
    InputEventUtils.addSelectEvents(infoButton, () => {
      Metrics.storeTheEvent(
        this.item.planogram.name,
        'click',
        `${MATOMO_EVENT_NAMES.CLICK_GALLERY_INFO_BUTTON}-${this.productIdentifier}-${this.productName}`
      );
      this.togglePage();
    });
  }

  protected initOverlayControls() {
    const closeButton = this.container.querySelector<HTMLElement>('#product-details-container .overlay-button-close');
    const backButton = this.container.querySelector<HTMLElement>('#product-details-container .overlay-button-back');
    const arrowButtons = this.container.querySelectorAll<HTMLElement>('.splide__arrows');

    if (backButton) {
      if (this.isGalleryViewDisabled) {
        backButton.classList.add('is-visibility-hidden');
      } else {
        InputEventUtils.addSelectEvents(backButton, () => {
          Metrics.storeTheEvent(
            this.item.planogram.name,
            'click',
            `${MATOMO_EVENT_NAMES.CLICK_PRODUCT_DETAILS_BACK_BUTTON}-${this.productIdentifier}-${this.productName}`
          );
          this.togglePage();
        });
      }
    } else {
      console.error('element not found - ".overlay-button-back"');
    }

    if (closeButton) {
      InputEventUtils.addSelectEvents(closeButton, e => {
        const eventString = this.isViewGallery()
          ? MATOMO_EVENT_NAMES.CLICK_PRODUCT_GALLERY_CLOSE_BUTTON
          : MATOMO_EVENT_NAMES.CLICK_PRODUCT_DETAILS_CLOSE_BUTTON;
        Metrics.storeTheEvent(
          this.item.planogram.name,
          'click',
          `${eventString}-${this.productIdentifier}-${this.productName}`
        );
        this.closeOverlay(e);
      });
    } else {
      console.error('element not found - ".overlay-button-close"');
    }

    if (arrowButtons.length) {
      arrowButtons.forEach(el =>
        InputEventUtils.addSelectEvents(el, () => {
          const eventString = this.isViewGallery()
            ? MATOMO_EVENT_NAMES.CLICK_PRODUCT_GALLERY_ARROW
            : MATOMO_EVENT_NAMES.CLICK_PRODUCT_DETAILS_ARROW;
          Metrics.storeTheEvent(
            this.item.planogram.name,
            'click',
            `${eventString}-${this.productIdentifier}-${this.productName}`
          );
        })
      );
    }
  }

  protected hideArrows(productImages: number) {
    if (productImages === 1) {
      const arrows: NodeListOf<HTMLElement> = this.mainContainer.querySelectorAll('.splide__arrows');

      [...arrows].forEach(el => el.classList.add('is-hidden'));
    }
  }

  protected initImageSlider() {
    this.productSlides = new ProductSlides(this.product, this.item);
    const slidesContainer = this.mainContainer.querySelector<HTMLElement>('#app-image-carousel .splide__list');
    const thumbsContainer = this.mainContainer.querySelector<HTMLElement>('#app-thumbnail-carousel .splide__list');

    this.hideArrows(this.productSlides.length);

    this.productSlides.slides.forEach(slide => {
      const liSlide = document.createElement('li');
      const imgSlide = document.createElement('img');
      const liThumb = document.createElement('li');
      const imgThumb = document.createElement('img');
      liSlide.classList.add('splide__slide');
      liSlide.append(imgSlide);
      liThumb.classList.add('splide__slide');
      liThumb.append(imgThumb);

      if (slide.type === PRODUCT_GALLERY_ITEM.IMAGE) {
        imgSlide.setAttribute('data-splide-lazy', slide.url);
        imgSlide.setAttribute('data-name', slide.name);
        imgThumb.setAttribute('data-splide-lazy', slide.url);
        imgThumb.setAttribute('data-name', slide.name);
        imgThumb.setAttribute('data-type', slide.type);
      } else if (slide.type === PRODUCT_GALLERY_ITEM.VIDEO) {
        imgThumb.setAttribute('data-splide-lazy', slide.thumbUrl);
        imgThumb.setAttribute('data-type', slide.type);
        liSlide.setAttribute(`data-splide-${slide.provider}`, slide.url);
        imgSlide.setAttribute('data-splide-lazy', slide.videoPreview);
        const thumbPlayBtn = document.createElement('div');
        thumbPlayBtn.classList.add('splide__video__play');
        liThumb.classList.add('splide__slide--has-video');
        liThumb.append(thumbPlayBtn);
      }

      slidesContainer.append(liSlide);
      thumbsContainer?.append(liThumb);
    });

    const galleryCarousel = this.mainContainer.querySelector<HTMLElement>('#app-image-carousel');
    this.gallerySplide = new Splide(galleryCarousel, this.getGalleryCarouselConfig(this.productSlides));

    // TODO workaround to pause video on iOS devices
    this.gallerySplide.event.on('video:pause', e => {
      requestAnimationFrame(() => {
        if (e?.slide?.dataset.splideHtmlVideo) {
          e.player?.pause();
          e.ui?.wrapper?.setAttribute('style', '');
        }
      });
    });

    const thumbnailCarousel = this.mainContainer.querySelector<HTMLElement>('#app-thumbnail-carousel');
    if (thumbnailCarousel) {
      this.thumbnailSplide = new Splide(thumbnailCarousel, this.getThumbsCarouselConfig(this.productSlides)).mount({
        Video
      });

      this.gallerySplide.sync(this.thumbnailSplide).mount({Video});

      InputEventUtils.addSelectEvents(thumbnailCarousel, this.handleThumbnailMatomoEvents.bind(this));
    } else {
      this.gallerySplide.mount({Video});
    }

    const imageCarousel = this.mainContainer.querySelector<HTMLElement>('#app-image-carousel');
    if (imageCarousel) {
      InputEventUtils.addSelectEvents(imageCarousel, this.handleImageMatomoEvents.bind(this));
    } else {
      console.error('image gallery not found - "#app-image-carousel"');
    }

    this.setThumbsSwitchLength();
    (this.mainContainer.querySelector<HTMLElement>('.splide__slide.is-active.is-visible') as HTMLElement).focus();
  }

  protected handleThumbnailMatomoEvents(e: Event) {
    const target = e.target as HTMLElement;
    if (target.closest('.splide__slide')) {
      const thumbnailImage = target.closest('.splide__slide').querySelector<HTMLElement>('img');
      const thumbnailType = thumbnailImage.getAttribute('data-type');

      Metrics.storeTheEvent(
        this.item.planogram.name,
        'click',
        this.isViewGallery()
          ? MATOMO_EVENT_NAMES.CLICK_PRODUCT_GALLERY_PRODUCT_THUMBNAIL(
              thumbnailType,
              this.productIdentifier,
              this.productName
            )
          : MATOMO_EVENT_NAMES.CLICK_PRODUCT_DETAILS_PRODUCT_THUMBNAIL(
              thumbnailType,
              this.productIdentifier,
              this.productName
            )
      );
    }
  }

  protected handleImageMatomoEvents(e: Event) {
    const target = e.target as HTMLElement;
    if (target.closest('.splide__slide')) {
      let name = '';
      let imgElement: HTMLElement;

      if (target.nodeName === 'LI') {
        imgElement = target.querySelector<HTMLElement>('img');
      } else if (target.nodeName === 'IMG') {
        imgElement = target;
      }

      if (imgElement) {
        name = imgElement.getAttribute('data-name');
      }

      const eventName = this.isViewGallery()
        ? MATOMO_EVENT_NAMES.CLICK_PRODUCT_GALLERY_PRODUCT_IMAGE
        : MATOMO_EVENT_NAMES.CLICK_PRODUCT_DETAILS_PRODUCT_IMAGE;

      Metrics.storeTheEvent(this.item.planogram.name, 'click', `${eventName}${name ? '-' + name : ''}`);
    }
  }

  protected initProductPrice() {
    if (this.product.price_in_user_currency_str) {
      const priceContainer = this.container.querySelector<HTMLElement>('.app-product-price');
      const priceEl = priceContainer?.querySelector<HTMLElement>('.product-price-value');
      if (priceEl) {
        priceEl.prepend(this.product.price_in_user_currency_str);
        priceContainer.classList.remove('is-hidden');
      }
    }
  }

  protected initSharingButton() {
    const sharingButton = this.item.planogram.sharingButton;
    const shareLinksContainer = this.container.querySelector<HTMLElement>('.app-share-links');

    if (!sharingButton?.enabled || !shareLinksContainer) {
      return;
    }

    shareLinksContainer.classList.remove('is-hidden');
    this.mainContainer.classList.add('with-sharing-button');
    this.setupSharingProviders(sharingButton.sharing_providers);
    this.setupMobileSharingButton(sharingButton);
    this.setupGallerySharingButton(sharingButton);
  }

  protected setupSharingProviders(providers: Array<SharingProviderIcon>) {
    const filteredProviders = providers.filter(p => p.enabled);
    const getEventString = provider => {
      const pageType = this.isViewGallery() ? 'gallery' : 'details';
      return `${MATOMO_EVENT_NAMES.CLICK_EVENT}${provider}-product_${pageType}-${this.productIdentifier}-${this.productName}`;
    };

    ShareLink.init(filteredProviders, this.getProductUrl.bind(this), getEventString, this.item.planogram.name);

    filteredProviders.forEach(provider => {
      const url = provider.icon_custom_url || provider.icon_default_url;
      const imgs = this.container.querySelectorAll<HTMLImageElement>(`.share-button-${provider.identifier}`);

      imgs.forEach(img => {
        img.classList.remove('is-hidden');
        img.setAttribute('src', url);
      });
      if (provider.identifier === ShareProviderTypes.COPY_LINK) {
        imgs.forEach(img => InputEventUtils.addSelectEvents(img, e => this.copyLinkHandler(e)));
      }
    });
  }

  protected setupMobileSharingButton(config: SharingButton) {
    this.sharePopupContainer = this.container.querySelector('#share-mobile-popup');
    const shareMobileButton = this.container.querySelector<HTMLElement>('#share-mobile');

    if (!this.sharePopupContainer || !shareMobileButton) {
      return;
    }

    const sharePopupBackdrop = this.container.querySelector<HTMLElement>('.app-popup-backdrop');
    const buttonTextEl = shareMobileButton.querySelector<HTMLElement>('span');
    const buttonIconEl = shareMobileButton.querySelector<HTMLElement>('img');

    this.sharePopupContainer.classList.remove('is-hidden');
    shareMobileButton.classList.remove('is-hidden');
    shareMobileButton.style.backgroundColor = config.color || 'transparent';
    buttonTextEl.innerText = config.title;
    buttonIconEl.setAttribute('src', this.getProperShareButtonIconUrl(ShareButtonIconTypes.DEFAULT));
    FontLoader.mountCustomFont({
      ...config.sharing_button_font,
      assignment: CSS_VARIABLE_TYPES.SHARING_BUTTON
    });

    InputEventUtils.addSelectEvents(shareMobileButton, e => {
      e.stopPropagation();
      Metrics.storeTheEvent(
        this.item.planogram.name,
        'click',
        `${MATOMO_EVENT_NAMES.CLICK_SHARE_BUTTON}product_details-${this.productIdentifier}-${this.productName}`
      );
      this.sharePopupContainer.classList.add('active');
    });
    InputEventUtils.addSelectEvents(sharePopupBackdrop, e => {
      e.stopPropagation();
      this.sharePopupContainer.classList.remove('active');
    });
  }

  private setupGallerySharingButton(config: SharingButton) {
    const buttonWrapper = this.container.querySelector<HTMLElement>('#sharing-gallery-button');
    const buttonBackdrop = this.container.querySelector<HTMLElement>('#sharing-button-backdrop');

    if (!buttonWrapper || !buttonBackdrop) {
      return;
    }

    const buttonEl = buttonWrapper.querySelector<HTMLElement>('div');
    const iconDefaultEl = buttonEl.querySelector<HTMLElement>('img.share-button-icon-default');
    const iconActiveEl = buttonEl.querySelector<HTMLElement>('img.share-button-icon-active');

    buttonEl.style.backgroundColor = config.color || 'transparent';
    iconDefaultEl.setAttribute('src', this.getProperShareButtonIconUrl(ShareButtonIconTypes.DEFAULT));
    iconActiveEl.setAttribute('src', this.getProperShareButtonIconUrl(ShareButtonIconTypes.ACTIVE));

    InputEventUtils.addSelectEvents(buttonWrapper, e => {
      e.stopPropagation();

      Metrics.storeTheEvent(
        this.item.planogram.name,
        'click',
        `${MATOMO_EVENT_NAMES.CLICK_SHARE_BUTTON}product_gallery-${this.productIdentifier}-${this.productName}`
      );

      if (window.innerWidth >= window.innerHeight && window.innerHeight <= 450) {
        this.sharePopupContainer.classList.add('active');
      } else {
        buttonWrapper.classList.toggle('active');
        buttonBackdrop.classList.toggle('active');
      }
    });
    InputEventUtils.addSelectEvents(buttonBackdrop, e => {
      e.stopPropagation();
      buttonWrapper.classList.remove('active');
      buttonBackdrop.classList.remove('active');
    });
  }

  private copyLinkHandler(e) {
    e.preventDefault();
    e.stopPropagation();

    const pageType = this.isViewGallery() ? 'gallery' : 'details';
    Metrics.storeTheEvent(
      this.item.planogram.name,
      'click',
      `${MATOMO_EVENT_NAMES.CLICK_COPY_LINK}-product_${pageType}-${this.productIdentifier}-${this.productName}`
    );
    if (window.navigator.share && !isMacOS) {
      window.navigator
        .share({url: this.getProductUrl()})
        .then(() => console.log('Successful share'))
        .catch(error => console.log('Error sharing:', error));
    } else {
      this.copyLink(e);
    }
  }

  private copyLink(event: Event): void {
    const target = event.target as HTMLElement;
    const successMessage =
      target.parentElement.querySelector<HTMLElement>('.share-success-message') ||
      this.mainContainer.querySelector<HTMLElement>('#share-mobile-popup .share-success-message');

    window.clearTimeout(this.shareTimeout);
    WebUtils.copyToClipboard(this.getProductUrl());

    if (successMessage) {
      const tooltipKeepAliveTime = 2000;
      successMessage.classList.add('active');

      this.shareTimeout = window.setTimeout(() => {
        successMessage.classList.remove('active');
      }, tooltipKeepAliveTime);
    }
  }

  private getProperShareButtonIconUrl(type: ShareButtonIconTypes): string {
    const sharingButton = this.item.planogram.sharingButton;
    const userIcon = sharingButton.button_icons.find(icon => icon.icon_state === type);
    const systemIcon = sharingButton.button_icons.find(icon => icon.icon_state === `system_${type}`);

    return userIcon?.url || systemIcon?.url;
  }

  protected setBuyButtonUrl(buttonEl: HTMLElement, buttonUrl: string, button: ButtonData) {
    if (buttonUrl) {
      if (!this.product.price_in_user_currency_str) {
        buttonEl.classList.add('is-hidden');
      }

      InputEventUtils.addSelectEvents(buttonEl, () => {
        WebUtils.openLink(buttonUrl);
        Metrics.storeTheEvent(
          this.item.planogram.name,
          'click',
          `click-${button.button_type}_buy_button-${this.productIdentifier}-${this.productName}`
        );

        const {identifier, name, price_in_user_currency_str, category_name} = this.product;
        if (price_in_user_currency_str) {
          Metrics.storeEcommerceEvent({
            sku: identifier,
            productName: UrlUtils.slugify(name),
            category: category_name,
            productPrice: AppUtils.getPriceValueFromStr(price_in_user_currency_str)
          });
        }
      });
    } else {
      buttonEl.classList.add('is-completely-hidden');
      console.error(`URL for ${button.button_type} buy button is not defined properly`);
    }
  }

  protected setCheckoutGalleryImage(iconUrl: string) {
    const checkoutButtonGalleryImage = this.container.querySelector<HTMLElement>('#checkout-gallery-button div img');

    if (!checkoutButtonGalleryImage) {
      console.error('Checkout button in gallery view not found.');
      return;
    }
    if (!iconUrl) {
      checkoutButtonGalleryImage.remove();
    } else {
      checkoutButtonGalleryImage.setAttribute('src', iconUrl);
    }
  }

  protected handleCheckoutButtons() {
    const checkoutButtons = [
      {
        element: this.container.querySelector<HTMLElement>('#checkout-button'),
        trackerEvent: MATOMO_EVENT_NAMES.CLICK_PRODUCT_DETAILS_CHECKOUT_BUTTON
      },
      {
        element: this.container.querySelector<HTMLElement>('#checkout-gallery-button'),
        trackerEvent: MATOMO_EVENT_NAMES.CLICK_PRODUCT_GALLERY_CHECKOUT_BUTTON
      }
    ];

    checkoutButtons.forEach(button => {
      if (!button.element) {
        console.error('HTML element not found');
        return;
      }

      InputEventUtils.addSelectEvents(button.element, () => {
        this.changeURLAfterClosingOverlay = false;
        Metrics.storeTheEvent(
          this.item.planogram.name,
          'click',
          `${button.trackerEvent}-${this.productIdentifier}-${this.productName}`
        );
        const item = this.item;
        this.closeOverlay();
        Router.navigateToCheckout(item);
      });
    });
  }

  protected setThumbsSwitchLength() {
    if (this.gallerySplide && this.thumbnailSplide) {
      const thumbsLength = this.thumbnailSplide.length - 1;
      const maxLength = this.isViewGallery() && window.innerWidth >= 1366 ? 7 : 5;
      const perPage = Math.min(thumbsLength, maxLength);

      this.thumbnailSplide.options = {perPage, focus: perPage - 1};
      this.gallerySplide.emit('resize', {});
      this.thumbnailSplide.emit('resize', {});
    }
  }

  protected getGalleryCarouselConfig(productSlides: ProductSlides): Options {
    return {
      type: 'loop',
      perPage: 1,
      pagination: false,
      keyboard: true,
      speed: 100,
      updateOnMove: true,
      lazyLoad: 'nearby',
      start: this.productSlides.imageIndex,
      // @ts-ignore
      video: {
        playerOptions: {
          youtube: {
            playlist: undefined
          }
        }
      }
    };
  }

  protected getThumbsCarouselConfig(productSlides: ProductSlides): Options {
    return {
      fixedWidth: 156,
      height: 156,
      gap: 28,
      speed: 150,
      rewind: true,
      pagination: false,
      isNavigation: true,
      lazyLoad: 'sequential',
      start: productSlides.imageIndex,
      perMove: 1,
      perPage: Math.min(productSlides.length - 1, 7),
      focus: 6,
      keyboard: false,
      breakpoints: {
        2560: {
          fixedWidth: 125,
          height: 125,
          gap: 22
        },
        1921: {
          fixedWidth: 72,
          height: 72,
          gap: 12
        },
        1366: {
          fixedWidth: 60,
          height: 60,
          gap: 10
        },
        1365: {
          fixedWidth: 60,
          height: 60,
          gap: 10,
          perPage: Math.min(productSlides.length - 1, 5),
          focus: 4
        },
        1024: {
          fixedWidth: 40,
          height: 40,
          gap: 8
        }
      }
    };
  }
}
