export default class MultiSet<T> {
  private data: Map<T, number> = new Map();

  add(v: T) {
    this.data.set(v, (this.data.get(v) ?? 0) + 1);
  }

  has(v: T) {
    return this.data.has(v);
  }

  remove(v: T) {
    const current = (this.data.get(v) ?? 0) - 1;
    if (current <= 0) this.data.delete(v);
    else this.data.set(v, current);
  }

  get size() {
    return this.data.size;
  }
}
