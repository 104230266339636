import {InfoOverlay} from './infos-base_overlay';
import {InputEventUtils} from '../utils/input_event_utils';
import {overlayEventsHandler} from '../custom_event_utils';
import {InfosData} from '../interfaces/infos.interface';
import {CUSTOM_OVERLAYS} from '../shared/constants';
import {SphereItem} from '../sphere_item';
import {OverlayOptions} from '../overlay';

export class Privacy extends InfoOverlay {
  constructor(
    container: HTMLElement,
    planogramId: number,
    verionId: number,
    infoType: CUSTOM_OVERLAYS,
    item: SphereItem,
    options: OverlayOptions,
    closeCallback: Function
  ) {
    super(container, planogramId, verionId, infoType, options, closeCallback);
  }

  initTemplateContent(container: HTMLElement, data: InfosData) {
    super.initTemplateContent(container, data);
    const cookiesToggleButton = document.querySelector<HTMLElement>('.infos-overlay .cookies-btn');

    if (cookiesToggleButton) {
      cookiesToggleButton.classList.remove('is-hidden');
      InputEventUtils.addSelectEvents(cookiesToggleButton, this.toggleView.bind(this));
    } else {
      console.error('element not found - ".privacy-overlay .cookies-btn"');
    }
  }

  private toggleView(e?: Event): void {
    if (e) {
      e.stopPropagation();
    }
    overlayEventsHandler.emit('showCookieTable');
  }
}
