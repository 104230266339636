import pipe from 'callbag-pipe';
import subscribe from 'callbag-subscribe';
import {buttonsComponentView} from './shopify-buttons_component.view';
import {ShopifyService} from '../../../../api/services/shopify.service';
import {ShopifyProduct, ShopifyVariant} from '../../../../interfaces/shopify-product.interface';
import {InputEventUtils} from '../../../../utils/input_event_utils';
import {WebUtils} from '../../../../utils/web_utils';
import {EcommerceOverlaySettings} from '../../../../interfaces/planogram.interface';
import {ShoppingCartService} from '../../../../api/services/shopping-cart.service';
import {Metrics} from '../../../../metrics';
import {MATOMO_EVENT_NAMES} from '../../../../metric-events';
import {loader} from '../../../../components/loader';
import {AppState} from '../../../../shared/app.state';
import {ShopifyProductInfo} from '../../shopify-product_info';
import {QuantityPicker} from '../quantity-picker/quantity-picker';
import {AppUtils} from '../../../../utils/app_utils';
import {UrlUtils} from '../../../../api/urls';
import {L10nUtils} from '../../../../utils/l10n_utils';

export class ShopifyButtons {
  private buttonsWrapper: HTMLElement;
  private isProductAvailable: boolean;
  private variant: ShopifyVariant;
  private processing: boolean;

  constructor(
    private shopifyProductInfo: ShopifyProductInfo,
    private product: ShopifyProduct,
    private overlaySettings: EcommerceOverlaySettings,
    private mainContainer: HTMLElement,
    private quantityPicker: QuantityPicker,
    private shopifyService: ShopifyService,
    private shoppingCartService: ShoppingCartService
  ) {
    this.initComponent();
    this.handleButtonClicks();

    pipe(
      this.shopifyService.selectedProductSubject,
      subscribe({
        next: (variant: any) => {
          this.updateButtonsState(variant);
        }
      })
    );
  }

  private initComponent() {
    const buttonsContainer = this.mainContainer.querySelector<HTMLElement>('.app-shopify-buttons-container');
    const buttons = document.createElement('div');
    buttonsContainer.append(buttons);
    buttons.outerHTML = buttonsComponentView();

    this.buttonsWrapper = this.mainContainer.querySelector<HTMLElement>('.app-shopify-product-buy-buttons-wrapper');
    this.overlaySettings?.ecommerce_buttons.forEach(button => {
      if (
        AppUtils.isHarryPotterClient(this.shopifyProductInfo.item.planogram, AppState.clientName) &&
        button.button_type === 'buy_now' &&
        !this.shopifyProductInfo.enablePersonalizeOverlay
      ) {
        return;
      }

      if (button.color) {
        document.documentElement.style.setProperty(`--${button.button_type}-button-color`, `${button.color}`);
      }
      if (button.color_disabled) {
        document.documentElement.style.setProperty(
          `--${button.button_type}-button-color-disabled`,
          `${button.color_disabled}`
        );
      }
      if (button.use_border) {
        document.documentElement.style.setProperty(
          `--${button.button_type}-button-border-color`,
          `${button.border_color}`
        );
        document.documentElement.style.setProperty(
          `--${button.button_type}-button-border-width`,
          `${button.border_width.replace(/px/gi, '')}px`
        );
      }
      if (button.use_border_disabled) {
        document.documentElement.style.setProperty(
          `--${button.button_type}-button-border-color-disabled`,
          `${button.border_color_disabled}`
        );
        document.documentElement.style.setProperty(
          `--${button.button_type}-button-border-width-disabled`,
          `${button.border_width_disabled.replace(/px/gi, '')}px`
        );
      }
      const buttonItem = ShopifyButtons.createButtonItem(
        button,
        this.overlaySettings.button_shape,
        this.shoppingCartService.isMultipassEnabled
      );
      const buttonWrapper = document.createElement('div');
      this.buttonsWrapper.append(buttonWrapper);
      buttonWrapper.outerHTML = buttonItem;
    });
  }

  private handleButtonClicks() {
    const buyNowButton = this.buttonsWrapper?.querySelector<HTMLElement>('#buy_now-button');
    const addToCartButton = this.buttonsWrapper?.querySelector<HTMLElement>('#add_to_cart-button');
    if (buyNowButton) {
      InputEventUtils.addSelectEvents(buyNowButton, () => {
        if (!this.shopifyProductInfo.validateVariants()) {
          return;
        }
        if (this.isProductAvailable && !this.shopifyProductInfo.enablePersonalizeOverlay) {
          Metrics.storeTheEvent(
            AppState.planogramName,
            'click',
            MATOMO_EVENT_NAMES.ECOMMERCE_CLICK_BUY_NOW(this.product.identifier, UrlUtils.slugify(this.product.name))
          );
          if (this.shoppingCartService.getMultipassToken) {
            const link = window.open();
            buyNowButton.classList.add('loading');
            this.shoppingCartService
              .generateMultipassLink(this.variant.buy_btn_url)
              .then(resp => (link.location = resp?.link))
              .finally(() => buyNowButton.classList.remove('loading'));
          } else {
            WebUtils.openLink(this.variant.buy_btn_url);
          }
        } else if (this.shopifyProductInfo.enablePersonalizeOverlay && this.isProductAvailable) {
          this.shopifyProductInfo.openPersonalizedOverlay?.();
        }
      });
    }

    if (addToCartButton) {
      InputEventUtils.addSelectEvents(addToCartButton, () => {
        if (
          (!this.shopifyProductInfo.enablePersonalizeOverlay &&
            this.shopifyProductInfo.inputFields &&
            !this.shopifyProductInfo.inputFields.validate()) ||
          !this.shopifyProductInfo.validateVariants()
        ) {
          return;
        }

        if (this.isProductAvailable && !this.processing) {
          Metrics.storeTheEvent(
            AppState.planogramName,
            'click',
            MATOMO_EVENT_NAMES.ECOMMERCE_CLICK_ADD_TO_CART(this.product.identifier, UrlUtils.slugify(this.product.name))
          );
          this.processing = this.shopifyProductInfo.showShoppingCartWidget = true;
        }

        if (
          this.isProductAvailable &&
          !addToCartButton.classList.contains('loading') &&
          !this.shopifyProductInfo.enablePersonalizeOverlay
        ) {
          addToCartButton.classList.add('loading');
          this.shoppingCartService
            .fireShoppingCartUpdates({
              ...this.variant,
              quantity: this.quantityPicker.PRODUCT_QUANTITY,
              ...(this.shopifyProductInfo.inputFields?.withPersonalization && {
                custom_attributes: this.shopifyProductInfo.inputFields.values
              })
            })
            .finally(() => {
              addToCartButton.classList.remove('loading');
              this.processing = this.shopifyProductInfo.showShoppingCartWidget = false;
            });
        } else if (this.shopifyProductInfo.enablePersonalizeOverlay && this.isProductAvailable) {
          this.shopifyProductInfo.openPersonalizedOverlay?.();
        }
      });
    }
  }

  private updateButtonsState(variant) {
    this.buttonsWrapper.classList.remove('disabled');
    this.isProductAvailable = true;
    this.variant = variant;
    if ((!variant || variant?.inventory_quantity <= 0) && !variant?.isAutoSelected) {
      this.buttonsWrapper.classList.add('disabled');
      this.isProductAvailable = false;
    }
  }

  private static createButtonItem(button, shape, isMutlipassEnabled) {
    const currLang = L10nUtils.getCurrentLanguage();
    const buttonTitle = button.title[currLang] ?? button.title[L10nUtils.fallbackLanguage];

    return `
        <div
        id="${button.button_type}-button"
        class=
        "${shape}-button hp-custom-button app-shopify-product-button
        ${button.use_border ? ' with-border' : ''}
        ${button.use_border_disabled ? ' disabled-with-border' : ''}
        ${button.icon_url ? ' with-icon' : ''}
        ${!Boolean(buttonTitle) ? ' without-title' : ''}">
          ${button.icon_url ? `<img src="${button.icon_url}">` : ''}
          <div class="btn-border">
            <div class="left-border">
              <div class="square"></div>
            </div>
            <div class="right-border">
              <div class="square"></div>
            </div>
          </div>
          <span>${buttonTitle ?? ''}</span>
          ${button.button_type === 'add_to_cart' || isMutlipassEnabled ? `${loader({})}` : ''}
        </div>
        `;
  }
}
