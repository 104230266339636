import { CylinderBox } from "shared/utils/modulo.util";
import type { Box } from "shared/utils/modulo.util";

import { planogramWidth } from "./PlanogramPoint";
import { debugCommand } from "./debug";

export default class PlanogramBox extends CylinderBox<10000> {
  constructor(box2?: Box) {
    super(planogramWidth);
    if (box2) this.fromBox2(box2);
  }
}

debugCommand("box", () => new PlanogramBox());
