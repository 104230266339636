import subscribe from 'callbag-subscribe';
import pipe from 'callbag-pipe';

import {AccountService} from '../api/services/account.service';
import {AccountSignIn} from './account-sign-in/account-sign-in';
import {AccountSignUp} from './account-sign-up/account-sign-up';
import {AccountResetPass} from './account-reset-pass/account-reset-pass';
import {AccountEmailSent} from './account-email-sent/account-email-sent';
import {AccountProfile} from './account-profile/account-profile';
import {ACCESS_TOKEN_KEY, ACCOUNT_OVERLAY_TYPE, ECOMMERCE_BUTTON_TYPE} from '../shared/constants';
import {InputEventUtils} from '../utils/input_event_utils';
import {UserAccountData} from '../interfaces/user-account-data.interface';
import {accountView} from './account.view';
import {Metrics} from '../metrics';
import {MATOMO_EVENT_NAMES} from '../metric-events';
import {AppState} from '../shared/app.state';
import {EcommerceOverlaySettings} from '../interfaces/planogram.interface';
import {FontLoader} from '../font_loader';
import {AppUtils} from '../utils/app_utils';
import {OverlayScrollbar} from '../components/overlay_scrollbar';
import {WebUtils} from '../utils/web_utils';
import {HTMLUtils} from '../utils/html_utils';
import {ShoppingCartService} from '../api/services/shopping-cart.service';
import {Planogram} from '../planogram';

export class Account {
  static isLogged: boolean;
  static isAccountActive: boolean;
  private readonly accountService: AccountService;
  private readonly overlayScroll: OverlayScrollbar;
  private wrapperEl: HTMLElement;
  private userProfileBtn: HTMLElement;
  private signIn: AccountSignIn;
  private signUp: AccountSignUp;
  private resetPass: AccountResetPass;
  private emailSent: AccountEmailSent;
  private profile: AccountProfile;
  private accountSettings: EcommerceOverlaySettings;
  private userProfileBtnSettings: EcommerceOverlaySettings;

  constructor(private planogram: Planogram, private shoppingCartService: ShoppingCartService) {
    this.cleanUp();
    this.onStorageChange = this.onStorageChange.bind(this);
    this.accountService = new AccountService();
    this.wrapperEl = document.getElementById('account');
    const isHarryPotterClient = AppUtils.isHarryPotterClient(this.planogram, this.planogram.clientName);
    this.wrapperEl.innerHTML = accountView(isHarryPotterClient);
    this.signIn = new AccountSignIn(this.accountService, document.getElementById('account-sign-in'));
    this.signUp = new AccountSignUp(this.accountService, document.getElementById('account-sign-up'));
    this.resetPass = new AccountResetPass(this.accountService, document.getElementById('account-reset-pass'));
    this.emailSent = new AccountEmailSent(this.accountService, document.getElementById('account-email-sent'));
    this.profile = new AccountProfile(
      this.accountService,
      this.shoppingCartService,
      document.getElementById('account-profile')
    );

    this.init();

    this.overlayScroll = new OverlayScrollbar('.account-overlay-wrapper');

    pipe(
      this.accountService.accountActiveStateSubject,
      subscribe({
        next: (data: boolean) => {
          this.onActiveStateChange(data);
        }
      })
    );
    pipe(this.accountService.accountLoadingStateSubject, subscribe({next: this.onLoadingStateChange.bind(this)}));
    pipe(this.accountService.accountOverlayTypeSubject, subscribe({next: this.switchView.bind(this)}));
    pipe(
      this.accountService.accountUserDataSubject,
      subscribe({
        next: (userData: UserAccountData) => {
          Account.isLogged = !!userData;

          if (
            (!Account.isLogged && this.shoppingCartService.shoppingCart) ||
            (this.planogram.isMultipassKeyAvailable && this.shoppingCartService.shoppingCart)
          ) {
            this.shoppingCartService.clearShoppingCartTimer();
            this.shoppingCartService.updateShoppingCartState();
          }

          if (userData && !this.shoppingCartService.isMultipassEnabled) {
            this.shoppingCartService.clearShoppingCartTimer();
            this.shoppingCartService.clearShoppingCartToken();
            this.shoppingCartService.storeEmail(userData);
          }

          if (userData?.last_incomplete_checkout) {
            this.shoppingCartService.clearShoppingCartState();
            this.shoppingCartService.saveShoppingCartState({checkout: userData.last_incomplete_checkout});
          } else if (
            userData?.last_incomplete_checkout === null &&
            this.shoppingCartService.shoppingCart &&
            !this.shoppingCartService.isMultipassEnabled &&
            !this.planogram.isMultipassKeyAvailable
          ) {
            this.shoppingCartService.attachShoppingCartToUser();
          }

          this.onUserDataChange(userData);
        }
      })
    );

    window.addEventListener('storage', this.onStorageChange);

    this.initSavedUser();
  }

  private init() {
    this.accountSettings = this.planogram.ecommerceOverlaySettingsSignIn;
    this.userProfileBtnSettings = this.planogram.ecommerceOverlaySettingsButtons;
    this.userProfileBtn = document.querySelector<HTMLElement>('.account-profile-button');
    const backBtn = this.wrapperEl.querySelector<HTMLElement>('.back-btn');
    const overlayCloseBtn = this.wrapperEl.querySelector<HTMLElement>('.account-overlay-header .close-btn');
    const drawerCloseBtn = this.wrapperEl.querySelector<HTMLElement>('.account-profile-header .close-btn');
    const backdrop = this.wrapperEl.querySelector<HTMLElement>('.account-backdrop');

    this.userProfileBtn.classList.remove('is-hidden');
    InputEventUtils.addSelectEvents(this.userProfileBtn, () => {
      Metrics.storeTheEvent(AppState.planogramName, 'open', MATOMO_EVENT_NAMES.OPEN_USER_PROFILE_OVERLAY);

      if (!this.shoppingCartService.getMultipassToken && this.planogram.isMultipassKeyAvailable) {
        WebUtils.redirectToSpecificUrl(this.planogram.multipassRedirectUrl);
        return;
      }

      this.accountService.setActiveState(true);
    });

    InputEventUtils.addSelectEvents(backBtn, () => this.accountService.setOverlayType(ACCOUNT_OVERLAY_TYPE.SIGN_IN));
    InputEventUtils.addSelectEvents(overlayCloseBtn, () => this.accountService.setActiveState(false));
    InputEventUtils.addSelectEvents(drawerCloseBtn, () => this.accountService.setActiveState(false));
    InputEventUtils.addSelectEvents(backdrop, () => this.accountService.setActiveState(false));

    this.wrapperEl.classList.remove('is-completely-hidden');
    this.userProfileBtn.classList.remove('is-hidden');

    this.setOverlayFonts();
    this.setColorAndButtonSettings(this.accountSettings, 'ecommerce-account');
    this.setProfileButtonSettings(this.userProfileBtnSettings);
  }

  private setOverlayFonts() {
    this.accountSettings?.ecommerce_fonts.forEach(font => {
      const fontSettings = {...font, assignment: `ecommerce-account-${font.assignment}`};
      FontLoader.mountCustomFont(fontSettings);
    });
  }

  private setColorAndButtonSettings(settings: EcommerceOverlaySettings, assignment: string) {
    const root = document.documentElement;
    const primaryButtons = this.wrapperEl.querySelectorAll('.account-button-overlay-primary');
    const secondaryButtons = this.wrapperEl.querySelectorAll('.account-button-overlay-secondary');
    if (settings?.background_color) {
      const primaryColorRgb = AppUtils.hex2rgb(settings.background_color);

      root.style.setProperty(`--${assignment}-primary-color`, `${settings.background_color}`);
      root.style.setProperty(`--${assignment}-primary-color-rgb`, `${primaryColorRgb}`);
    }

    if (settings?.secondary_color) {
      const secondaryColorRgb = AppUtils.hex2rgb(settings.secondary_color);

      root.style.setProperty(`--${assignment}-secondary-color`, `${settings.secondary_color}`);
      root.style.setProperty(`--${assignment}-secondary-color-rgb`, `${secondaryColorRgb}`);
    }

    if (settings?.ecommerce_text_fields) {
      if (settings?.ecommerce_text_fields.border_width) {
        root.style.setProperty(
          `--${assignment}-text-fields-border-width`,
          `${parseInt(settings.ecommerce_text_fields.border_width)}px`
        );
      }
      if (settings?.ecommerce_text_fields.border_color) {
        root.style.setProperty(
          `--${assignment}-text-fields-border-color`,
          `${settings.ecommerce_text_fields.border_color}`
        );
      }
    }

    settings?.ecommerce_buttons?.forEach(button => {
      if (button.color) {
        root.style.setProperty(`--${assignment}-${button.button_type}-button-color`, `${button.color}`);
      }
      if (button.use_border) {
        root.style.setProperty(`--${assignment}-${button.button_type}-button-border-color`, `${button.border_color}`);
        root.style.setProperty(
          `--${assignment}-${button.button_type}-button-border-width`,
          `${button.border_width.replace(/px/gi, '')}px`
        );

        if (button.button_type === 'primary') {
          primaryButtons?.forEach(primaryButton => primaryButton?.classList.add('with-border'));
        } else {
          secondaryButtons?.forEach(secondaryButton => secondaryButton?.classList.add('with-border'));
        }
      }
    });
  }

  private setProfileButtonSettings(settings: EcommerceOverlaySettings) {
    settings?.ecommerce_icon_buttons?.forEach(button => {
      if (button.button_type === ECOMMERCE_BUTTON_TYPE.USER_PROFILE && button.other_asset?.url) {
        const imgElem = document.createElement('img');
        imgElem.onerror = () => {
          this.userProfileBtn.querySelector<HTMLElement>('img').remove();
        };
        imgElem.setAttribute('src', button.other_asset?.url);
        this.userProfileBtn.appendChild(imgElem);
      }
    });
  }

  private onActiveStateChange(active: boolean) {
    Account.isAccountActive = active;
    this.toggleFormFields(active);

    if (active) {
      this.wrapperEl.classList.add('active');
    } else {
      this.wrapperEl.classList.remove('active');
      if (!Account.isLogged) {
        this.wrapperEl.querySelector<HTMLElement>('.account-overlay-wrapper').addEventListener(
          'transitionend',
          () => {
            this.clearAllForms();
            this.switchView(ACCOUNT_OVERLAY_TYPE.SIGN_IN);
          },
          {once: true}
        );
      }
    }
  }

  private onLoadingStateChange(loading: boolean) {
    this.shoppingCartService.processingSubject(1, loading);
    loading ? this.wrapperEl.classList.add('loading') : this.wrapperEl.classList.remove('loading');
  }

  private switchView(type: ACCOUNT_OVERLAY_TYPE) {
    const classNames = [
      ACCOUNT_OVERLAY_TYPE.SIGN_IN,
      ACCOUNT_OVERLAY_TYPE.SIGN_UP,
      ACCOUNT_OVERLAY_TYPE.RESET_PASS,
      ACCOUNT_OVERLAY_TYPE.EMAIL_SENT,
      ACCOUNT_OVERLAY_TYPE.PROFILE
    ];

    this.wrapperEl.classList.remove(...classNames);
    this.wrapperEl.classList.add(type);
  }

  private onUserDataChange(userData: UserAccountData) {
    this.accountService.setOverlayType(userData ? ACCOUNT_OVERLAY_TYPE.PROFILE : ACCOUNT_OVERLAY_TYPE.SIGN_IN);
  }

  private onStorageChange({storageArea, key, newValue}: StorageEvent) {
    if (storageArea === window.localStorage && key === ACCESS_TOKEN_KEY) {
      newValue ? this.initSavedUser() : this.accountService.setUserData(null);
    }
  }

  private initSavedUser() {
    if (window.localStorage.getItem(ACCESS_TOKEN_KEY)) {
      this.accountService.setLoadingState(true);
      this.accountService
        .verifyToken()
        .then(resp => this.accountService.setUserData(resp.user))
        .catch(() => window.localStorage.removeItem(ACCESS_TOKEN_KEY))
        .finally(() => this.accountService.setLoadingState(false));
    } else if (this.shoppingCartService.getMultipassToken) {
      this.accountService.setUserData({
        display_name: this.shoppingCartService.email,
        email: this.shoppingCartService.email,
        last_incomplete_checkout: null
      });
    }
  }

  private clearAllForms() {
    const overlaysWithForms = [this.signIn, this.signUp, this.resetPass];

    overlaysWithForms.forEach(form => form?.clearForm());
  }

  private toggleFormFields(active: boolean) {
    const overlaysWithForms = [this.signIn, this.signUp, this.resetPass];

    overlaysWithForms.forEach(form => form?.toggleFields(active));
  }

  private cleanUp() {
    const wrapper = document.querySelector<HTMLElement>('.account-profile-button');
    HTMLUtils.removeChildElements(wrapper);
  }

  dispose() {
    window.removeEventListener('storage', this.onStorageChange);
  }
}
