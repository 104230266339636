import {EffectComposer} from 'three/examples/jsm/postprocessing/EffectComposer';
import {RenderPass} from 'three/examples/jsm/postprocessing/RenderPass';
import {SMAAPass} from 'three/examples/jsm/postprocessing/SMAAPass';
import {BlurPass} from 'postprocessing';
import {Box2, Clock, Color, Mesh, Raycaster, Scene, Vector2, Vector3, WebGLRenderer} from 'three';
import {BASE_FOV, Camera} from './camera';
import {SphereItems} from './sphere_items';
import {Planogram} from './planogram';
import {SphereShape} from 'shared/utils/SphereShape';
import {normalizeMouse} from './utils/math_utils';
import LodProvider, {LodParameters} from 'shared/lod/LodProvider';
import {SphereItem} from './sphere_item';
import {ExtraImageData, ImageId, ImageLodData, LodId} from 'shared/lod/interfaces';
import {isLodItem} from './utils/planogram_utils';
import PlanogramPoint from 'shared/utils/PlanogramPoint';
import loadingProgress, {LOADING_STAGES} from './api/services/loading_progress.service';
import {ImageMetaData, ItemData, ItemLOD} from './interfaces/planogram.interface';
import {BackgroundImageWithLod} from 'shared/interfaces/planogram';
import {backgroundDataToLodItem, backgroundToLodId, skipAtlasLevels} from 'shared/lod/helpers';
import {disposeObject3D} from './utils/disposeThree';
import {SphereItemType} from 'shared/interfaces/planogram';
import {BrowserUtils} from './utils/browser_utils';
import {debugFloatPrameter} from 'shared/utils/debug';

const BLUR_RESOLUTION_WIDTH = 960;
const MAX_LOD_LOADING_DURATION = debugFloatPrameter('MAX_LOD_LOADING_DURATION', 1700);
const BACKGROUND_RENDER_ORDER = -1; // SphereItems have render order 0

// using full device pixel ratio can put the renderer resolution over the max allowed texture size on some devices, which crashes post-processing
function limitDevicePixelRatio(renderer: WebGLRenderer) {
  const maxTextureSize = renderer.capabilities.maxTextureSize;
  const size = renderer.getSize(new Vector2());
  return Math.min(window.devicePixelRatio, maxTextureSize / size.x, maxTextureSize / size.y);
}

function getItemId(item: SphereItem): string {
  return item.id as string;
}

function getLodId(item: SphereItem): number {
  const pictureData = (item.data as unknown) as ImageMetaData;
  return pictureData.picture?.id ?? pictureData.id;
}

function pickDeviceLimits(): Partial<LodParameters> {
  if (BrowserUtils.detectWebkitInAppBrowser() && BrowserUtils.iPhoneMachineId() < 12.1) {
    return {
      physicalTextureCount: 3,
      physicalTextureResolution: 2048
    };
  } else if (BrowserUtils.isMobileSafari()) {
    return {
      physicalTextureCount: 4
    };
  } else {
    return {
      physicalTextureCount: 7
    };
  }
}

export default class CanvasRenderer {
  // TODO: make private?
  readonly camera: Camera;

  private scene: Scene;
  private renderer: WebGLRenderer;
  private composer: EffectComposer;
  private passes: {renderPass: RenderPass; blurPass: BlurPass; smaaPass: SMAAPass} = {
    renderPass: undefined,
    blurPass: undefined,
    smaaPass: undefined
  };
  private disposed = false;
  private isPaused = false;
  private clock = new Clock();
  private lodProvider: LodProvider;

  get capabilities() {
    return this.renderer.capabilities;
  }

  constructor(canvas: HTMLCanvasElement, private planogram: Planogram, sphereShape: SphereShape) {
    {
      this.scene = new Scene();
      this.scene.background = new Color(...(planogram.backgroundColor ?? []));
    }

    {
      this.camera = new Camera(planogram, sphereShape);
      this.camera.updateAspect();
      this.camera.updateCamera();
    }

    this.renderer = new WebGLRenderer({
      canvas,
      antialias: false,
      alpha: true,
      stencil: false,
      depth: true
    });
    this.renderer.autoClear = false;

    {
      this.composer = new EffectComposer(this.renderer);
      this.passes.renderPass = new RenderPass(this.scene, this.camera.perspectiveCamera);
      this.passes.renderPass.clear = false;
      this.composer.addPass(this.passes.renderPass);

      this.passes.smaaPass = new SMAAPass(window.innerWidth, window.innerHeight);
      this.composer.addPass(this.passes.smaaPass);

      this.passes.blurPass = new BlurPass({
        width: BLUR_RESOLUTION_WIDTH
      });
      this.composer.addPass(this.passes.blurPass);
    }
    this.updateRendererSize();

    {
      this.camera.onUpdate(() => {
        if (this.disposed) return;
        const planogramToCanvas = this.camera.baseZoomPlanogramHeight() / canvas.height;
        this.lodProvider.updateCamera(this.camera.planogramPoint(), this.camera.zoom() / planogramToCanvas);
        const viewport = this.camera.getViewport();
        this.viewportListeners.forEach(it => it(viewport));
      });
    }
    this.lodProvider = new LodProvider(this.renderer, {
      cdnUrl: CDN_HOST,
      ...pickDeviceLimits()
    });
  }

  private makeBackgroundItem(id: string, imageId: number) {
    const imageData: ImageMetaData = {
      id: imageId,
      imageName: id,
      image_name: id,
      name: id,
      description: ''
    };
    const planogramSize = this.planogram.size();
    const backgroundItem = new SphereItem(
      {
        id: id,
        x: 0,
        layer: 0,
        y: -planogramSize.y,
        type: SphereItemType.Image,
        width: planogramSize.x,
        height: planogramSize.x,
        data: imageData,
        renderOrder: BACKGROUND_RENDER_ORDER,
        lods: [] // otherwise SphereItem constructor makes it invisible
      },
      this.planogram
    );
    return backgroundItem;
  }

  async addItems(itemsGroup: SphereItems) {
    this.scene.add(itemsGroup);
    const images: Map<ImageId, ExtraImageData> = new Map();
    const lodData: Map<LodId, ImageLodData> = new Map();
    itemsGroup.items.forEach(item => {
      if (!isLodItem(item.itemData)) return;
      item.material = this.lodProvider.getMaterial(getItemId(item));
      const itemData = item.itemData as ItemData<ImageMetaData>;
      const lodId: LodId = getLodId(item);
      images.set(getItemId(item), {
        lodId,
        position: new PlanogramPoint(item.getViewportCenter()),
        size: item.getSize(),
        naturalResolution: new Vector2(...itemData.data?.naturalResolution)
      });

      const filteredLods = skipAtlasLevels(itemData.lods).filter((lod, i) => {
        function atlasSize(lod: ItemLOD) {
          return lod.textures[0]?.uv?.width ?? 1.0;
        }
        if (i === 0) return true;
        if (atlasSize(lod) === 1.0) return true;
        // TODO: ask BE to filter out identical atlas LOD levels
        return atlasSize(itemData.lods[i - 1]) !== atlasSize(lod);
      });

      lodData.set(lodId, {
        id: lodId,
        curator_lods: filteredLods,
        fit_size: itemData.data.fit_size,
        full_size: itemData.data.full_size,
        naturalResolution: itemData.data.naturalResolution
      });
    });
    const backgroundImage: BackgroundImageWithLod | undefined = this.planogram.background_images[0];
    if (backgroundImage !== undefined) {
      const BACKGROUND_ID = 'generated-background';
      const BACKGROUND_IMAGE_ID = -1;
      const backgroundItem = this.makeBackgroundItem(BACKGROUND_ID, BACKGROUND_IMAGE_ID);
      backgroundItem.material = this.lodProvider.getMaterial(getItemId(backgroundItem));
      await backgroundItem.createMesh(this.capabilities);
      this.scene.add(backgroundItem.object3D);

      const lodId = backgroundToLodId(backgroundImage.id);
      images.set(BACKGROUND_ID, {
        lodId,
        position: new PlanogramPoint(backgroundItem.getViewportCenter()),
        size: this.planogram.size(),
        naturalResolution: new Vector2(backgroundImage.original_width, backgroundImage.original_height)
      });
      lodData.set(lodId, backgroundDataToLodItem(backgroundImage));
    }
    this.lodProvider.updateImageList(images, (ids: number[]) => Promise.resolve(ids.map(id => lodData.get(id))));
  }

  preloadLod() {
    return new Promise<void>(resolve => {
      const lodPumpInterval = setInterval(() => this.lodProvider.update(), 0);
      let timeout: NodeJS.Timeout;
      const finish = () => {
        loadingProgress.progressStage(LOADING_STAGES.TILES_FOR_LOD, 1);
        this.lodProvider.removeLoadedListener(finish);
        clearInterval(lodPumpInterval);
        clearTimeout(timeout);
        resolve();
      };
      timeout = setTimeout(finish, MAX_LOD_LOADING_DURATION);
      this.lodProvider.addLoadedListener(finish);
      // normally LOD updates are spread over frames to minimize FPS hit
      // during initial loading it doesn't matter, so we update as often as possible to speed up loading
    });
  }

  blur(kernelSize?: number) {
    this.passes.blurPass.enabled = kernelSize !== undefined;
    this.passes.blurPass.kernelSize = kernelSize ?? 0;
  }

  start() {
    this.camera.updateCamera();
    this.renderer.setAnimationLoop(() => this.render());
  }

  pauseLodUpdates(state) {
    this.isPaused = state;
  }

  private updateRendererSize() {
    const width = window.innerWidth;
    const height = window.innerHeight;
    const pixelRatio = limitDevicePixelRatio(this.renderer);
    this.renderer.setSize(width, height);
    this.renderer.setPixelRatio(pixelRatio);
    this.composer.setSize(width, height);
    this.composer.setPixelRatio(pixelRatio);
    this.passes.smaaPass.setSize(width, height);
  }

  resize() {
    this.updateRendererSize();
    this.renderer.setRenderTarget(null);
    this.camera.updateAspect();
    this.camera.zoomIn(BASE_FOV);
    this.camera.updateCamera();
    this.composer.render();
  }

  private viewportListeners: Array<(viewport: Box2) => void> = [];

  onViewportChange(callback: (viewport: Box2) => void) {
    this.viewportListeners.push(callback);
  }

  private render() {
    if (!this.isPaused) {
      this.lodProvider.update();
    }
    const dt = this.clock.getDelta();
    this.composer.render();
    this.renderListeners.forEach(it => it(dt));
  }

  private renderListeners: Array<(dt: number) => void> = [];

  onRender(callback: (dt: number) => void) {
    this.renderListeners.push(callback);
  }

  getInteractableObjectAtScreenCoordinate(x: number, y: number): {mesh: Mesh; point: Vector3} {
    const coords = normalizeMouse(x, y);
    const raycaster = new Raycaster();
    raycaster.setFromCamera(coords, this.camera.perspectiveCamera);
    const intersects = raycaster.intersectObjects(this.scene.children, true);

    // Filter intersection that are closer than the near clipping plane.
    // Since the camera, and thus the origin of the raycaster, is located outside of the sphere
    // it can happens that when raycasting items on the opposite side of the sphere are also intersected.
    const topIntersection = intersects
      .filter(i => i.distance > Camera.NEAR_CLIPPING)
      .sort((a, b) => {
        return b.object.renderOrder - a.object.renderOrder;
      })[0];

    // Layer 2 is reserved for elements reacting to input
    const hasInput = topIntersection?.object.layers.isEnabled(2) ?? false;

    if (!hasInput) {
      return {mesh: undefined, point: undefined};
    }

    return {mesh: topIntersection.object as Mesh, point: topIntersection.point};
  }

  dispose() {
    this.lodProvider.dispose();
    this.renderer.setAnimationLoop(null);
    this.viewportListeners.splice(0, this.viewportListeners.length);
    this.renderListeners.splice(0, this.renderListeners.length);
    this.disposed = true;

    disposeObject3D(this.scene);
  }
}
