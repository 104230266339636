import {CameraControls} from './camera_controls';
import {DIRECTION} from '../shared/constants';
import {Search} from '../search/search';
import {searchEventHandler} from '../custom_event_utils';
import {SEARCH_EVENT_NAMES} from '../event-names';
import {Account} from '../account/account';
import {LayoutSwitcher} from '../components/layout-switcher/layout-switcher';
import {InputHandler} from '../input_handler';
import {Overlay} from '../overlay';
import {SphereApp} from '../sphere_app';

export enum KeyCodes {
  ESC = 'Escape',
  LEFT = 'ArrowLeft',
  UP = 'ArrowUp',
  RIGHT = 'ArrowRight',
  BOTTOM = 'ArrowDown',
  I = 'KeyI',
  L = 'KeyL',
  O = 'KeyO',
  R = 'KeyR',
  S = 'KeyS',
  A = 'KeyA'
}

const KEYBOARD_ZOOM_FACTOR = 0.965;

export class KeyboardControls {
  constructor(private cameraControls: CameraControls, private inputHandler: InputHandler, private overlay: Overlay) {
    this.onKeyDown = this.onKeyDown.bind(this);
    window.addEventListener('keydown', this.onKeyDown, false);
  }

  private onKeyDown = (event: KeyboardEvent) => {
    if (
      this.inputHandler.isOverlayShowing() ||
      Search.isSearchActive ||
      Account.isAccountActive ||
      LayoutSwitcher.isLayoutActive ||
      SphereApp.isNavigationMenuActive
    ) {
      this.handleInputOnOverlay(event);
      return;
    }

    switch (event.key) {
      case KeyCodes.UP:
        this.cameraControls.clearAnimation();
        this.inputHandler.animateCameraToClusterByArrows(DIRECTION.UP);
        break;
      case KeyCodes.RIGHT:
        this.cameraControls.clearAnimation();
        this.inputHandler.animateCameraToClusterByArrows(DIRECTION.RIGHT);
        break;
      case KeyCodes.BOTTOM:
        this.cameraControls.clearAnimation();
        this.inputHandler.animateCameraToClusterByArrows(DIRECTION.DOWN);
        break;
      case KeyCodes.LEFT:
        this.cameraControls.clearAnimation();
        this.inputHandler.animateCameraToClusterByArrows(DIRECTION.LEFT);
        break;
      case KeyCodes.A:
        this.inputHandler.navigateToAutoplayClusters();
        break;
      case KeyCodes.I:
        this.cameraControls.clearAnimation();
        this.cameraControls.zoomBy(KEYBOARD_ZOOM_FACTOR);
        break;
      case KeyCodes.O:
        this.cameraControls.clearAnimation();
        this.cameraControls.zoomBy(1 / KEYBOARD_ZOOM_FACTOR);
        break;
      case KeyCodes.L:
        this.cameraControls.autoRotate(CameraControls.ROTATE_LEFT);
        break;
      case KeyCodes.R:
        this.cameraControls.autoRotate(CameraControls.ROTATE_RIGHT);
        break;
      case KeyCodes.S:
        this.cameraControls.stopAutoRotate();
        this.inputHandler.resetAutoplay();
        break;
    }
  };

  handleInputOnOverlay(event: KeyboardEvent) {
    switch (event.key) {
      case KeyCodes.RIGHT:
        event.stopPropagation();
        this.overlay.handleRightKey();
        break;
      case KeyCodes.LEFT:
        event.stopPropagation();
        this.overlay.handleLeftKey();
        break;
      case KeyCodes.ESC:
        this.overlay.hide(true);
        searchEventHandler.emit(SEARCH_EVENT_NAMES.CLOSE_SEARCH);
        break;
    }
  }

  dispose() {
    window.removeEventListener('keydown', this.onKeyDown, false);
  }
}
