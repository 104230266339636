import {BufferAttribute, BufferGeometry, Vector2, Vector3} from 'three';
import {Planogram} from '../planogram';
import {
  createVector2Projection,
  makeFanOfVectors2,
  makeRectangleCornerIndex,
  makeRectangleLineIndex,
  makeRectangleLineUvs,
  makeRectangleLineLengths,
  makeRectangleLinePoints
} from '../utils/shape_utils';
import {SphereGeometry} from './sphere_geometry';

const CORNER_START_DIRECTION = new Vector2(0, 1);
const CORNER_END_DIRECTION = new Vector2(1, 0);

const reusableVector2 = [new Vector2(), new Vector2()];

class RectangleBorderGeometry extends BufferGeometry {
  x: number;

  y: number;

  objectScale: [number, number, number];

  thickness: number;

  cornerRadius: number;

  soft: boolean;

  planogram: Planogram;

  private safeCornerRadius: number;

  constructor(planogram, scale, x, y, thickness, cornerRadius, soft) {
    super();

    this.x = x;
    this.y = y;
    this.planogram = planogram;
    this.objectScale = scale;
    this.thickness = thickness;
    this.cornerRadius = cornerRadius;
    this.safeCornerRadius = cornerRadius;
    this.soft = soft;
    this.updateGeometry();
  }

  /**
   * Updates the geometry attributes based on current variable values
   */
  updateGeometry() {
    this.updateSafeVariables();

    const {index: cornerIndex, position: cornerPosition, uv: cornerUV} = this.getCornerAttributes();
    const {index: lineIndex, position: linePosition, uv: lineUV, lineLength} = this.getLineAttributes();

    const cornerVertexCount = cornerPosition.length / 3;
    const shiftedLineIndex = lineIndex.map(vertexIndex => vertexIndex + cornerVertexCount);

    this.setIndex(cornerIndex.concat(shiftedLineIndex));
    this.setAttribute('position', new BufferAttribute(new Float32Array(cornerPosition.concat(linePosition)), 3));
    this.setAttribute('uv', new BufferAttribute(new Float32Array(cornerUV.concat(lineUV)), 2));
    this.setAttribute(
      'lineLength',
      new BufferAttribute(new Float32Array(new Array(cornerVertexCount).fill(0).concat(lineLength)), 1)
    );
  }

  private updateSafeVariables() {
    this.safeCornerRadius = this.cornerRadius;

    // Decrease the corner radius if too big to display
    if (this.safeCornerRadius + this.thickness > Math.abs(this.objectScale[0]) / 2) {
      this.safeCornerRadius = Math.abs(this.objectScale[0]) / 2 - this.thickness;
    }
    if (this.safeCornerRadius + this.thickness > Math.abs(this.objectScale[1]) / 2) {
      this.safeCornerRadius = Math.abs(this.objectScale[1]) / 2 - this.thickness;
    }
    if (this.safeCornerRadius < 0) {
      this.safeCornerRadius = 0;
    }
  }

  /**
   * Returns the geometry attributes for rectangle side-lines
   *
   * @returns {{index: number[], position: number[], uvs: number[]}} Geomtery attributes for side-lines
   */
  private getLineAttributes() {
    const horizontalLineLength = Math.abs(this.objectScale[0]) - 2 * (this.safeCornerRadius + this.thickness);
    const verticalLineLength = Math.abs(this.objectScale[1]) - 2 * (this.thickness + this.safeCornerRadius);

    const horizontalSegments = Math.ceil(horizontalLineLength / 100);
    const verticalSegments = Math.ceil(verticalLineLength / 100);
    return {
      position: SphereGeometry.projectCoordinatesToSphere(
        this.makeRectangleLinePositions(horizontalLineLength, verticalLineLength, horizontalSegments, verticalSegments),
        this.planogram
      ),
      index: makeRectangleLineIndex(horizontalSegments, verticalSegments),
      uv: makeRectangleLineUvs(horizontalSegments, verticalSegments),
      lineLength: makeRectangleLineLengths(
        horizontalLineLength,
        verticalLineLength,
        horizontalSegments,
        verticalSegments
      )
    };
  }

  /**
   * Creates the 2D positions of all rectangle side-line vertices.
   *
   * @param {number} xLineLength Length of the rectangle border's horizontal side-lines
   * @param {number} yLineLength  Length of the rectangle border's vertical side-lines
   * @param {number} xSegmentCount Number of segments (quads) in the horizontal side-lines
   * @param {number} ySegmentCount Number of segments (quads) in the vertical side-lines
   *
   * @returns {number[]} Array of all four rectangle side-line vertex positions (2D representation)
   */
  private makeRectangleLinePositions(
    xLineLength: number,
    yLineLength: number,
    xSegmentCount: number,
    ySegmentCount: number
  ) {
    const horizontalPoints2D = makeRectangleLinePoints(2 * this.thickness, xLineLength, xSegmentCount, false);
    const verticalPoints2D = makeRectangleLinePoints(2 * this.thickness, yLineLength, ySegmentCount, true);

    const leftLinePositions2D = this.moveCenteredLine(verticalPoints2D, true, false);
    const topLinePositions2D = this.moveCenteredLine(horizontalPoints2D, false, false);
    const rightLinePositions2D = this.moveCenteredLine(verticalPoints2D, true, true);
    const bottomLinePositions2D = this.moveCenteredLine(horizontalPoints2D, false, true);

    const position2D = leftLinePositions2D.concat(topLinePositions2D, rightLinePositions2D, bottomLinePositions2D);

    return position2D;
  }

  /**
   * Moves and mirrors the vertex positions of a centered rectangle side-line to get
   * the positions of any of the four rectangle side-lines.
   *
   * @param {number[]} linePositions2D Centered position of a line
   * @param {boolean} isVertical Indicates if the line is vertical or horizontal
   * @param {boolean} shouldFlip Indicates if the line should be mirrored across object center after moving
   *
   * @returns {number[]} Array of final vertex positions for a single rectangle side-line
   */
  private moveCenteredLine(linePositions2D: number[], isVertical: boolean, shouldFlip: boolean) {
    const finalLinePositions = new Array(linePositions2D.length);
    const positionOffset = this.objectScale[isVertical ? 0 : 1] / 2;

    for (let vertexNumber = 0; vertexNumber < linePositions2D.length / 2; vertexNumber++) {
      let vertexX = linePositions2D[vertexNumber * 2];
      let vertexY = linePositions2D[vertexNumber * 2 + 1];

      if (isVertical) {
        vertexX += positionOffset;
      } else {
        vertexY += positionOffset;
      }

      if (shouldFlip) {
        vertexX = -vertexX;
        vertexY = -vertexY;
      }

      finalLinePositions[vertexNumber * 2] = vertexX + this.x + this.objectScale[0] / 2;
      finalLinePositions[vertexNumber * 2 + 1] = vertexY + this.y + this.objectScale[1] / 2;
    }

    return finalLinePositions;
  }

  /**
   * Returns the geometry attributes for rectangle corners.
   *
   * @returns {{index: number[], position: number[], uvs: number[]}} Geometry attributes for rectangle corners
   */
  private getCornerAttributes() {
    const cornerPositions: number[] = [];
    const cornerUVs: number[] = [];
    const cornerIndex: number[] = [];

    for (let cornerNumber = 0; cornerNumber < 4; cornerNumber++) {
      const {index: currentCornerIndex, position: currentCornerPositions, uvs: currentCornerUVS} =
        this.soft || this.cornerRadius > 0
          ? this.getSoftCornerAttributes(cornerNumber)
          : this.getHardCornerAttributes(cornerNumber);

      cornerPositions.push(...currentCornerPositions);
      cornerUVs.push(...currentCornerUVS);
      cornerIndex.push(...currentCornerIndex.map(value => value + (cornerNumber * currentCornerPositions.length) / 3));
    }

    return {
      position: cornerPositions,
      index: cornerIndex,
      uv: cornerUVs
    };
  }

  /**
   * Returns the geometry attributes for hard rectangle corners without corner-radius.
   * Creates four squares and moves them to appropriate corners.
   *
   * @param {number} cornerNumber Index of the rectangle corner (0-3)
   * @returns {{index: number[], position: number[], uvs: number[]}} Geomtery attributes for hard corners
   */
  private getHardCornerAttributes(cornerNumber: number) {
    const lineWidth = this.thickness * 2;
    const squareCornerPositions = [0, lineWidth, 0, 0, lineWidth, lineWidth, lineWidth, 0];

    return {
      index: [0, 1, 2, 3, 2, 1],
      position: SphereGeometry.projectCoordinatesToSphere(
        this.moveCenteredCorner(squareCornerPositions, cornerNumber),
        this.planogram
      ),
      uvs: [1, 0, 1, 0, 1, 0, 1, 0]
    };
  }

  /**
   * Returns the geometry attributes for soft rectangle corners and/or corners with cornerRadius.
   *
   * @param {number} cornerNumber Index of the rectangle corner (0-3)
   * @returns {{index: number[], position: number[], uvs: number[]}} Geomtery attributes for a single soft corner
   */
  private getSoftCornerAttributes(cornerNumber: number) {
    const divisionFactor = 2; // Defines how many times all corner segments will be split into two
    const vertexCount = 2 * 2 ** divisionFactor + 3;
    const vertexCoordinates2D = this.makeSoftCornerPositions(divisionFactor);

    return {
      position: SphereGeometry.projectCoordinatesToSphere(
        this.moveCenteredCorner(vertexCoordinates2D, cornerNumber),
        this.planogram
      ),
      index: makeRectangleCornerIndex(vertexCount, cornerNumber),
      uvs: this.convertCornerPositionsToUVs(vertexCoordinates2D)
    };
  }

  /**
   * Returns the 2D positions of vertices for a single soft corner. Geometry wraps the corner tightly,
   * but leaves enough space for the fragment shader to draw a perfect arc. Returns positions for the top-right corner,
   * centered in the middle - after needs to be translated / mirrored into its place.
   *
   * TODO: draw an image of the geometry, add to Confluence documentation, add link here
   *
   * @param {number} divisionFactor Number of times all corner segments are split into two
   * @returns {number[]} The centered 2D vertex positions of the top-right corner
   */
  private makeSoftCornerPositions(divisionFactor: number) {
    const vertexCount = 2 * 2 ** divisionFactor + 3;
    const vertexCoordinates2D = new Array(vertexCount * 2).fill(0);

    // create fan of vectors across 90 degrees, to be used for top-right corner geometry segmentation
    const cornerSegmentDirections = makeFanOfVectors2(CORNER_START_DIRECTION, CORNER_END_DIRECTION, divisionFactor);
    cornerSegmentDirections.push(CORNER_END_DIRECTION.clone());

    // Add the first outer vertex
    vertexCoordinates2D[0] = 0;
    vertexCoordinates2D[1] = this.safeCornerRadius + 2 * this.thickness;

    // Add two more vertices for each segment
    for (let divNumber = 0; divNumber < cornerSegmentDirections.length - 1; divNumber++) {
      const startDirection = cornerSegmentDirections[divNumber];
      const endDirection = cornerSegmentDirections[divNumber + 1];

      // inner vertex
      vertexCoordinates2D[2 + divNumber * 2 * 2] = startDirection.x * this.safeCornerRadius;
      vertexCoordinates2D[2 + divNumber * 2 * 2 + 1] = startDirection.y * this.safeCornerRadius;

      // outer vertex
      const midDirection = reusableVector2[0];
      const projection = reusableVector2[1];

      midDirection.copy(startDirection).add(endDirection).normalize();

      createVector2Projection(midDirection, startDirection, projection);
      const midVertexDistance = (2 * this.thickness + this.safeCornerRadius) / projection.length();
      midDirection.multiplyScalar(midVertexDistance);

      vertexCoordinates2D[4 + divNumber * 2 * 2] = midDirection.x;
      vertexCoordinates2D[4 + divNumber * 2 * 2 + 1] = midDirection.y;
    }

    // add final two vertices
    vertexCoordinates2D[vertexCoordinates2D.length - 4] = CORNER_END_DIRECTION.x * this.safeCornerRadius;
    vertexCoordinates2D[vertexCoordinates2D.length - 3] = CORNER_END_DIRECTION.y * this.safeCornerRadius;

    vertexCoordinates2D[vertexCoordinates2D.length - 2] =
      CORNER_END_DIRECTION.x * (this.safeCornerRadius + 2 * this.thickness);
    vertexCoordinates2D[vertexCoordinates2D.length - 1] =
      CORNER_END_DIRECTION.y * (this.safeCornerRadius + 2 * this.thickness);

    return vertexCoordinates2D;
  }

  /**
   * Maps soft corner positions to corner UVs. UVs go from 0 to 1 on both axis,
   * (0, 0) being the center of the corner radius and (1,0) or (0,1) being the outer edge of the corner.
   *
   * @param {number} positions2D Corner vertex positions
   * @returns {number[]}} Corner UV attribute
   */
  private convertCornerPositionsToUVs(positions2D: number[]) {
    const cornerUVs: number[] = new Array(positions2D.length);

    for (let vertexNumber = 0; vertexNumber < positions2D.length / 2; vertexNumber++) {
      const vertexX = positions2D[vertexNumber * 2];
      const vertexY = positions2D[vertexNumber * 2 + 1];

      cornerUVs[vertexNumber * 2] = vertexX / (this.safeCornerRadius + 2 * this.thickness);
      cornerUVs[vertexNumber * 2 + 1] = vertexY / (this.safeCornerRadius + 2 * this.thickness);
    }

    return cornerUVs;
  }

  /**
   * Moves and mirrors the vertex positions of a centered rectangle corner to get
   * the final positions of any of the four rectangle corners.
   *
   * @param {number[]} cornerPositions2D 2D positions of corner vertices
   * @param {number} cornerNumber Index of the corner
   * @returns {number[]} Array of final vertex positions for a single corner
   */
  private moveCenteredCorner(cornerPositions2D: number[], cornerNumber: number) {
    // translates and mirrors the corner from center to its designated place
    const xDimensionFactor = [0, 1].includes(cornerNumber) ? -1 : 1;
    const yDimensionFactor = [1, 2].includes(cornerNumber) ? 1 : -1;

    const xOffsetFromCenter = Math.abs(this.objectScale[0]) / 2 - this.safeCornerRadius - this.thickness;

    const yOffsetFromCenter = Math.abs(this.objectScale[1]) / 2 - this.safeCornerRadius - this.thickness;

    const finalCornerPositions: number[] = new Array(cornerPositions2D.length);

    cornerPositions2D.forEach((position, index) => {
      if (index % 2 === 0) {
        finalCornerPositions[index] =
          (position + xOffsetFromCenter) * xDimensionFactor + this.x + this.objectScale[0] / 2;
      }
      if (index % 2 === 1) {
        finalCornerPositions[index] =
          (position + yOffsetFromCenter) * yDimensionFactor + this.y + this.objectScale[1] / 2;
      }
    });

    return finalCornerPositions;
  }

  dispose() {
    this.planogram = undefined;
    super.dispose();
  }
}

export default RectangleBorderGeometry;
