const MATOMO_EVENT_NAMES = {
  WEBGL_OPEN_PRODUCT_OVERLAY: 'open-product_overlay',
  CLICK_GALLERY_INFO_BUTTON: 'click-gallery_info_button',
  CLICK_PRODUCT_GALLERY_CLOSE_BUTTON: 'click-product_gallery-close_button',
  CLICK_PRODUCT_GALLERY_ARROW: 'click-product_gallery-arrow',
  CLICK_PRODUCT_DETAILS_CLOSE_BUTTON: 'click-product_details-close_button',
  CLICK_PRODUCT_DETAILS_ARROW: 'click-product_details-arrow',
  CLICK_PRODUCT_DETAILS_BACK_BUTTON: 'click-product_details-back_button',
  CLICK_PRODUCT_GALLERY_CHECKOUT_BUTTON: 'click-product_gallery-checkout_button',
  CLICK_PRODUCT_DETAILS_CHECKOUT_BUTTON: 'click-product_details-checkout_button',
  WEBGL_OPEN_VIDEO_OVERLAY: 'open-video',
  WEBGL_OPEN_SINGLE_IMAGE_OVERLAY: 'open-singleimage',
  WEBGL_CLICK_LINK: 'click-link',
  WEBGL_CLICK_ANIMATE: 'click-animate',
  CLICK_PRODUCT_GALLERY_PRODUCT_IMAGE: 'click-product_gallery-product_image',
  CLICK_PRODUCT_DETAILS_PRODUCT_IMAGE: 'click-product_details-product_image',
  CLICK_PRODUCT_GALLERY_PRODUCT_THUMBNAIL: (type, identifier, productName) => `click-product_gallery-product_${type}_thumbnail-${identifier}-${productName}`,
  CLICK_PRODUCT_DETAILS_PRODUCT_THUMBNAIL: (type, identifier, productName) => `click-product_details-product_${type}_thumbnail-${identifier}-${productName}`,
  CLICK_SHARE_BUTTON: 'click-share_button-',
  CLICK_EVENT: 'click-',
  CLICK_COPY_LINK: 'click-copy_link',
  CLICK_CUSTOM_BUY_BUTTON: 'click-custom_buy_button',
  CLICK_CUSTOM_BUY_SET_BUTTON: 'click-custom_buy_set_button',
  CLICK_RAKUTEN_BUY_BUTTON: 'click-rakuten_buy_button',
  CLICK_EBAY_BUTTON: 'click-ebay_button',
  CLICK_CHECKOUT_RAKUTEN_BROWSE: 'click-checkout-rakuten_browse',
  CLICK_CHECKOUT_RAKUTEN_BUY: 'click-checkout-rakuten_buy',
  CLICK_CHECKOUT_EBAY: 'click-checkout-ebay',
  CLICK_CHECKOUT_CLOSE_BUTTON: 'click-checkout-close_button',
  CLICK_CHECKOUT_BACK_BUTTON: 'click-checkout-back_button',
  CLICK_CHECKOUT_EXPAND_BUTTON: 'click-checkout-expand_button',
  CLICK_CHECKOUT_PAGINATION_ARROW: 'click-checkout-pagination_arrow',
  WEBGL_OPEN_PRIVACY_OVERLAY: 'open-privacy_overlay',
  WEBGL_OPEN_COOKIES_POLICY_OVERLAY: 'open-cookies_policy_overlay',
  WEBGL_OPEN_COPYRIGHT_OVERLAY: 'open-copyright_overlay',
  WEBGL_CLICK_ABOUT_US: 'click-about_us',
  WEBGL_CLICK_CONTACT_US: 'click-contact_us',
  WEBGL_CLICK_CENTRAL_CARD: 'click-central_card',
  WEBGL_CLICK_SPHERE: 'click-sphere',
  WEBGL_CLICK_IFRAME: `click-iframe`,
  WEBGL_CLICK_CONTENT_OVERLAY: `click-content_overlay`,
  WEBGL_CLICK_SOCIAL_CONTENT_OVERLAY: 'click-social_content_overlay',
  WEBGL_CLICK_VIDEO: (name, url, duration) => `click-background_video-${name}-${url}-${duration}`,
  CLICK_LOGO: 'click-logo',
  CLICK_CONTROL_BUTTON: (name) => `click-control_button-${name}`,
  OPEN_PRODUCT_OVERLAY_DESCRIPTION: 'open-product_overlay-description',
  CLICK_SEARCH_BUTTON_DEFAULT: 'click-search_button-default_state',
  CLICK_SEARCH_BUTTON_ACTIVE: 'click-search_button-active_state',
  CLICK_SEARCH_RESULT: (identifier, productName) => `click-search-result-${identifier}-${productName}`,
  CLICK_SEARCH_RESULT_ICON: (sphereName) => `click-search-result_icon-${sphereName}`,
  INPUT_SEARCH_QUERY: (query) => `search-query-${query}`,
  CLICK_MOBILE_ORIENTATION_POPUP_CLOSE_BUTTON: 'click-mobile_orientation_prompting_popup-close_button',
  ROTATE_MOBILE_ORIENTATION_POPUP: 'rotate-mobile_orientation_prompting_popup',
  ECOMMERCE_CLICK_ADD_TO_CART: (identifier, productName) => `click-e_com_overlay-add_to_cart-${identifier}-${productName}`,
  ECOMMERCE_CLICK_BUY_NOW: (identifier, productName) => `click-e_com_overlay-buy_now-${identifier}-${productName}`,
  CLICK_ECOMMERCE_OVERLAY_PRODUCT_IMAGE: (identifier) => `сlick-e_com_overlay-product_details-product_image-${identifier}`,
  CLICK_ECOMMERCE_PRODUCT_THUMBNAIL: (thumbnailType, identifier, productName) => `click-e_com_overlay-product_details-product_${thumbnailType}_thumbnail-${identifier}-${productName}`,
  CLICK_ECOMMERCE_OVERLAY_CLOSE_BUTTON: (identifier, productName) => `click-e_com_overlay-close_button-${identifier}-${productName}`,
  CLICK_ECOMMERCE_OVERLAY_PRODUCT_DETAILS_DESCRIPTION: (identifier, productName) => `open-e_com_overlay-product_details_description-${identifier}-${productName}`,
  SELECT_ECOMMERCE_OVERLAY_PRODUCT_VARIANT: (optionName, optionValue, identifier, productName) => `select-e_com_overlay-option-${optionName}-option_value-${optionValue}-${identifier}-${productName}`,
  OPEN_USER_PROFILE_OVERLAY: 'open-user_profile_overlay',
  CLICK_USER_PROFILE_OVERLAY_LOGOUT: 'click-user_profile_overlay-logout',
  CLICK_USER_PROFILE_OVERLAY_LOGIN: 'click-user_profile_overlay-login',
  CLICK_USER_PROFILE_OVERLAY_CREATE_ACC: 'click-user_profile_overlay-create_an_account',
  CLICK_USER_PROFILE_OVERLAY_RESET_PASSWORD: 'click-user_profile_overlay-reset_password',
  OPEN_SHOPPING_CART: 'open-shopping_cart',
  CLICK_SHOPPING_CART_OVERLAY_CLOSE_BUTTON: 'click-shopping_cart_overlay-close_button',
  CLICK_SHOPPING_CART_OVERLAY_CHECKOUT_BUTTON: 'click-shopping_cart_overlay-checkout_button',
  CLICK_SHOPPING_CART_OVERLAY_INCREASE_ITEM_QUANTITY: (identifier, productName) => `click-shopping_cart_overlay-increase_item_quantity-${identifier}-${productName}`,
  CLICK_SHOPPING_CART_OVERLAY_DECREASE_ITEM_QUANTITY: (identifier, productName) => `click-shopping_cart_overlay-decrease_item_quantity-${identifier}-${productName}`,
  CLICK_SHOPPING_CART_OVERLAY_REMOVE_PRODUCT: (identifier, productName) => `click-shopping_cart_overlay-remove_product-${identifier}-${productName}`,
  CLICK_SHOPPING_CART_OVERLAY_PRODUCT_TITLE: (identifier, productName) => `click-shopping_cart_overlay-product_title-navigate_to_the_PDP-${identifier}-${productName}`,
  OPEN_RESET_PASSWORD: 'open-user_profile_overlay-open_reset_password',
  OPEN_CREATE_ACCOUNT: 'open-user_profile_overlay-open_create_an_account',
  OPEN_LOG_IN: 'open-user_profile_overlay-open_login',
  CLICK_COOKIES_POPUP_AUDIO_TOGGLE: 'click-cookies_popup-audio_toggle',
  CLICK_COOKIES_POPUP_FULLSCREEN_TOGGLE: 'click-cookies_popup-fullscreen_toggle',
  CLICK_ECOMMERCE_OVERLAY_NOTIFY_ME_BUTTON: (identifier, productName) => `click-e_com_overlay-notify_me-${identifier}-${productName}`,
  CLICK_ECOMMERCE_OVERLAY_POPUP_CHECKOUT_BUTTON: (identifier, productName) => `click-e_com_overlay-pop_up-checkout-${identifier}-${productName}`,
  CLICK_MENU_BUTTON: (name) => `click-menu_item_${name}`,
  CAMERA_ZOOM: (zoomDir, zoomLvl, x, y, itemName?) => `${zoomDir}-zoom_lvl_${zoomLvl}-x_${x}_y_${y}${itemName ? `-${itemName}` : ''}`,
  DRAG_EVENT: (direction, fromX, fromY, toX, toY, itemName?) => `drag_${direction}-from_x_${fromX}_y_${fromY}-to_x_${toX}_y_${toY}${itemName ? `-${itemName}` : ''}`,
  NAVIGATION_ARROW_CLICK: (arrowType) => `click-navigation_arrow-${arrowType}_button`
};

export {MATOMO_EVENT_NAMES};
