import {Options} from '@splidejs/splide';
import subscribe from 'callbag-subscribe';
import pipe from 'callbag-pipe';

import {ShopifyProductInfoService} from '../../api/services/shopify-product_info.service';
import {SphereItem} from '../../sphere_item';
import {ShopifyService} from '../../api/services/shopify.service';
import {shopifyOverlayView} from './shopify-product_info.view';
import {ShopifyProduct, ShopifyVariant} from '../../interfaces/shopify-product.interface';
import {ProductSlides} from '../../product_slides';
import {InputEventUtils} from '../../utils/input_event_utils';
import {Metrics} from '../../metrics';
import {MATOMO_EVENT_NAMES} from '../../metric-events';
import {ProductFields} from './components/product-fields/product-fields_component';
import {ShopifyButtons} from './components/buttons/shopify-buttons_component';
import {VariantsComponent} from './components/variants/variants_component';
import {EcommerceOverlaySettings} from '../../interfaces/planogram.interface';
import {AppUtils} from '../../utils/app_utils';
import {FontLoader} from '../../font_loader';
import {ShoppingCartService} from '../../api/services/shopping-cart.service';
import {L10nUtils} from '../../utils/l10n_utils';
import {CurrencyService} from '../../api/services/currency.service';
import {CurrencyUtils} from '../../utils/currency_utils';
import {AppState} from '../../shared/app.state';
import {ProductInfoBase} from '../product-info/product-info-base_overlay';
import {PRODUCT_GALLERY_ITEM} from '../../shared/constants';
import {SphereApp} from '../../sphere_app';
import {shopifyOverlayHandler} from '../../custom_event_utils';
import {QuantityPicker} from './components/quantity-picker/quantity-picker';
import {ShoppingCartWidgetComponent} from '../../shopping-cart-widget/shopping-cart-widget.component';
import {InputFieldsComponent} from './harry-potter-overlays/input-fields/input-fields-component';
import {HP_OVERLAY_THEMES} from '../../interfaces/product.interface';

export class ShopifyProductInfo extends ProductInfoBase {
  protected readonly shopifyService: ShopifyService;
  product: ShopifyProduct;
  protected productFields: ProductFields;
  protected quantityPicker: QuantityPicker;
  protected buttons: ShopifyButtons;
  protected overlaySettings: EcommerceOverlaySettings;
  protected quantityVideoSlides: number;
  protected readonly blurInformationBound: any;
  protected shoppingCartWidget: ShoppingCartWidgetComponent;
  private readonly updateFocusStateBound: any;
  inputFields?: InputFieldsComponent;
  openPersonalizedOverlay: Function;
  showShoppingCartWidget: boolean = false;
  variant: ShopifyVariant;
  private isInputFieldFocused: boolean = false;
  protected variantComponents: Array<VariantsComponent>;

  constructor(
    container: HTMLElement,
    item: SphereItem,
    protected sphereApp: SphereApp,
    options: any,
    productInfoService: ShopifyProductInfoService,
    protected shoppingCartService: ShoppingCartService,
    protected currencyService: CurrencyService,
    closeCallback: Function
  ) {
    super(container, item, options, productInfoService, closeCallback);
    this.shopifyService = new ShopifyService();
    this.quantityVideoSlides = 0;
    this.blurInformationBound = this.blurInformation.bind(this);
    this.updateFocusStateBound = this.updateFocusState.bind(this);

    pipe(
      this.shopifyService.selectedProductSubject,
      subscribe({
        next: (variant: any) => {
          this.updateProductPrice(variant);
          this.getPreSelectSlide(variant);
          this.variant = variant;
        }
      })
    );

    pipe(
      this.shoppingCartService.processingSubject,
      subscribe({
        next: (loading: boolean) => {
          if (
            !loading &&
            this.shoppingCartWidget &&
            !this.shoppingCartWidget?.panelVisibility &&
            this.showShoppingCartWidget
          ) {
            this.shoppingCartWidget.panelVisibility = true;
          }
        }
      })
    );

    shopifyOverlayHandler.listen('shoppingCartVisibility', this.blurInformationBound);
    shopifyOverlayHandler.listen('inputFieldFocus', this.updateFocusStateBound);
  }

  get enablePersonalizeOverlay() {
    return (
      this.product.theme_name === HP_OVERLAY_THEMES.FROG ||
      this.product.theme_name === HP_OVERLAY_THEMES.ACCEPTANCE_LETTER ||
      this.product.theme_name === HP_OVERLAY_THEMES.TRUNK
    );
  }

  protected async init(): Promise<void> {
    this.overlaySettings = this.item.planogram.ecommerceOverlaySettingsPdp;
    await super.init();
    if (this.enablePersonalizeOverlay) {
      this.initPersonalizeOverlay(this.product);
    }
    this.initFlipbook(this.product);
    this.shopifyService.updateProductData(this.product);
  }

  private getPreSelectSlide = variant => {
    if (!variant?.image_id) {
      return;
    }
    const imgVariantIndex =
      this.quantityVideoSlides +
      this.product?.product_images.findIndex(
        img => img?.shopify_id === variant.image_id || img?.id === variant.image_id
      );
    const currentSlideIndex = this.gallerySplide.index;

    if (imgVariantIndex >= 0 && imgVariantIndex !== currentSlideIndex) {
      this.gallerySplide.go(imgVariantIndex);
    }
  };

  protected setItemContent() {
    this.container.innerHTML = shopifyOverlayView(this.product);
    this.mainContainer = this.container.querySelector('.app-shopify-template');
    if (this.overlaySettings.enable_buttons) {
      this.variantComponents = this.product.options
        .sort((prevOption, nextOption) => prevOption.position - nextOption.position)
        .map(option => {
          return new VariantsComponent(option, this.product, this.mainContainer, this.shopifyService);
        });
      this.quantityPicker = new QuantityPicker(
        this.product,
        this.mainContainer,
        this.shopifyService,
        this.shoppingCartService
      );
      this.buttons = new ShopifyButtons(
        this,
        this.product,
        this.overlaySettings,
        this.mainContainer,
        this.quantityPicker,
        this.shopifyService,
        this.shoppingCartService
      );
      this.shoppingCartWidget = new ShoppingCartWidgetComponent(
        this.sphereApp,
        this.shoppingCartService,
        this.currencyService,
        this.quantityPicker,
        'app-shopify-cart-container',
        this.product
      );
    }
    this.productFields = new ProductFields(this.product, this.mainContainer, this.overlaySettings);
    this.setOverlayFonts();
    this.syncEcommerceOverlayColor();
  }

  protected initImageSlider() {
    super.initImageSlider();
    if (this.product.video_first) {
      this.quantityVideoSlides = this.productSlides.slides.reduce((acc, item) => {
        return item.type === PRODUCT_GALLERY_ITEM.VIDEO ? ++acc : acc;
      }, 0);
    }
  }

  private initFlipbook({preview_pictures_enabled, preview_pictures = []}: ShopifyProduct) {
    const seeInsideBtn = document.getElementById('see_inside-button');

    if (preview_pictures_enabled && seeInsideBtn) {
      import(/* webpackChunkName: "flip-book_overlay", webpackPreload: true */ '../flip-book_overlay')
        .then(({FlipBookOverlay}) => {
          // TODO Replace with utils function that does this logic (implemented in one of the envs)
          const pagesSlides = new ProductSlides({product_images: preview_pictures} as ShopifyProduct, this.item);

          const backCb = () => (this.mainContainer.hidden = false);
          const closeCb = () => super.closeOverlay();
          const openFlipBook = () => {
            this.mainContainer.hidden = true;
            // tslint:disable-next-line:no-unused-expression
            new FlipBookOverlay(this.container, pagesSlides.slides, backCb, closeCb);
          };

          seeInsideBtn.classList.remove('is-hidden');
          InputEventUtils.addSelectEvents(seeInsideBtn, openFlipBook);
        })
        .catch(console.warn);
    }
  }

  private initPersonalizeOverlay({theme_name}: ShopifyProduct) {
    const container = this.mainContainer.querySelector<HTMLElement>('.app-shopify-product-overlay') as HTMLElement;
    let templatePath, templateName;

    switch (true) {
      case theme_name === HP_OVERLAY_THEMES.ACCEPTANCE_LETTER:
      case theme_name === HP_OVERLAY_THEMES.TRUNK: {
        templatePath = './harry-potter-overlays/acceptance-letter-trunk/personalized-acceptance-letter-trunk-overlay';
        templateName = 'PersonalizedAcceptanceLetterAndTrunkOverlay';
        break;
      }

      case theme_name === HP_OVERLAY_THEMES.FROG: {
        templatePath = './harry-potter-overlays/frog/personalized-frog-overlay';
        templateName = 'PersonalizedFrogOverlay';
        break;
      }
    }

    import(/* webpackChunkName: "personalized_overlay", webpackPreload: true */ `${templatePath}`)
      .then(data => {
        const backCb = () => (this.mainContainer.hidden = false);
        const closeCb = () => super.closeOverlay();
        this.openPersonalizedOverlay = () => {
          this.mainContainer.hidden = true;
          // tslint:disable-next-line:no-unused-expression
          new data[templateName](
            container,
            this,
            this.product,
            this.quantityPicker,
            this.shoppingCartService,
            this.shopifyService,
            backCb,
            closeCb
          );
        };
      })
      .catch(console.warn);
  }

  private updateFocusState() {
    this.isInputFieldFocused = !this.isInputFieldFocused;
    this.gallerySplide.options = {keyboard: !this.isInputFieldFocused};
    this.gallerySplide.emit('updated');
  }

  protected setOverlayFonts() {
    this.overlaySettings?.ecommerce_fonts.forEach(font => {
      const fontSettings = {...font, assignment: `ecommerce-overlay-${font.assignment}`};
      FontLoader.mountCustomFont(fontSettings);
    });
  }

  protected syncEcommerceOverlayColor() {
    const root = document.documentElement;
    if (this.overlaySettings?.background_color) {
      const ecommerceOverlayColorRgb = AppUtils.hex2rgb(this.overlaySettings.background_color);

      root.style.setProperty(`--ecommerce-overlay-primary-color`, `${this.overlaySettings.background_color}`);
      root.style.setProperty(`--ecommerce-overlay-primary-color-rgb`, `${ecommerceOverlayColorRgb}`);
    }

    if (this.overlaySettings?.secondary_color) {
      const ecommerceOverlayColorRgb = AppUtils.hex2rgb(this.overlaySettings.secondary_color);

      root.style.setProperty(`--ecommerce-overlay-secondary-color`, `${this.overlaySettings.secondary_color}`);
      root.style.setProperty(`--ecommerce-overlay-secondary-color-rgb`, `${ecommerceOverlayColorRgb}`);
    }
  }

  protected initProductPrice() {}

  protected initDescriptionControls() {}

  protected initGalleryViewControls() {}

  protected initOverlayElements() {}

  initPurchasingFlow() {}

  setCustomButtons(buttons) {}

  protected initOverlayControls() {
    const closeButton: HTMLElement = this.mainContainer.querySelector<HTMLElement>(
      '#shopify-product-details-container .overlay-button-close'
    );

    if (closeButton) {
      InputEventUtils.addSelectEvents(closeButton, e => {
        Metrics.storeTheEvent(
          this.item.planogram.name,
          'click',
          MATOMO_EVENT_NAMES.CLICK_ECOMMERCE_OVERLAY_CLOSE_BUTTON(this.productIdentifier, this.productName)
        );
        this.closeOverlay(e);
      });
    } else {
      console.error('element not found - ".overlay-button-close"');
    }
  }

  private blurInformation(options) {
    options.value
      ? this.mainContainer.classList.add('is-cart-active')
      : this.mainContainer.classList.remove('is-cart-active');
  }

  private getStockValue(variant: ShopifyVariant) {
    const priceWrapper = this.mainContainer.querySelector<HTMLElement>('.app-shopify-product-price');
    const stockValueContainer = priceWrapper.querySelector<HTMLElement>('.app-shopify-product-stock-value');
    let stockValue: string;

    if (variant?.isAutoSelected) {
      stockValueContainer.classList.add('is-hidden');
    } else {
      stockValueContainer.classList.remove('is-hidden');
    }

    switch (true) {
      case variant?.inventory_quantity > 0 && variant?.inventory_quantity < 10: {
        stockValue = `${L10nUtils.l10n('shopify-product-overlay.product-stock-n')}: ${variant?.inventory_quantity}`;
        break;
      }

      case variant?.inventory_quantity >= 10: {
        stockValue = L10nUtils.l10n('shopify-product-overlay.product-stock-available');
        break;
      }

      default: {
        stockValue = L10nUtils.l10n('shopify-product-overlay.product-out-of-stock');
      }
    }

    (stockValueContainer as HTMLElement).innerText = stockValue;
  }

  protected updateProductPrice(selectedVariant) {
    if (!this.mainContainer) {
      return;
    }
    const priceWrapper = this.mainContainer.querySelector<HTMLElement>('.app-shopify-product-price');
    const priceContainer = priceWrapper.querySelector<HTMLElement>('.app-shopify-product-price-value');
    const discountPriceContainer = priceWrapper.querySelector<HTMLElement>('.app-shopify-product-discount-price-value');
    priceWrapper.classList.remove('has-discount-price', 'is-unavailable', 'is-unavailable-without-margin');

    this.getStockValue(selectedVariant);

    if (!selectedVariant && !selectedVariant.isAutoSelected) {
      priceWrapper.classList.add('is-unavailable-without-margin');
      (priceContainer as HTMLElement).innerText = '';
      (discountPriceContainer as HTMLElement).innerText = '';
      return;
    } else if (selectedVariant.inventory_quantity <= 0 && !selectedVariant.isAutoSelected) {
      priceWrapper?.classList.add('is-unavailable');
    }

    const {selectedCurrencyCode, currenciesList} = this.currencyService;
    const getPriceValue = price => CurrencyUtils.getPriceWithSymbolOrCode(price, selectedCurrencyCode, currenciesList);

    if (selectedVariant?.compare_at_price && parseFloat(selectedVariant?.compare_at_price)) {
      // due to weird behaviour of shopify api discount price logic
      (priceContainer as HTMLElement).innerText = getPriceValue(selectedVariant.compare_at_price);
      (discountPriceContainer as HTMLElement).innerText = getPriceValue(selectedVariant.price);
      priceWrapper.classList.add('has-discount-price');
    } else {
      (priceContainer as HTMLElement).innerText = getPriceValue(selectedVariant.price);
      (discountPriceContainer as HTMLElement).innerText = '';
    }
  }

  protected handleImageMatomoEvents(e: Event) {
    const target = e.target as HTMLElement;
    if (target.closest('.splide__slide')) {
      let name = '';
      let imgElement: HTMLElement;

      if (target.nodeName === 'LI') {
        imgElement = target.querySelector<HTMLElement>('img');
      } else if (target.nodeName === 'IMG') {
        imgElement = target;
      }

      if (imgElement) {
        name = imgElement.getAttribute('data-name');
      }

      Metrics.storeTheEvent(
        AppState.planogramName,
        'click',
        `${MATOMO_EVENT_NAMES.CLICK_ECOMMERCE_OVERLAY_PRODUCT_IMAGE(this.product?.identifier)}${name ? '-' + name : ''}`
      );
    }
  }

  protected handleThumbnailMatomoEvents(e: Event) {
    const target = e.target as HTMLElement;
    if (target.closest('.splide__slide')) {
      const thumbnailImage = target.closest('.splide__slide').querySelector<HTMLElement>('img');
      const thumbnailType = thumbnailImage.getAttribute('data-type');

      Metrics.storeTheEvent(
        AppState.planogramName,
        'click',
        MATOMO_EVENT_NAMES.CLICK_ECOMMERCE_PRODUCT_THUMBNAIL(thumbnailType, this.productIdentifier, this.productName)
      );
    }
  }

  protected getGalleryCarouselConfig(productSlides: ProductSlides): Options {
    return {
      type: 'loop',
      perPage: 1,
      pagination: false,
      keyboard: true,
      speed: 100,
      updateOnMove: true,
      lazyLoad: 'nearby',
      // @ts-ignore
      video: {
        playerOptions: {
          youtube: {
            playlist: undefined
          }
        }
      }
    };
  }

  protected getThumbsCarouselConfig(productSlides: ProductSlides): Options {
    return {
      autoWidth: true,
      autoHeight: true,
      gap: '1.53vw',
      speed: 150,
      rewind: true,
      pagination: false,
      isNavigation: true,
      lazyLoad: 'sequential',
      perPage: Math.min(productSlides.length - 1, 5),
      focus: 4,
      perMove: 1,
      keyboard: false,
      breakpoints: {
        1200: {
          gap: 18
        },
        1024: {
          gap: 16
        }
      }
    };
  }

  validateVariants() {
    return this.variantComponents.every(variantComponent => variantComponent?.validate());
  }

  dispose() {
    // TODO: Fix unsubscribe from listeners in splide
    //  (splide video extension not able to destroy with invalid youtube video in slide)
    try {
      this.gallerySplide?.destroy(true);
      this.thumbnailSplide?.destroy(true);
    } catch (e) {
      console.error('Gallery destroy error:', e);
    }
    this.shoppingCartWidget?.dispose();
    this.quantityPicker?.dispose();
    this.inputFields?.dispose();
  }
}
