import type { WebGLRenderer } from 'three';
import {
  Vector2,
  DataTexture,
  RGBAFormat,
  UnsignedByteType,
  UVMapping,
  RepeatWrapping,
  LinearFilter,
} from 'three';

import {
  EMPTY_MAP_TEXTURE,
  ERROR_MAP_TEXTURE,
  TILES_IN_TEXTURE,
  TEXTURE_SIZE_PACKING,
  TILE_SIZE,
  MIN_ATLAS_POWER,
  ATLAS_PACKING,
} from './parameters';

interface TileLocation {
  textureIndex: number;
  x: number;
  y: number;
  size: number; // size in highest quality tiles
  ratio: number; // part of the tile occupied by the image for atlas support
  atlasX: number;
  atlasY: number;
}

const EMPTY_LOCATION: TileLocation = {
  textureIndex: ERROR_MAP_TEXTURE,
  x: 0,
  y: 0,
  size: 1,
  ratio: 1,
  atlasX: 0,
  atlasY: 0,
};

const FULL_LOCATION: TileLocation = { ...EMPTY_LOCATION };

export const TRANSPARENT_TILE: TileLocation = {
  textureIndex: EMPTY_MAP_TEXTURE,
  x: 0,
  y: 0,
  size: 1,
  ratio: 1,
  atlasX: 0,
  atlasY: 0,
};

function packTileLocation(tileLocation: TileLocation, out: Uint8Array, index: number) {
  const offset = index * 4;

  const r =
    (Math.floor(tileLocation.x / TILE_SIZE) % TILES_IN_TEXTURE) +
    (Math.floor(tileLocation.y / TILE_SIZE) % TILES_IN_TEXTURE) * TILES_IN_TEXTURE;

  const g =
    (tileLocation.textureIndex % TEXTURE_SIZE_PACKING) +
    (Math.log2(tileLocation.size) % TEXTURE_SIZE_PACKING) * TEXTURE_SIZE_PACKING;

  const b = Math.log2(tileLocation.ratio) + MIN_ATLAS_POWER;
  const a =
    Math.floor(tileLocation.atlasX * ATLAS_PACKING) +
    Math.floor(tileLocation.atlasY * ATLAS_PACKING) * ATLAS_PACKING;

  out[offset + 0] = r;
  out[offset + 1] = g;
  out[offset + 2] = b;
  out[offset + 3] = a;
}

export class TileMap {
  private data: Array<Array<TileLocation>> = []; // resolution x resolution map of chunks
  private updateQueue: Array<[Vector2, number]> = [];

  readonly texture: DataTexture;

  constructor(readonly resolution: number, private renderer: WebGLRenderer) {
    for (let i = 0; i < resolution; ++i) {
      const row: Array<TileLocation> = [];
      this.data[i] = row;
      for (let j = 0; j < resolution; ++j) row[j] = EMPTY_LOCATION;
    }
    const data = new Uint8Array(4 * resolution * resolution);
    for (let i = 0; i < resolution * resolution; i++) packTileLocation(EMPTY_LOCATION, data, i);
    this.texture = new DataTexture(
      data,
      resolution,
      resolution,
      RGBAFormat,
      UnsignedByteType,
      UVMapping,
      RepeatWrapping,
      RepeatWrapping,
      LinearFilter,
      LinearFilter,
    );
    this.texture.needsUpdate = true;
  }

  // TODO: consider non-square sizes
  reserveSpace(size: number): Vector2 {
    // TODO: replace greedy algorithm with an online backpack packing algorithm
    for (let x = 0; x + size < this.resolution; ++x) {
      for (let y = 0; y + size < this.resolution; ++y) {
        let blocked = false;
        for (let i = 0; i < size; ++i) {
          for (let j = 0; j < size; ++j) {
            blocked = this.data[x + i][y + j] != EMPTY_LOCATION;
            if (blocked) break;
          }
          if (blocked) break;
        }
        if (!blocked) {
          const position = new Vector2(x, y);
          for (let i = 0; i < size; ++i)
            for (let j = 0; j < size; ++j) this.data[x + i][y + j] = FULL_LOCATION;
          return position;
        }
      }
    }
    throw new Error('No space in the tilemap texture!');
  }

  releaseSpace(position: Vector2, size: number) {
    for (let x = position.x; x < size; ++x) {
      for (let y = position.y; y < size; ++y) {
        this.data[x][y] = EMPTY_LOCATION;
      }
    }
  }

  storeTileLocation(position: Vector2, size: number, location: TileLocation) {
    for (let x = position.x; x < position.x + size; ++x) {
      const row = this.data[x];
      for (let y = position.y; y < position.y + size; ++y) {
        row[y] = location;
      }
    }
    this.updateQueue.push([position.clone(), size]);
  }

  update(): boolean {
    if (this.updateQueue.length === 0) return true;
    const min = this.updateQueue[0][0].clone();
    const max = this.updateQueue[0][0].clone();
    const temp = new Vector2();
    this.updateQueue.forEach(([position, size]) => {
      min.min(position);
      max.max(temp.copy(position).addScalar(size - 1));
    });
    this.updateQueue = [];
    this.updateTexture(min, max.sub(min).addScalar(1));
    return false;
  }

  private updateTexture(position: Vector2, size: Vector2) {
    const data = new Uint8Array(4 * size.x * size.y);
    for (let x = 0; x < size.x; ++x) {
      for (let y = 0; y < size.y; ++y) {
        const tileLocation = this.data[x + position.x][y + position.y];
        packTileLocation(tileLocation, data, x + y * size.x);
      }
    }
    const temp = new DataTexture(
      data,
      size.x,
      size.y,
      RGBAFormat,
      UnsignedByteType,
      UVMapping,
      RepeatWrapping,
      RepeatWrapping,
      LinearFilter,
      LinearFilter,
    );
    temp.needsUpdate = true;
    this.renderer.copyTextureToTexture(position, temp, this.texture);
    temp.dispose();
  }

  dispose() {
    this.texture.dispose();
  }

  chunkId(position: Vector2) {
    return position.x + position.y * this.resolution;
  }

  chunkOffset(chunkId: number) {
    return new Vector2(chunkId % this.resolution, Math.floor(chunkId / this.resolution));
  }
}
