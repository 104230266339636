import pipe from 'callbag-pipe';
import subscribe from 'callbag-subscribe';
import {ShoppingCartService} from '../api/services/shopping-cart.service';
import {ShoppingCartItemComponent} from './components/shopping-cart-item_component';
import {WebUtils} from '../utils/web_utils';
import {Metrics} from '../metrics';
import {MATOMO_EVENT_NAMES} from '../metric-events';
import {AppState} from '../shared/app.state';
import {CurrencyService} from '../api/services/currency.service';
import {UrlUtils} from '../api/urls';

export class ShoppingCartItems {
  private itemsContainer: HTMLElement;
  private shoppingCartContainer: HTMLElement;

  constructor(
    scPanelWrapper: HTMLElement,
    private shoppingCartService: ShoppingCartService,
    private currencyService: CurrencyService,
    private redirectToProduct: (productLink: string) => void
  ) {
    this.shoppingCartContainer = scPanelWrapper.querySelector<HTMLElement>('.app-shopping-cart-panel-container');
    this.itemsContainer = scPanelWrapper.querySelector<HTMLElement>('.app-shopping-cart-items-container');
    pipe(
      this.shoppingCartService.checkoutSubject,
      subscribe({
        next: (checkout: any) => {
          if (checkout?.products) this.initProducts(checkout.products);
          else this.clearCart();
        },
        error: () => {
          this.clearCart();
        }
      })
    );
  }

  get processing(): boolean {
    return this.shoppingCartContainer.classList.contains('loading');
  }

  updateShoppingCart(product) {
    if (this.processing) {
      return;
    }
    this.shoppingCartService.updateShoppingCartQuantity(product);
  }

  removeItemFromCart(product) {
    this.shoppingCartService.removeItemFromCart(product);
  }

  navigateToProduct(product) {
    const productTitle = UrlUtils.slugify(product.title);

    if (product.sphere_name && product.product_id) {
      const productLink = `sphere#${product.sphere_name}#${product.product_id}`;
      this.redirectToProduct(productLink);

      Metrics.storeTheEvent(
        AppState.planogramName,
        'click',
        MATOMO_EVENT_NAMES.CLICK_SHOPPING_CART_OVERLAY_PRODUCT_TITLE(product.product_id, productTitle)
      );
    } else if (product.store_url) {
      WebUtils.openLink(product.store_url);
      Metrics.storeTheEvent(
        AppState.planogramName,
        'click',
        MATOMO_EVENT_NAMES.CLICK_SHOPPING_CART_OVERLAY_PRODUCT_TITLE(product.product_id, productTitle)
      );
    }
  }

  private initProducts(products) {
    this.clearCart();
    if (!products?.length) {
      return;
    }
    const resultItems = document.createDocumentFragment();

    products.forEach(product => {
      const shoppingCartItem = new ShoppingCartItemComponent(
        this,
        product,
        this.currencyService,
        this.shoppingCartService
      );
      const shoppingCartItemElem = shoppingCartItem.renderItem();
      resultItems.appendChild(shoppingCartItemElem);
    });

    this.itemsContainer.appendChild(resultItems);
  }

  private clearCart() {
    this.itemsContainer.innerHTML = '';
  }
}
