import {debounce} from 'lodash';
import {checkoutView} from './checkout-view';
import {Product} from '../../interfaces/product.interface';
import {CheckoutResult} from '../../interfaces/checkout.interface';
import {InputEventUtils} from '../../utils/input_event_utils';
import {CheckoutService} from '../../api/services/checkout.service';
import {CDN_RESOURCES, UrlUtils} from '../../api/urls';
import {WebUtils} from '../../utils/web_utils';
import {Metrics} from '../../metrics';
import {SphereItem} from '../../sphere_item';
import {MATOMO_EVENT_NAMES} from '../../metric-events';
import Router from '../../router';
import {ProductInfoService} from '../../api/services/product_info.service';
import {PAGE_LOADING_TYPES, PRODUCT_PAGE_ROUTES} from '../../shared/constants';
import {PageLoadingSpeed} from '../../page_loading_speed';
import {L10nUtils} from '../../utils/l10n_utils';
import {AppUtils} from '../../utils/app_utils';

const ASSETS_PREFIX = !!REQUEST_PREFIX ? REQUEST_PREFIX : '';

enum PAGE_NAVIGATION_DIRECTION {
  NEXT = 'next',
  PREV = 'prev'
}

export class CheckoutOverlay {
  private readonly item: SphereItem;
  private readonly slugifyProductName: string;
  private product: Product;
  private readonly checkoutService: CheckoutService;
  private readonly productInfoOverlayType: PRODUCT_PAGE_ROUTES;

  private isClosedOverlay = false;
  private changeURLAfterClosingOverlay = true;

  private rakutenResult: CheckoutResult;
  private ebayResults: CheckoutResult[];

  private isEbayPageAnimating = false;
  private currentEbayPage = 1;
  private ebayPageCapacity = 1;
  private ebayResultsPerPage: Array<CheckoutResult[]> = [];

  private isSectionExpanded = false;
  private sizeModifier: string;

  private ebayContainerObserver: ResizeObserver;

  constructor(
    private container: HTMLElement,
    options: any,
    private productInfoService: ProductInfoService,
    private closeCallback: Function
  ) {
    this.item = options.item;
    this.productInfoOverlayType = options.productInfoOverlayType;
    this.product = options.product;
    this.slugifyProductName = UrlUtils.slugify(this.item.name || '');
    PageLoadingSpeed.startMeasure(PAGE_LOADING_TYPES.CHECKOUT);

    this.checkoutService = new CheckoutService();
    this.setLoaderVisibility(true);

    this.productInfoService
      .getView(this.item.identifier)
      .then(product => {
        this.product = product;

        this.initOverlay();

        this.checkoutService
          .getCheckoutInfo(this.product.identifier)
          .then(data => {
            PageLoadingSpeed.completeMeasure(PAGE_LOADING_TYPES.CHECKOUT);
            this.rakutenResult = data.rakuten.products[0];
            this.ebayResults = data.ebay.products;

            this.showAllResults();
          })
          .catch(err => {
            this.closeOverlay();
            console.error('Something happen with Checkout data initialization');
          });
      })
      .catch(err => console.error(`Something went wrong while requesting product with id - ${this.item.identifier}`));
  }

  private initOverlay() {
    this.setContent();
    this.initOverlayElements();
    this.initEbaySectionElements();
    this.handleEbayContainerSizeChanges();
    this.handleProductClick();
  }

  handleClick(e) {
    return false;
  }

  // Is for parent Overlay class; Do not rename this method
  closeOverlay(e?: Event) {
    if (this.isClosedOverlay) {
      return;
    }
    this.isClosedOverlay = true;
    this.ebayContainerObserver.disconnect();
    ProductInfoService.clearProductInfoCache();

    if (e) {
      e.stopPropagation();
    }
    if (this.closeCallback) {
      this.closeCallback(this.changeURLAfterClosingOverlay);
    } else {
      console.error('"Close" function is not set up');
    }
  }

  private setContent() {
    this.container.innerHTML = checkoutView(this.product);
  }

  private setLoaderVisibility(isVisible: boolean) {
    const loaderContainer = this.container.querySelector<HTMLElement>('.checkout-loader-container');
    const contentContainer = this.container.querySelector<HTMLElement>('.app-product-content-wrapper > .checkout-grid');

    if (!loaderContainer || !contentContainer) {
      return;
    }

    if (isVisible) {
      loaderContainer.classList.remove('is-hidden');
      contentContainer.classList.add('is-hidden');
    } else {
      loaderContainer.classList.add('is-hidden');
      contentContainer.classList.remove('is-hidden');
    }
  }

  private initOverlayElements() {
    const backButton = this.container.querySelector<HTMLElement>('.overlay-button-back');
    const closeButton = this.container.querySelector<HTMLElement>('.overlay-button-close');

    if (backButton) {
      InputEventUtils.addSelectEvents(backButton, e => {
        e.preventDefault();
        e.stopPropagation();

        this.triggerMatomoClickEvent(MATOMO_EVENT_NAMES.CLICK_CHECKOUT_BACK_BUTTON);

        if (this.isSectionExpanded) {
          this.changeEbaySectionState(false);
        } else {
          this.changeURLAfterClosingOverlay = false;
          this.closeOverlay();
          Router.navigateToProduct(this.item, this.productInfoOverlayType);
        }
      });
    }

    if (closeButton) {
      InputEventUtils.addSelectEvents(closeButton, e => {
        e.preventDefault();
        e.stopPropagation();

        this.triggerMatomoClickEvent(MATOMO_EVENT_NAMES.CLICK_CHECKOUT_CLOSE_BUTTON);
        this.closeOverlay();
      });
    }
  }

  // TODO: Move all ebay and future store providers logic to separate class
  private changeEbayPage(direction: PAGE_NAVIGATION_DIRECTION) {
    if (this.isEbayPageAnimating || this.ebayResultsPerPage.length === 1) {
      return;
    }

    const ebayGridContainer = this.container.querySelector<HTMLElement>('#ebay-store .inner-grid');
    const animationNames = ['fade-in-left', 'fade-in-right', 'fade-out-left', 'fade-out-right'];
    const outAnimationName = `fade-out-${direction === PAGE_NAVIGATION_DIRECTION.NEXT ? 'left' : 'right'}`;
    const inAnimationName = `fade-in-${direction === PAGE_NAVIGATION_DIRECTION.NEXT ? 'right' : 'left'}`;

    if (direction === PAGE_NAVIGATION_DIRECTION.PREV) {
      if (--this.currentEbayPage <= 0) {
        this.currentEbayPage = this.ebayResultsPerPage.length;
      }
    } else {
      if (++this.currentEbayPage > this.ebayResultsPerPage.length) {
        this.currentEbayPage = 1;
      }
    }

    this.isEbayPageAnimating = true;
    ebayGridContainer.classList.remove(...animationNames);
    ebayGridContainer.classList.add(outAnimationName);
    ebayGridContainer.addEventListener(
      'animationend',
      () => {
        this.partialUpdateEbaySection();
        ebayGridContainer.classList.remove(outAnimationName);
        ebayGridContainer.classList.add(inAnimationName);
        ebayGridContainer.addEventListener(
          'animationend',
          () => {
            this.isEbayPageAnimating = false;
            ebayGridContainer.classList.remove(inAnimationName);
          },
          {once: true}
        );
      },
      {once: true}
    );
  }

  private initEbaySectionElements() {
    const ebayExpandButton = this.container.querySelector<HTMLElement>('#ebay-store .btn-expand');

    if (ebayExpandButton) {
      InputEventUtils.addSelectEvents(ebayExpandButton, e => {
        e.preventDefault();
        e.stopPropagation();

        this.triggerMatomoClickEvent(MATOMO_EVENT_NAMES.CLICK_CHECKOUT_EXPAND_BUTTON);
        this.changeEbaySectionState(true);
      });
    }

    const prevSliderButton = this.container.querySelector<HTMLElement>('.slider-controls.prev');
    const nextSliderButton = this.container.querySelector<HTMLElement>('.slider-controls.next');

    if (prevSliderButton && nextSliderButton) {
      InputEventUtils.addSelectEvents(prevSliderButton, e => {
        e.preventDefault();
        e.stopPropagation();
        this.triggerMatomoClickEvent(MATOMO_EVENT_NAMES.CLICK_CHECKOUT_PAGINATION_ARROW);
        this.changeEbayPage(PAGE_NAVIGATION_DIRECTION.PREV);
      });

      InputEventUtils.addSelectEvents(nextSliderButton, e => {
        e.preventDefault();
        e.stopPropagation();
        this.triggerMatomoClickEvent(MATOMO_EVENT_NAMES.CLICK_CHECKOUT_PAGINATION_ARROW);
        this.changeEbayPage(PAGE_NAVIGATION_DIRECTION.NEXT);
      });
    }

    const ebayGridContainer = this.container.querySelector<HTMLElement>('#ebay-store .inner-grid');

    if (ebayGridContainer) {
      InputEventUtils.addSwipeEvent(ebayGridContainer as HTMLElement, swipe => {
        swipe.event.preventDefault();
        swipe.event.stopPropagation();

        if (swipe.direction === 'left') {
          this.changeEbayPage(PAGE_NAVIGATION_DIRECTION.NEXT);
        } else if (swipe.direction === 'right') {
          this.changeEbayPage(PAGE_NAVIGATION_DIRECTION.PREV);
        }
      });
    }
  }

  private changeEbaySectionState(isExpanded: boolean) {
    this.isSectionExpanded = isExpanded;

    const mainContainer = this.container.querySelector<HTMLElement>('.app-product-content-wrapper > .checkout-grid');
    const rakutenStoreContainer = document.getElementById('rakuten-store');
    const ebayStorePagerElement = document.getElementById('ebay-store-pager');
    const ebayStoreButtonElement = this.container.querySelector<HTMLElement>('#ebay-store .btn-expand');

    if (!mainContainer || !rakutenStoreContainer) {
      return;
    }

    if (this.isSectionExpanded) {
      ebayStorePagerElement.classList.remove('is-hidden');
      ebayStoreButtonElement.classList.add('is-hidden');
      rakutenStoreContainer.classList.add('is-hidden');
      mainContainer.classList.add('full-width');
    } else {
      ebayStorePagerElement.classList.add('is-hidden');
      ebayStoreButtonElement.classList.remove('is-hidden');
      rakutenStoreContainer.classList.remove('is-hidden');
      mainContainer.classList.remove('full-width');
    }

    // TODO simplify
    // needs to make full rerender
    this.currentEbayPage = 1;

    this.showEbayResults();
  }

  private handleEbayContainerSizeChanges() {
    const element = document.querySelector<HTMLElement>('#ebay-store .checkout-grid.inner-grid');

    this.ebayContainerObserver = new ResizeObserver(
      debounce(() => {
        this.updateEbaySection();
      }, 150)
    );

    if (!element) {
      return;
    }

    this.ebayContainerObserver.observe(element);
  }

  private handleProductClick() {
    const mainGridContainer = this.container.querySelector<HTMLElement>('.app-product-content-wrapper');

    if (!mainGridContainer) {
      console.error('Main grid container is not found');
    }

    InputEventUtils.addSelectEvents(mainGridContainer, e => {
      let productNode = e.target as HTMLElement;
      if (!productNode.classList.contains('product')) {
        productNode = productNode.closest('.product');
      }
      if (!productNode) {
        return;
      }
      e.preventDefault();
      e.stopPropagation();

      const url = productNode.getAttribute('data-url');
      const storeName = productNode.getAttribute('data-store-name');
      const isFallback = parseInt(productNode.getAttribute('data-is-fallback'), 10);

      if (storeName === 'ebay') {
        this.triggerMatomoClickEvent(MATOMO_EVENT_NAMES.CLICK_CHECKOUT_EBAY);
      } else if (storeName === 'rakuten') {
        if (isFallback === 1) {
          this.triggerMatomoClickEvent(MATOMO_EVENT_NAMES.CLICK_CHECKOUT_RAKUTEN_BROWSE);
        } else if (isFallback === 0) {
          this.triggerMatomoClickEvent(MATOMO_EVENT_NAMES.CLICK_CHECKOUT_RAKUTEN_BUY);
        }
      }

      const {identifier, name, price_in_user_currency_str, category_name} = this.product;
      if (price_in_user_currency_str) {
        Metrics.storeEcommerceEvent({
          sku: identifier,
          productName: UrlUtils.slugify(name),
          category: category_name,
          productPrice: AppUtils.getPriceValueFromStr(price_in_user_currency_str)
        });
      }
      WebUtils.openLink(url);
    });
  }

  private showAllResults() {
    const mainContainer = document.querySelector<HTMLElement>('.app-product-content-wrapper > .checkout-grid');

    this.sizeModifier = this.getSizeModifier(this.ebayResults.length);

    if (this.sizeModifier) {
      mainContainer.classList.add(this.sizeModifier);
    }

    this.setLoaderVisibility(false);
    this.showRakutenResults();
    this.showEbayResults();
  }

  private showRakutenResults() {
    const rakutenStoreContainer = document.getElementById('rakuten-store');

    this.renderProducts(rakutenStoreContainer, 'rakuten', [this.rakutenResult]);
  }

  private showEbayResults(pageSize?: number) {
    const ebayStoreContainer = document.getElementById('ebay-store');
    const ebayStoreBarContainer = ebayStoreContainer.querySelector<HTMLElement>('.store-bar');
    const ebayStoreGridContainer = ebayStoreContainer.querySelector<HTMLElement>('.checkout-grid');
    const ebayStorePageControls = ebayStoreContainer.querySelectorAll('.slider-controls');

    if (!this.ebayResults.length) {
      ebayStoreContainer.classList.add('is-completely-hidden');
      return;
    }
    if (this.ebayResults.length > 1) {
      ebayStoreContainer.classList.add('multiple');
    }

    // Show counter of results
    const ebayStoreBarAmountElement = ebayStoreBarContainer.querySelector<HTMLElement>('.amount');
    ebayStoreBarAmountElement.innerHTML = this.ebayResults.length.toString();

    // Filter and show results
    let items = [...this.ebayResults];

    if (pageSize && pageSize < items.length) {
      // TODO handle edge cases more precisely
      try {
        items = [...this.ebayResultsPerPage[this.currentEbayPage - 1]];
      } catch (err) {
        this.currentEbayPage = 1;
        items = [...this.ebayResultsPerPage[this.currentEbayPage - 1]];
      }
      ebayStorePageControls.forEach(el => el.classList.remove('is-hidden'));
    } else {
      ebayStorePageControls.forEach(el => el.classList.add('is-hidden'));
    }

    // Remove all rendered products
    const productsList = ebayStoreGridContainer.querySelectorAll('.product');
    productsList.forEach(el => el.remove());

    // Render new pack of products
    this.renderProducts(ebayStoreGridContainer, 'ebay', items);

    if (this.ebayResults.length === 1) {
      ebayStoreGridContainer.outerHTML = ebayStoreGridContainer.querySelector<HTMLElement>('.product').outerHTML;
      ebayStoreBarContainer.outerHTML = `
        <div class="store-logo">
          <img src="${ASSETS_PREFIX}/assets/img/icons/logo-ebay.svg" alt="">
        </div>
      `;
    }
  }

  private renderProducts(container: Element, storeName: string, items: Array<CheckoutResult>) {
    items.forEach(item => {
      const element = document.createElement('div');
      element.classList.add('product');
      element.setAttribute('data-url', item.url);
      element.setAttribute('data-store-name', storeName);
      element.setAttribute('data-is-fallback', (item.is_fallback ? 1 : 0).toString());
      element.innerHTML = this.createResultElement(item, this.sizeModifier);
      container.appendChild(element);
    });
  }

  private updateEbaySection() {
    if (this.ebayResults.length <= 1) {
      return;
    }
    this.ebayPageCapacity = this.recalculateEbayPageCapacity();

    this.updateEbayPagesArray(this.ebayPageCapacity);
    this.showEbayResults(this.ebayPageCapacity);
    this.updateEbayPageCounter();
  }

  // only rerender Ebay section results
  private partialUpdateEbaySection() {
    this.showEbayResults(this.ebayPageCapacity);
    this.updateEbayPageCounter();
  }

  private updateEbayPagesArray(pageSize) {
    const clonedArray = [...this.ebayResults];
    this.ebayResultsPerPage = [];

    while (clonedArray.length > pageSize) {
      this.ebayResultsPerPage.push(clonedArray.splice(0, pageSize));
    }
    if (clonedArray.length > 0) {
      this.ebayResultsPerPage.push([...clonedArray]);
    }
  }

  private updateEbayPageCounter() {
    const ebayStorePagerElement = document.getElementById('ebay-store-pager');

    if (!ebayStorePagerElement) {
      return;
    }

    ebayStorePagerElement.innerText = `${this.currentEbayPage}/${this.ebayResultsPerPage.length}`;
  }

  private recalculateEbayPageCapacity(): number {
    try {
      const ebayStoreContainer = document.getElementById('ebay-store');
      const gridContainer = ebayStoreContainer.querySelector<HTMLElement>('.checkout-grid');
      const productItem = ebayStoreContainer.querySelector<HTMLElement>('.product');

      const containerBoundingRect = gridContainer.getBoundingClientRect();
      const productBoundingRect = productItem.getBoundingClientRect();
      const productComputedStyles = window.getComputedStyle(productItem);
      const horizontalItemMargin = parseFloat(productComputedStyles.marginLeft) * 2;
      const bottomItemMargin = parseFloat(productComputedStyles.marginBottom);
      const topContainerPadding = parseFloat(window.getComputedStyle(gridContainer).paddingTop);

      const containerTotalHeight = containerBoundingRect.height - topContainerPadding;
      const productTotalHeight = productBoundingRect.height + bottomItemMargin;

      const rows = Math.floor(containerTotalHeight / productTotalHeight);
      const columns = Math.floor(containerBoundingRect.width / (productBoundingRect.width + horizontalItemMargin));

      return Math.max(rows * columns, 1);
    } catch (err) {
      console.error(err);
    }
  }

  private createResultElement(item: CheckoutResult, sizeModifier: string) {
    let resultElement = '';

    // Add image
    let imageUrl = item.image_url;
    if (!imageUrl && item.picture) {
      const pickedImage = item.picture.variants.VARIANT_MEDIUM_WEBP;
      imageUrl = UrlUtils.insertValueToUrl(CDN_RESOURCES.PRODUCT_IMAGE, pickedImage);
    }
    resultElement += `
     <div class="photo ${sizeModifier} ${item.is_fallback ? 'disabled' : ''}">
        <img src="${imageUrl}" alt="">
      </div>
    `;

    // Add price
    if (item.price) {
      resultElement += `
       <strong class="price ${item.is_fallback ? 'is-hidden' : ''}">${item.price.currency + item.price.value}</strong>
    `;
    }

    // Add shipping
    let shippingText = L10nUtils.l10n('checkout-overlay.item.plus-postage');
    if (item.shipping) {
      shippingText = `+${item.shipping.currency + item.shipping.value}`;
    }
    resultElement += `
      <span class="delivery ${item.is_fallback ? 'is-hidden' : ''}">${shippingText}</span>
    `;

    // Is fallback
    if (item.is_fallback) {
      resultElement += `
       <span class="notification">${L10nUtils.l10n('checkout-overlay.item.lego-fallback')}</span>
    `;
    }
    return resultElement;
  }

  private getSizeModifier(count: number): string {
    switch (count) {
      case 0:
        return '';
      case 1:
        return 'xl';
      case 2:
        return 'l';
      case 3:
        return 'm';
      case 4:
        return 's';
      case 5:
        return 'xs';
      default:
        return 'xxs';
    }
  }

  private triggerMatomoClickEvent(eventName: string) {
    Metrics.storeTheEvent(
      this.item.planogram.name,
      'click',
      `${eventName}-${this.item.identifier}-${this.slugifyProductName}`
    );
  }
}
