import {layoutSwitcherView} from './layout-switcher.view';
import {InputEventUtils} from '../../utils/input_event_utils';
import {OverlayScrollbar} from '../overlay_scrollbar';
import {sphereEventHandler} from '../../custom_event_utils';
import {SPHERE_EVENT_NAMES as EVENTS} from '../../event-names';

export class LayoutSwitcher {
  private readonly container: HTMLElement;
  private readonly itemsContainer: HTMLElement;
  private readonly layoutSwitcherContainer: HTMLElement;
  private readonly switcher: HTMLElement;
  private readonly search: HTMLInputElement;
  private readonly handleSelect: Function;
  private selectedItem;
  private items: NodeListOf<HTMLElement>;
  private itemsList;
  private layoutType: string;
  static readonly isOpenedClass = 'is-opened';
  static readonly isActiveClass = 'is-active';
  static isLayoutActive: boolean;

  private get ANIMATION_DELAY() {
    return 100;
  }

  private get isListOpened() {
    return this.switcher.classList.contains(LayoutSwitcher.isOpenedClass);
  }

  constructor({container, children, type, handleSelect}) {
    this.handleResize = this.handleResize.bind(this);
    this.container = container;
    this.handleSelect = handleSelect;
    this.itemsList = children;
    this.layoutType = type;

    this.container.innerHTML = layoutSwitcherView({type});
    this.itemsContainer = this.container.querySelector<HTMLElement>('.layout-items_wrapper');
    this.layoutSwitcherContainer = this.container.querySelector<HTMLElement>('.layout-switcher_container');
    this.switcher = this.container.firstElementChild as HTMLElement;
    this.search = this.container.querySelector<HTMLInputElement>('.layout-switcher_search input');
    this.renderItems(children);

    this.registerClickEventHandler();
    this.registerInputEventHandler();
    this.setActiveState(children[0]?.id);

    sphereEventHandler.listen(EVENTS.CONTROL.CLICK, () => {
      this.search?.blur();
    });

    window.addEventListener('resize', this.handleResize);
    this.search?.addEventListener('click', () => {
      this.renderItems(this.itemsList);
      this.setOpeningStatus(!this.isListOpened);
      if (!LayoutSwitcher.isLayoutActive) {
        this.search?.blur();
      } else {
        this.search.placeholder = '';
      }
    });
    this.search?.addEventListener('blur', this.handleSearchBlur.bind(this));
    const renderItemsCallback = () => {
      renderItemsObserver.disconnect();
      return new OverlayScrollbar('.scroll-wrapper', {autoHide: 'never'});
    };
    const renderItemsObserver = new MutationObserver(renderItemsCallback);
    renderItemsObserver.observe(this.itemsContainer, {childList: true});
  }

  private registerClickEventHandler() {
    InputEventUtils.addSelectEvents(this.itemsContainer, this.handleClick.bind(this));
  }

  private renderItems(items) {
    this.clearList();
    const resultItems = document.createDocumentFragment();

    items.forEach(item => {
      const itemContainer = document.createElement('div');
      const itemContent = document.createElement('div');
      itemContainer.setAttribute('data-item-id', item.id);
      itemContainer.classList.add('layout-switcher_item');
      itemContent.classList.add('layout-switcher_item_content');
      itemContent.innerHTML = `${item.symbol ?? ''} ${item.element}`;
      itemContainer.appendChild(itemContent);
      resultItems.appendChild(itemContainer);
    });

    this.itemsContainer.appendChild(resultItems);
    this.items = this.container.querySelectorAll('.layout-switcher_item');
    setTimeout(() => {
      if (LayoutSwitcher.isLayoutActive) {
        this.setSwitcherHeight();
      }
    }, this.ANIMATION_DELAY);
  }

  private clearList() {
    this.itemsContainer.innerHTML = '';
  }

  private setSearchPlaceholder() {
    if (!this.search) {
      return;
    }

    this.search.placeholder = `${this.selectedItem.symbol ?? ''} ${this.selectedItem.id}`;
  }

  private handleSearchBlur() {
    this.search.value = '';
    this.setSearchPlaceholder();
    this.handleResize();
  }

  private registerInputEventHandler() {
    this.search?.addEventListener('input', (e: Event) => {
      const query = (e.target as HTMLInputElement).value?.toUpperCase();

      const searchedCurrencies = this.itemsList.filter(currency => currency.id.includes(query));
      this.renderItems(searchedCurrencies);
    });
  }

  private handleResize() {
    setTimeout(() => {
      this.switcher.classList.remove(LayoutSwitcher.isOpenedClass);
      if (this.layoutType === 'text') {
        this.clearList();
      }
    }, this.ANIMATION_DELAY);
    this.clearSwitcherHeight();
    LayoutSwitcher.isLayoutActive = false;
  }

  private setOpeningStatus(data: boolean): void {
    if (data) {
      this.switcher.classList.add(LayoutSwitcher.isOpenedClass);
      this.setSwitcherHeight();
      LayoutSwitcher.isLayoutActive = true;
    } else {
      this.handleResize();
    }
  }

  private async handleClick(e) {
    const item = e.target.closest('.layout-switcher_item');

    if (!this.isListOpened) {
      this.setOpeningStatus(true);

      return;
    }

    if (!item.classList.contains(LayoutSwitcher.isActiveClass)) {
      const {itemId} = item.dataset;
      await this.handleSelect(itemId);
      this.setActiveState(itemId);
    }

    this.setOpeningStatus(false);
  }

  private setSwitcherHeight() {
    this.switcher.style.height = this.layoutSwitcherContainer.offsetHeight + 'px';
  }

  private clearSwitcherHeight() {
    this.switcher.style.height = '';
  }

  setActiveState(itemId: string) {
    const itemEl = this.container.querySelector<HTMLElement>(`.layout-switcher_item[data-item-id=${itemId}]`);
    this.selectedItem = this.itemsList.find(item => item.id === itemId);
    if (itemEl) {
      this.items.forEach(item => item.classList.remove(LayoutSwitcher.isActiveClass));
      itemEl.classList.add(LayoutSwitcher.isActiveClass);
      this.setSearchPlaceholder();
    }
  }

  dispose() {
    window.removeEventListener('resize', this.handleResize);
  }
}
