import {isContentOverlayAction} from 'shared/interfaces/planogram';
import {SphereItem} from '../sphere_item';
import {FullScreenButton} from '../components/overlay-fullscreen-mode';
import {Overlay, OverlayOptions} from '../overlay';

export class ContentOverlay {
  container: HTMLElement;
  private fullscreenButton: FullScreenButton;

  constructor(
    container: HTMLElement,
    private url: string,
    private item: SphereItem,
    private options: OverlayOptions,
    private closeCallback: Function
  ) {
    this.container = container;
    container.innerHTML = '';
    const root = document.createElement('div');
    root.classList.add(
      'iframe-overlay',
      'content-overlay',
      'overlay-container',
      'is-flex',
      'is-vertical',
      'is-vertically-aligned-center',
      'is-horizontally-aligned-center'
    );
    this.container.appendChild(root);

    const closeButton = document.createElement('div');
    closeButton.classList.add('overlay-button', 'overlay-button-close', 'will-close');
    closeButton.appendChild(document.createElement('span'));
    closeButton.addEventListener('click', this.closeOverlay.bind(this));
    root.appendChild(closeButton);

    const holder = document.createElement('div');
    holder.classList.add('iframe-holder');
    holder.style.setProperty('background-color', 'transparent');
    root.appendChild(holder);

    this.fetchType(url).then(type => {
      const isImage = type.match(/^image\/.*$/) !== null;
      const contentWrapper = document.createElement('div');
      const content = document.createElement(isImage ? 'img' : 'iframe');
      contentWrapper.classList.add('content-wrapper');
      const fullscreenButtonWrapper = document.createElement('div');
      fullscreenButtonWrapper.classList.add('iframe-fullscreen-button');
      if (isImage) {
        holder.style.setProperty('background-color', 'transparent');
        root.classList.add('is-image');
      } else {
        holder.style.removeProperty('background-color');
      }
      content.onload = e => {
        if (isImage) {
          const target = e.target as HTMLImageElement;
          holder.style.setProperty('aspect-ratio', `${target.naturalWidth} / ${target.naturalHeight}`);
        }
      };
      content.src = this.url;
      content.style.objectFit = 'contain';
      content.style.width = '100%';
      content.style.height = '100%';
      contentWrapper.appendChild(content);
      root.appendChild(fullscreenButtonWrapper);
      holder.appendChild(contentWrapper);

      if (this.options?.fullscreen) {
        const fullscreenButtonContainer = this.container.querySelector<HTMLElement>('.iframe-fullscreen-button');
        this.fullscreenButton = new FullScreenButton(fullscreenButtonContainer);
      }

      if (this.options?.title && isContentOverlayAction(this.item.action)) {
        root.classList.add('with-title');
        Overlay.showSeoTitle(root, this.item.action.data.iframeLink);
      }
    });
  }

  private fetchType(url: string) {
    return fetch(url, {method: 'HEAD'}).then(r => r.headers.get('Content-Type'));
  }

  private closeOverlay(e?: Event): void {
    if (e) {
      e.stopPropagation();
    }
    if (this.closeCallback) {
      this.closeCallback();
    } else {
      console.error('"Close" function is not set up');
    }
  }

  handleClick(e) {
    return e.target.classList.contains('iframe-overlay');
  }
}
