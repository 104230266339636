import {ApiService} from '../api';
import {URLS, UrlUtils} from '../urls';
import {L10nUtils} from '../../utils/l10n_utils';
import {CUSTOM_HEADER_KEY} from '../../shared/constants';
import {PlanogramVersionResponse, PlanogramData, PlanogramVersion} from '../../interfaces/planogram.interface';

function saveCustomHeader(response: Response) {
  const customHeaderValue = response.headers.get(CUSTOM_HEADER_KEY);
  window.sessionStorage.setItem(CUSTOM_HEADER_KEY, customHeaderValue);
  return response;
}

export class PlanogramsService extends ApiService {
  getPlanogram(planogramName: string): Promise<PlanogramVersionResponse> {
    if (!planogramName) {
      return Promise.reject(new Error('Missing data for planogram URL'));
    }
    const lang = L10nUtils.getCurrentLanguage();
    const url = UrlUtils.insertFewValuesToUrl(URLS.PLANOGRAM, {planogramName, lang});
    return this.get(url)
      .then(saveCustomHeader)
      .then(resp => resp.json());
  }

  getActionJson(url: string): Promise<PlanogramData> {
    return this.get(url)
      .then(saveCustomHeader)
      .then(resp => resp.json());
  }

  getPlanogramFromCdn(url: string): Promise<PlanogramVersion> {
    return this.getWithoutToken(url)
      .then(resp => resp.json());
  }
}
