import {InputEventUtils} from '../utils/input_event_utils';
import {SphereItem} from '../sphere_item';
import {InputHandler} from '../input_handler';
import {JourneyOverlayNavigationBar} from './journey-overlay-navigation-bar';
import {SphereApp} from '../sphere_app';
import {overlayEventsHandler} from '../custom_event_utils';
import {SPHERE_EVENT_NAMES} from '../event-names';
import {NAVIGATION_ARROW_TYPES} from '../interfaces/planogram.interface';
import {ActionType} from 'shared/interfaces/planogram';

const AUTOPLAY_INTERVAL = 5000;

export class JourneyOverlay {
  private navBar: JourneyOverlayNavigationBar;
  private intervalAutoplayId: any;

  constructor(
    private readonly container: HTMLElement,
    private items: SphereItem[],
    private inputHandler: InputHandler,
    private animationTime = 500,
    private closeCallback: Function,
    private autoplay: boolean
  ) {
    this.container = container;
    const root = document.documentElement;
    root.style.setProperty('--journey-animation-time', `${this.animationTime}ms`);
    this.pauseAutoPlay = this.pauseAutoPlay.bind(this);
    this.toggleFullscreen = this.toggleFullscreen.bind(this);

    this.container.innerHTML = `
      <div class="journey-overlay overlay-container is-flex is-vertical is-vertically-aligned-center is-horizontally-aligned-center">
        <div class="journey-overlay-backdrop"></div>
        <div class="journey-overlay-wrapper">
          <div class="overlay-button overlay-button-close will-close">
            <span></span>
          </div>
          <div class="journey-overlay-transition-wrapper">
            <div class="journey-overlay-inner-content"></div>
          </div>
        </div>
      </div>
    `;

    const closeButton = this.container.querySelector<HTMLElement>('.journey-overlay .overlay-button-close');
    this.container.classList.remove('is-hidden');
    this.initInnerOverlays();

    if (closeButton) {
      InputEventUtils.addSelectEvents(closeButton, this.closeOverlay.bind(this));
    } else {
      console.error('element not found - ".overlay-button-close"');
    }

    this.handleAutoplay();
    this.navBar = new JourneyOverlayNavigationBar({
      autoplay: this.autoplay,
      container: this.container,
      onPrev: () => this.handleNavigation(NAVIGATION_ARROW_TYPES.LEFT, true),
      onToggleAutoPlay: () => this.handleToggleAutoplay(),
      onNext: () => this.handleNavigation(NAVIGATION_ARROW_TYPES.RIGHT, true),
      onExit: () => this.closeOverlay()
    });

    overlayEventsHandler.listen(
      [SPHERE_EVENT_NAMES.OVERLAY.OVERLAY_USER_ACTION, SPHERE_EVENT_NAMES.OVERLAY.OVERLAY_USER_SCROLL].join(' '),
      this.pauseAutoPlay
    );

    overlayEventsHandler.listen(SPHERE_EVENT_NAMES.OVERLAY.FULLSCREEN, this.toggleFullscreen);
  }

  private initInnerOverlays() {
    const internalContainer = this.container.querySelector<HTMLElement>('.journey-overlay-inner-content');
    this.items.forEach((item, index) => {
      const overlayContainer = document.createElement('div');
      const overlayContent = document.createElement('div');
      const {prevIndex, nextIndex} = this.getNearestItems();
      overlayContent.classList.add('journey-overlay-inner-item-content');
      overlayContainer.classList.add('journey-overlay-inner-item');
      overlayContainer.style.setProperty('--index', `${index}`);

      const autoplayVideo = index === this.inputHandler.currentAnimationIndex && this.autoplay;
      this.inputHandler.overlay.showItem(
        item,
        undefined,
        overlayContent,
        autoplayVideo,
        {
          title: this.inputHandler.animationPath.items[index].showSeoTitle,
          fullscreen: item.action?.type !== ActionType.VideoOverlay
        }
      );
      if (index === this.inputHandler.currentAnimationIndex) {
        overlayContainer.classList.add('active');
      } else if (index === prevIndex) {
        overlayContainer.classList.add('prev');
      } else if (index === nextIndex) {
        overlayContainer.classList.add('next');
      }
      overlayContainer.append(overlayContent);
      internalContainer.append(overlayContainer);
    });
  }

  private toggleFullscreen() {
    this.container.classList.toggle('fullscreen-mode');
  }

  private handleAutoplay() {
    if (this.autoplay)
      this.intervalAutoplayId = setInterval(() => {
        this.handleNavigation(NAVIGATION_ARROW_TYPES.RIGHT);
      }, AUTOPLAY_INTERVAL);
    else clearInterval(this.intervalAutoplayId);
  }

  private handleToggleAutoplay() {
    this.autoplay = !this.autoplay;
    this.navBar.autoplay = this.autoplay;
    this.handleAutoplay();
  }

  pauseAutoPlay() {
    this.autoplay = true;
    this.handleToggleAutoplay();
    overlayEventsHandler.emit(SPHERE_EVENT_NAMES.OVERLAY.INTERACTION);
  }

  private handleNavigation(arrowType: NAVIGATION_ARROW_TYPES, manualClick?: boolean) {
    const isLastItem = this.inputHandler.currentAnimationIndex === this.items.length - 1;
    if (this.autoplay && isLastItem && !this.inputHandler.animationPath.loop) this.pauseAutoPlay();
    const reverse = arrowType === NAVIGATION_ARROW_TYPES.LEFT;
    const transitionContainer = this.container.querySelector<HTMLElement>('.journey-overlay-inner-content');
    this.inputHandler.handleNextAnimation(reverse);
    const itemsContainers = this.container.querySelectorAll<HTMLElement>('.journey-overlay-inner-item');
    itemsContainers.forEach(item => item.classList.remove('active', 'prev', 'next'));
    const {prevIndex, nextIndex} = this.getNearestItems();
    if (prevIndex !== this.inputHandler.currentAnimationIndex) {
      itemsContainers[prevIndex]?.classList.add('prev');
    }
    itemsContainers[this.inputHandler.currentAnimationIndex].classList.add('active');
    if (nextIndex !== this.inputHandler.currentAnimationIndex) {
      itemsContainers[nextIndex].classList.add('next');
    }
    transitionContainer.style.setProperty('--dir', reverse ? '-1' : '1');
    if (manualClick) {
      this.pauseAutoPlay();
    }

    const currentActiveElement = itemsContainers[this.inputHandler.currentAnimationIndex]?.querySelector(
      'video, iframe'
    ) as HTMLVideoElement | HTMLIFrameElement | null;
    const prevActiveElement = itemsContainers[reverse ? nextIndex : prevIndex]?.querySelector('video, iframe') as
      | HTMLVideoElement
      | HTMLIFrameElement
      | null;
    if (currentActiveElement) this.handleMediaElement(currentActiveElement, 'play');
    if (prevActiveElement) this.handleMediaElement(prevActiveElement, 'pause');
  }

  private handleMediaElement(element: HTMLVideoElement | HTMLIFrameElement | null, action: 'play' | 'pause') {
    if (!element) return;

    if (element instanceof HTMLVideoElement) action === 'play' ? element.play() : element.pause();
    else if (action === 'pause') element.src = element.src; // reloads the iframe
  }

  private getNearestItems() {
    const prevIndex =
      this.inputHandler.currentAnimationIndex - 1 >= 0
        ? this.inputHandler.currentAnimationIndex - 1
        : this.items.length - 1;
    const nextIndex = (this.inputHandler.currentAnimationIndex + 1) % this.items.length;

    return {
      prevIndex,
      nextIndex
    };
  }

  private closeOverlay(e?: Event): void {
    if (e) {
      e.stopPropagation();
    }
    if (this.closeCallback) {
      overlayEventsHandler.emit(SPHERE_EVENT_NAMES.OVERLAY.JOURNEY_OVERLAY_CLOSE);
      this.closeCallback();
      this.dispose();
    } else {
      console.error('"Close" function is not set up');
    }
  }

  handleClick(e) {
    const innerContainer = this.container.querySelector('.journey-overlay-inner-content');
    if (innerContainer.contains(e.target)) {
      this.pauseAutoPlay();
    }
    return e.target.classList.contains('journey-overlay-backdrop');
  }

  dispose() {
    this.container.classList.add('is-hidden');
    this.container.innerHTML = '';
    clearInterval(this.intervalAutoplayId);
    overlayEventsHandler.removeAll(
      [
        SPHERE_EVENT_NAMES.OVERLAY.OVERLAY_USER_ACTION,
        SPHERE_EVENT_NAMES.OVERLAY.OVERLAY_USER_SCROLL,
        SPHERE_EVENT_NAMES.OVERLAY.FULLSCREEN,
        SPHERE_EVENT_NAMES.OVERLAY.INTERACTION,
        SPHERE_EVENT_NAMES.OVERLAY.JOURNEY_OVERLAY_CLOSE
      ].join(' ')
    );
  }
}
