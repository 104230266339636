import {overlayEventsHandler} from '../custom_event_utils';
import {SPHERE_EVENT_NAMES} from '../event-names';

export class FullScreenButton {
  button: HTMLElement;

  constructor(private buttonContainer: HTMLElement) {
    this.dispose = this.dispose.bind(this);
    this.buttonContainer.innerHTML = this.initView();
    this.toggleFullscreenView = this.toggleFullscreenView.bind(this);

    this.button = this.buttonContainer.querySelector<HTMLElement>('.overlay-fullscreen-button');

    this.button.addEventListener('click', this.toggleFullscreenView);
    overlayEventsHandler.listen(SPHERE_EVENT_NAMES.OVERLAY.JOURNEY_OVERLAY_CLOSE, this.dispose);
  }

  private toggleFullscreenView() {
    overlayEventsHandler.emit(SPHERE_EVENT_NAMES.OVERLAY.FULLSCREEN);
  }

  private initView() {
    return `
      <div class="overlay-fullscreen-button-container">
        <div class="overlay-fullscreen-button">
          <div class="fullscreen-on">
            <svg width="29" height="29" viewBox="0 0 29 29" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g filter="url(#resr7y3lpa)" fill-rule="evenodd" clip-rule="evenodd" fill="#fff">
                  <path d="M18.007 4.235c0-.434.352-.786.786-.786h6.01c.433 0 .785.352.785.786v6.006a.786.786 0 1 1-1.572 0V6.133l-6.472 6.471a.786.786 0 0 1-1.112-1.111l6.473-6.472h-4.112a.786.786 0 0 1-.786-.786zM12.708 16.336a.786.786 0 0 1 0 1.111l-6.47 6.467h4.11a.786.786 0 1 1 0 1.572H4.423a.785.785 0 0 1-.876-.784v-6.008a.786.786 0 0 1 1.572 0v4.114l6.477-6.473a.786.786 0 0 1 1.111 0z"/>
              </g>
              <defs>
                <filter id="resr7y3lpa" x=".926" y=".827" width="27.285" height="27.286" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                  <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                  <feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                  <feOffset/>
                  <feGaussianBlur stdDeviation="1.311"/>
                  <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.5 0"/>
                  <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_2627_132327"/>
                  <feBlend in="SourceGraphic" in2="effect1_dropShadow_2627_132327" result="shape"/>
                </filter>
              </defs>
            </svg>
          </div>
          <div class="fullscreen-off">
            <svg width="26" height="25" viewBox="0 0 26 25" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g filter="url(#ja1hu41vua)">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M22.965 2.233a.749.749 0 0 1 0 1.059L4.758 21.498h3.916a.749.749 0 1 1 0 1.497H3.03a.748.748 0 0 1-.835-.746v-5.724a.749.749 0 0 1 1.498 0v3.92L21.906 2.232a.749.749 0 0 1 1.059 0z" fill="#fff" fill-opacity=".88"/>
              </g>
              <defs>
                <filter id="ja1hu41vua" x=".195" y=".012" width="24.989" height="24.988" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                  <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                  <feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                  <feOffset/>
                  <feGaussianBlur stdDeviation="1"/>
                  <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.5 0"/>
                  <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_2537_128279"/>
                  <feBlend in="SourceGraphic" in2="effect1_dropShadow_2537_128279" result="shape"/>
                </filter>
              </defs>
            </svg>
          </div>
        </div>
      </div>
    `;
  }

  dispose() {
    this.button.removeEventListener('click', this.toggleFullscreenView);
  }
}
