import PlanogramPoint from 'shared/utils/PlanogramPoint';
import PlanogramBox from 'shared/utils/PlanogramBox';
import { assertTrue, debugCommand } from 'shared/utils/debug';

import { TILE_CONTENT_SIZE } from './parameters';
import { HEIGHT, WIDTH } from '../config/PlanogramConfig';

const MIN_VALUE = Number.MIN_VALUE;
const MAX_DISTANCE = Math.sqrt(WIDTH * WIDTH + HEIGHT * HEIGHT) * 0.5;

function tileSize(ratio: number, sameTileCount: boolean) {
  // TODO figure out why/if this 2 is necessary
  const size = sameTileCount ? 0 : (2 * TILE_CONTENT_SIZE) / ratio;
  return {
    x: size,
    y: size,
  };
}

export interface ChunkPriorityData {
  point: PlanogramPoint;
  minPixelPlanogramRatio: number;
  maxPixelPlanogramRatio: number;
  canUpgrade: boolean;
  canDowngrade: boolean;
  someNotLoaded: boolean;
  someLoaded: boolean;
  minDistanceToAtlas: number;
  maxDistanceToAtlas: number;
  distanceToUnload: number;
}

export class TilePriority {
  private focusPoint: PlanogramPoint = new PlanogramPoint();
  private pixelsToSizeRatio: number = 1;

  private minimumQuality: number;
  private maximumQuality: number;

  constructor(targetPixelRatio: number, private unloadedTileBias: number) {
    this.minimumQuality = targetPixelRatio;
    this.maximumQuality = 2 * this.minimumQuality;
    debugCommand('tilePriority', () => this);
  }

  getFocusPoint() {
    return this.focusPoint;
  }

  setFocusPoint(point: PlanogramPoint) {
    this.focusPoint.copy(point);
  }

  setPixelRatio(ratio: number) {
    this.pixelsToSizeRatio = ratio;
  }

  rateLocation(location: PlanogramPoint, offset: number = 0): number {
    const distance = Math.max(MIN_VALUE, this.focusPoint.distance(location) + offset);
    const rating = (distance / MAX_DISTANCE) * Math.sqrt(distance); // divide to avoid huge numbers
    assertTrue(!isNaN(rating), 'NaN rating');
    return rating;
  }

  private ratePlanogramPixelRatio(ratio: number) {
    const qualityRatio = ratio / this.pixelsToSizeRatio;
    return Math.max(MIN_VALUE, qualityRatio);
  }

  needsUpgrade(chunk: ChunkPriorityData) {
    return (
      chunk.someNotLoaded ||
      this.ratePlanogramPixelRatio(chunk.minPixelPlanogramRatio) < this.minimumQuality
    );
  }

  needsDowngrade(chunk: ChunkPriorityData) {
    return this.ratePlanogramPixelRatio(chunk.maxPixelPlanogramRatio) > this.maximumQuality;
  }

  rateLocationAndRatio(location: PlanogramPoint, ratio: number) {
    const locationRating = this.rateLocation(location);
    return locationRating * this.ratePlanogramPixelRatio(ratio);
  }

  rateUpgrade(chunk: ChunkPriorityData, box: PlanogramBox) {
    const rating = this.rateBestInBox(box, chunk.minPixelPlanogramRatio);
    return rating + (chunk.canUpgrade && this.needsUpgrade(chunk) ? 0 : +Infinity);
  }

  rateDowngrade(chunk: ChunkPriorityData, box: PlanogramBox) {
    const rating = this.rateWorstInBox(box, chunk.maxPixelPlanogramRatio);
    // TODO: account for atlas differently?
    return (
      -rating * (chunk.canDowngrade ? 1 : 2 ** -this.unloadedTileBias) +
      (chunk.someLoaded ? 0 : +Infinity)
    );
  }

  rateAliasing(chunk: ChunkPriorityData, _box: PlanogramBox) {
    return -chunk.maxPixelPlanogramRatio + (chunk.canDowngrade ? 0 : +Infinity);
  }

  private upgradeRatio(chunk: ChunkPriorityData) {
    return chunk.someNotLoaded ? 2 ** (1 + this.unloadedTileBias) : 2;
  }

  estimateWorstUpgrade(chunk: ChunkPriorityData) {
    const ratio = chunk.minPixelPlanogramRatio * this.upgradeRatio(chunk);
    const box = new PlanogramBox().fromCenterAndSize(
      chunk.point,
      tileSize(ratio, chunk.minDistanceToAtlas <= 0 || !chunk.someLoaded),
    );
    return this.rateWorstInBox(box, ratio);
  }

  private downgradeRatio(chunk: ChunkPriorityData) {
    if (chunk.maxDistanceToAtlas <= 0)
      return 0.5 ** (chunk.distanceToUnload + this.unloadedTileBias);
    else return chunk.canDowngrade ? 0.5 : 0.5 ** (1 + this.unloadedTileBias);
  }

  estimateBestDowngrade(chunk: ChunkPriorityData) {
    const ratio = chunk.maxPixelPlanogramRatio * this.downgradeRatio(chunk);
    const box = new PlanogramBox().fromCenterAndSize(
      chunk.point,
      tileSize(chunk.maxPixelPlanogramRatio, chunk.maxDistanceToAtlas <= 1 || !chunk.canDowngrade),
    );
    return this.rateBestInBox(box, ratio);
  }

  rateBestInBox(box: PlanogramBox, planogramPixelRatio: number): number {
    const bestPoint = box.clamp(this.focusPoint);
    return this.rateLocationAndRatio(bestPoint, planogramPixelRatio);
  }

  rateWorstInBox(box: PlanogramBox, planogramPixelRatio: number): number {
    const worstPoint = box.farthestFrom(this.focusPoint);
    return this.rateLocationAndRatio(worstPoint, planogramPixelRatio);
  }
}
