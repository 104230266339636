import type { PlanogramWidth } from 'shared/utils/PlanogramPoint';
import PlanogramPoint, { planogramWidth } from 'shared/utils/PlanogramPoint';
import CylinderQuadTree from 'shared/utils/CylinderQuadTree';
import PlanogramBox from 'shared/utils/PlanogramBox';
import { debugCommand } from 'shared/utils/debug';

import type { ChunkPriorityData, TilePriority } from './TilePriority';
import { CylinderBox } from '../utils/modulo.util';

interface TreeData extends ChunkPriorityData {
  // chunkId and point are only useful for specific chunks, not tree branches
  point: PlanogramPoint;
  chunkId: number;
}

export type ChunkSpatialData = TreeData;

function treeDataReducer(data: TreeData[]): TreeData {
  return {
    point: new PlanogramPoint(),
    minPixelPlanogramRatio: data.reduce(
      (min, it) => Math.min(min, it.minPixelPlanogramRatio),
      +Infinity,
    ),
    maxPixelPlanogramRatio: data.reduce((max, it) => Math.max(max, it.maxPixelPlanogramRatio), 0),
    canUpgrade: data.some(it => it.canUpgrade),
    canDowngrade: data.some(it => it.canDowngrade),
    someNotLoaded: data.some(it => it.someNotLoaded),
    someLoaded: data.some(it => it.someLoaded),
    chunkId: 0,
    minDistanceToAtlas: data.reduce((min, it) => Math.min(min, it.minDistanceToAtlas), 0),
    maxDistanceToAtlas: data.reduce((max, it) => Math.max(max, it.maxDistanceToAtlas), 0),
    distanceToUnload: data.reduce((min, it) => Math.min(min, it.distanceToUnload), 0),
  };
}

function defaultTreeData(): TreeData {
  return {
    point: new PlanogramPoint(),
    minPixelPlanogramRatio: +Infinity,
    maxPixelPlanogramRatio: 0,
    canDowngrade: false,
    canUpgrade: false,
    someNotLoaded: false,
    someLoaded: false,
    chunkId: 0,
    minDistanceToAtlas: 0,
    maxDistanceToAtlas: 0,
    distanceToUnload: 0,
  };
}

export default class ChunkSpatialTree extends CylinderQuadTree<PlanogramWidth, TreeData> {
  constructor(private tilePriority: TilePriority) {
    super(
      planogramWidth,
      treeDataReducer,
      defaultTreeData,
      new PlanogramBox({
        min: { x: 0, y: -Infinity },
        max: { x: planogramWidth, y: +Infinity },
      }),
    );

    const self = this;
    debugCommand('lodSpatialTree', () => self);
  }

  private findWithBlacklist(
    blacklist: Set<number>,
    rate: (data: TreeData, box: CylinderBox<PlanogramWidth>) => number,
  ) {
    const best = this.findLowestRating((data, box) =>
      blacklist.has(data.chunkId) ? +Infinity : rate(data, box),
    );
    if (best === undefined || blacklist.has(best.chunkId)) return undefined;
    else return best;
  }

  findUpgrade(blacklist: Set<number>) {
    const best = this.findWithBlacklist(blacklist, (data, box) =>
      this.tilePriority.rateUpgrade(data, box),
    );
    if (best === undefined || !best.canUpgrade || !this.tilePriority.needsUpgrade(best))
      return undefined;
    else return best;
  }

  findDowngrade(blacklist: Set<number>) {
    const best = this.findWithBlacklist(blacklist, (data, box) =>
      this.tilePriority.rateDowngrade(data, box),
    );
    if (best === undefined || !best.someLoaded) return undefined;
    else return best;
  }

  findAliased(blacklist: Set<number>) {
    const best = this.findWithBlacklist(blacklist, (data, box) =>
      this.tilePriority.rateAliasing(data, box),
    );
    if (best === undefined || !best.canDowngrade || !this.tilePriority.needsDowngrade(best))
      return undefined;
    else return best;
  }
}
