import { WIDTH } from 'shared/config/PlanogramConfig';
import { Arithmetic, CylinderPoint } from 'shared/utils/modulo.util';
import type { Vector } from 'shared/utils/modulo.util';

export type PlanogramWidth = typeof WIDTH;
export const planogramWidth: PlanogramWidth = WIDTH;
export const planogramArithmetic = new Arithmetic(WIDTH);

export default class PlanogramPoint<
  V extends Vector = Vector
> extends CylinderPoint<PlanogramWidth> {
  constructor(position?: V) {
    super(planogramWidth);
    if (position) this.set(position.x, position.y);
    else this.set(0, 0);
  }
}
