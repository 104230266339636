import {ShoppingCartService} from '../api/services/shopping-cart.service';
import {SphereApp} from '../sphere_app';
import {WebUtils} from '../utils/web_utils';
import {InputEventUtils} from '../utils/input_event_utils';
import {Metrics} from '../metrics';
import {MATOMO_EVENT_NAMES} from '../metric-events';
import {CurrencyService} from '../api/services/currency.service';
import {AppState} from '../shared/app.state';
import {L10nUtils} from '../utils/l10n_utils';
import {appendHtml} from '../utils/html_utils';
import {UrlUtils} from '../api/urls';
import {QuantityPicker} from '../overlays/shopify-product-info/components/quantity-picker/quantity-picker';
import {ShopifyProduct, ShopifyVariant} from '../interfaces/shopify-product.interface';
import {ShoppingCartComponent} from '../shopping-cart/shopping-cart.component';
import {shoppingCartWidgetView} from './shopping-cart-widget.view';
import {ErrorResponseInterface, ShoppingCartInterface} from '../interfaces/shopping-cart.interface';

export class ShoppingCartWidgetComponent extends ShoppingCartComponent {
  protected panelElement: HTMLElement;
  protected scPanelContainer: HTMLElement;
  protected scCheckoutButton: HTMLElement;
  protected backdropWrapper: HTMLElement;

  constructor(
    protected sphereApp: SphereApp,
    protected shoppingCartService: ShoppingCartService,
    protected currencyService: CurrencyService,
    protected quantityPicker?: QuantityPicker,
    protected wrapperSelector?: string,
    private product?: ShopifyProduct
  ) {
    super(sphereApp, shoppingCartService, currencyService, quantityPicker, wrapperSelector);
  }

  protected initView() {
    this.panelElement = document.createElement('div');
    appendHtml(
      this.panelElement,
      shoppingCartWidgetView(this.overlaySettings, this.shoppingCartService.isMultipassEnabled)
    );
    document.getElementById(this.wrapperSelector)?.append(this.panel);

    this.scCheckoutButton = this.panelElement.querySelector<HTMLElement>('.app-shopping-cart-checkout-button');
    this.backdropWrapper = this.panelElement.querySelector<HTMLElement>('.app-shopping-cart-panel-backdrop');
    this.scPanelContainer = this.panelElement.querySelector<HTMLElement>('.app-shopping-cart-panel-container');
  }

  protected getQuantityBadgeProducts() {}

  protected setOverlayFonts() {}

  protected setColorAndButtonSettings() {}

  protected updateSubtotalPrice() {}

  protected handleClicks() {
    InputEventUtils.addSelectEvents(this.scCheckoutButton, () => {
      if (!this.scCheckoutButton.classList.contains('app-disabled')) {
        Metrics.storeTheEvent(
          AppState.planogramName,
          'click',
          MATOMO_EVENT_NAMES.CLICK_ECOMMERCE_OVERLAY_POPUP_CHECKOUT_BUTTON(
            this.product?.identifier,
            UrlUtils.slugify(this.product?.name)
          )
        );

        const checkoutUrl = UrlUtils.getUrlWithQueries(this.checkout?.checkout_url, {
          locale: L10nUtils.getCurrentLanguage(),
          planogram_id: this.sphereApp.planogram.id.toString()
        });

        if (this.shoppingCartService.getMultipassToken) {
          const link = window.open();
          this.scCheckoutButton.classList.add('loading');
          this.shoppingCartService
            .generateMultipassLink(checkoutUrl)
            .then(resp => (link.location = resp?.link))
            .finally(() => this.scCheckoutButton.classList.remove('loading'));
        } else {
          WebUtils.openLink(checkoutUrl);
        }
      }
    });

    const continueButton: HTMLElement = this.panelElement.querySelector<HTMLElement>(
      '.app-shopping-cart-continue-button'
    );

    InputEventUtils.addSelectEvents(this.backdropWrapper, () => {
      this.panelVisibility = false;
    });

    if (continueButton) {
      InputEventUtils.addSelectEvents(continueButton, () => {
        this.panelVisibility = false;
      });
    }
  }

  protected updateCheckoutButtonState() {
    if (this.hasItemsWithError) {
      this.scCheckoutButton.classList.add('app-disabled');
    } else {
      this.scCheckoutButton.classList.remove('app-disabled');
    }
  }

  protected updateShoppingCartState(checkout: ShoppingCartInterface | null, err?: ErrorResponseInterface | undefined) {
    super.updateShoppingCartState(checkout, err);
    const successfullyAddedIcon = `
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M23 12v-.9c0-.6-.4-1-1-1s-1 .4-1 1v.9c0 5-4 9-9 9s-9-4-9-9 4-9 9-9c1.3 0 2.5.3 3.7.8.5.2 1.1 0 1.3-.5.2-.5 0-1.1-.5-1.3-1.4-.7-3-1-4.5-1C5.9 1 1 5.9 1 12s4.9 11 11 11 11-4.9 11-11zm-.7-9.7c.4-.4 1-.4 1.4 0 .4.4.4 1 0 1.4l-11 11c-.2.2-.5.3-.7.3-.2 0-.5-.1-.7-.3l-3-3c-.4-.4-.4-1 0-1.4.4-.4 1-.4 1.4 0l2.3 2.3L22.3 2.3z" fill="currentColor"/>
      <mask id="a" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="1" y="1" width="23" height="22">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M23 12v-.9c0-.6-.4-1-1-1s-1 .4-1 1v.9c0 5-4 9-9 9s-9-4-9-9 4-9 9-9c1.3 0 2.5.3 3.7.8.5.2 1.1 0 1.3-.5.2-.5 0-1.1-.5-1.3-1.4-.7-3-1-4.5-1C5.9 1 1 5.9 1 12s4.9 11 11 11 11-4.9 11-11zm-.7-9.7c.4-.4 1-.4 1.4 0 .4.4.4 1 0 1.4l-11 11c-.2.2-.5.3-.7.3-.2 0-.5-.1-.7-.3l-3-3c-.4-.4-.4-1 0-1.4.4-.4 1-.4 1.4 0l2.3 2.3L22.3 2.3z" fill="#fff"/>
      </mask>
    </svg>`;
    const errorIcon = `
      <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" fill="currentColor" clip-rule="evenodd" d="M0 11C0 4.928 4.917 0 10.989 0 17.072 0 22 4.928 22 11s-4.928 11-11.011 11C4.917 22 0 17.072 0 11zm12.1-4.4c0-.605-.495-1.1-1.1-1.1-.605 0-1.1.495-1.1 1.1V11c0 .605.495 1.1 1.1 1.1.605 0 1.1-.495 1.1-1.1V6.6zM11 19.8A8.798 8.798 0 0 1 2.2 11c0-4.862 3.938-8.8 8.8-8.8s8.8 3.938 8.8 8.8-3.938 8.8-8.8 8.8zm-1.1-5.5v2.2h2.2v-2.2H9.9z"/>
      </svg>
    `;
    const notificationWrapper = this.scPanelContainer.querySelector<HTMLElement>(
      '.app-shopping-cart-added-notification'
    );
    const messageContainer = this.scPanelContainer.querySelector<HTMLElement>(
      '.app-shopping-cart-added-notification-text'
    );
    const variant = this.checkout?.products.find(item => {
      return (item as ShopifyVariant).variant_id === this.quantityPicker.PRODUCT_VARIANT_ID;
    });
    const currentQuantity = variant?.quantity || 0;
    let messageBody;
    notificationWrapper.classList.remove('error');
    switch (true) {
      case err && !this.checkout: {
        err.json().then(resp => {
          const errMessage = resp.response?.[0];
          notificationWrapper.classList.add('error');
          messageContainer.innerText = '';
          messageBody = `
            <span class="app-shopping-cart-added-notification-text-title">
              <span class="app-shopping-cart-added-notification-success-icon">${successfullyAddedIcon}</span>
              <span class="app-shopping-cart-added-notification-error-icon">${errorIcon}</span>
              ${L10nUtils.l10n('shopping-cart.panel.error-notification-title')}
            </span>
            <span class="app-shopping-cart-added-notification-text-message">
              ${errMessage || L10nUtils.l10n('shopping-cart.panel.error-notification-default-message')}
            </span>
          `;
          appendHtml(messageContainer, messageBody);
        });
        break;
      }
      case currentQuantity <= this.quantityPicker.PRODUCT_VARIANT_INVENTORY_QUANTITY &&
        this.quantityPicker.PRODUCT_QUANTITY <= this.quantityPicker.PRODUCT_VARIANT_AVAILABLE_QUANTITY: {
        messageContainer.innerText = '';
        messageBody = `
          <span class="app-shopping-cart-added-notification-text-title">
            <span class="app-shopping-cart-added-notification-success-icon">${successfullyAddedIcon}</span>
            <span class="app-shopping-cart-added-notification-error-icon">${errorIcon}</span>
            ${L10nUtils.l10n('shopping-cart.panel.added-notification-title')}
          </span>
        `;
        appendHtml(messageContainer, messageBody);
        break;
      }
      case this.quantityPicker.PRODUCT_VARIANT_AVAILABLE_QUANTITY > 0 &&
        this.quantityPicker.PRODUCT_QUANTITY > this.quantityPicker.PRODUCT_VARIANT_AVAILABLE_QUANTITY: {
        notificationWrapper.classList.add('error');
        messageContainer.innerText = '';
        messageBody = `
          <span class="app-shopping-cart-added-notification-text-title">
            <span class="app-shopping-cart-added-notification-success-icon">${successfullyAddedIcon}</span>
            <span class="app-shopping-cart-added-notification-error-icon">${errorIcon}</span>
            ${L10nUtils.l10n('shopping-cart.panel.error-available-notification')}
          </span>
          <span class="app-shopping-cart-added-notification-text-message">
            ${this.quantityPicker.PRODUCT_VARIANT_AVAILABLE_QUANTITY}
            ${L10nUtils.l10n('shopping-cart.panel.error-available-notification-separator')}
            ${this.quantityPicker.PRODUCT_VARIANT_INVENTORY_QUANTITY}
            ${L10nUtils.l10n('shopping-cart.panel.error-available-notification-items-added')}
          </span>
        `;
        appendHtml(messageContainer, messageBody);
        break;
      }
      case !this.quantityPicker.PRODUCT_VARIANT_AVAILABLE_QUANTITY: {
        notificationWrapper.classList.add('error');
        messageContainer.innerText = '';
        messageBody = `
          <span class="app-shopping-cart-added-notification-text-title">
            <span class="app-shopping-cart-added-notification-success-icon">${successfullyAddedIcon}</span>
            <span class="app-shopping-cart-added-notification-error-icon">${errorIcon}</span>
            ${L10nUtils.l10n('shopping-cart.panel.error-notification-title')}
          </span>
          <span class="app-shopping-cart-added-notification-text-message">
            ${L10nUtils.l10n('shopping-cart.panel.error-notification-message')}
          </span>
        `;
        appendHtml(messageContainer, messageBody);
        break;
      }
    }
    this.quantityPicker.updateAvailableQuantity();
  }
}
