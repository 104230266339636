import Stats from 'three/examples/jsm/libs/stats.module';

export class FPSMeter {
  private static readonly SECOND = 1000;

  private static getFormattedValue(num: number): string {
    return num.toFixed(1).padStart(4, '0');
  }

  private fps: number;
  private isLoopRunning: boolean;
  private stats: Stats;
  private wrapper: HTMLElement;
  private controlsEl: HTMLElement;
  private buttonEl: HTMLElement;
  private countFn: Function;
  constructor(private container: HTMLElement) {
    this.wrapper = document.createElement('div');
    this.wrapper.setAttribute('id', 'custom-fps-meter');
    this.container.appendChild(this.wrapper);

    this.initDiagram();
    this.initControls();
    this.initLoop();
  }

  private initDiagram() {
    this.stats = Stats();
    this.stats.dom.style.position = 'static';
    this.wrapper.appendChild(this.stats.dom);
  }

  private initControls() {
    this.controlsEl = document.createElement('div');
    this.controlsEl.classList.add('stat-controls');
    this.controlsEl.innerHTML = `
      <div class="stat-value">SEC: <span>--</span></div>
      <div class="stat-value">AVG: <span>--</span></div>
      <div class="stat-value">MIN: <span>--</span></div>
      <div class="stat-value">MAX: <span>--</span></div>
    `;

    this.buttonEl = document.createElement('div');
    this.buttonEl.classList.add('stat-control-btn');
    this.buttonEl.textContent = 'Start';

    this.buttonEl.addEventListener('click', this.toggleCount.bind(this));

    this.wrapper.appendChild(this.controlsEl);
    this.wrapper.appendChild(this.buttonEl);
  }

  private initLoop() {
    this.isLoopRunning = true;
    const times = [];

    const update = () => {
      this.stats.begin();
      const now = performance.now();
      while (times.length > 0 && times[0] <= now - FPSMeter.SECOND) {
        times.shift();
      }
      times.push(now);
      this.fps = times.length;
      this.countFn?.(now);
      this.stats.end();

      if (this.isLoopRunning) {
        requestAnimationFrame(update);
      }
    };

    requestAnimationFrame(update);
  }

  private updateStats(ms = 0, avg = 0, min = 0, max = 0) {
    this.controlsEl.children[0].firstElementChild.textContent = FPSMeter.getFormattedValue(ms / FPSMeter.SECOND);
    this.controlsEl.children[1].firstElementChild.textContent = FPSMeter.getFormattedValue(avg);
    this.controlsEl.children[2].firstElementChild.textContent = FPSMeter.getFormattedValue(min);
    this.controlsEl.children[3].firstElementChild.textContent = FPSMeter.getFormattedValue(max);
  }

  private toggleCount() {
    if (this.countFn) {
      this.buttonEl.textContent = 'Start';
      this.countFn = null;
    } else {
      this.buttonEl.textContent = 'Stop';
      this.countFn = (() => {
        const startTime = performance.now();
        let fpsSum = 0;
        let trackCount = 0;
        let min = Infinity;
        let max = 0;

        return (now: number) => {
          trackCount++;
          fpsSum += this.fps;
          min = Math.min(min, this.fps);
          max = Math.max(max, this.fps);

          this.updateStats(now - startTime, fpsSum / trackCount, min, max);
        };
      })();
    }
  }

  destroy() {
    this.isLoopRunning = false;
    this.wrapper.remove();
  }
}
