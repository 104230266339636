import { Texture } from 'three';

export class TextureCache {
  load(url: string): Promise<Texture> {
    const cached = this.cache.get(url);
    if (cached) return cached;
    const img = document.createElement('img');
    img.crossOrigin = 'anonymous';
    img.src = url;
    const cancelation = new AbortController();
    this.cancelations.set(url, cancelation);
    const request = new Promise<Texture>((resolve, reject) => {
      cancelation.signal.onabort = () => {
        img.src = '';
        reject(new Error('Canceled'));
      };
      img.onload = () => {
        this.cancelations.delete(url);
        const texture = new Texture(img);
        texture.generateMipmaps = false;
        texture.needsUpdate = true;
        resolve(texture);
      };
      img.onerror = reject;
    });
    this.cache.set(url, request);
    request.finally(() => {
      const cached = this.cancelations.get(url);
      if (cached === cancelation) this.cancelations.delete(url);
    });
    return request;
  }

  cancel(url: string) {
    this.cancelations.get(url)?.abort();
    this.cancelations.delete(url);
  }

  unload(url: string) {
    this.cancelations.get(url)?.abort();
    this.cache.get(url)?.then(t => t.dispose());
    this.cancelations.delete(url);
    this.cache.delete(url);
  }

  dispose() {
    Array.from(this.cancelations.values()).forEach(c => c.abort());
    Array.from(this.cache.values()).forEach(p => p.then(t => t.dispose()));
  }

  private cancelations = new Map<string, AbortController>();
  private cache = new Map<string, Promise<Texture>>();
}
