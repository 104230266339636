export class CheckboxField {
  private readonly inputEl: HTMLInputElement;

  constructor(private inputWrapper: HTMLElement, onChange: (value: boolean) => void) {
    this.inputEl = this.inputWrapper.querySelector<HTMLInputElement>('input');

    this.inputEl.addEventListener('change', () => {
      onChange(this.value);
    });
  }

  get value() {
    return this.inputEl.checked;
  }
}
