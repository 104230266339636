import {L10nUtils} from '../../../utils/l10n_utils';
import {URLS} from '../../../api/urls';
import {Product} from '../../../interfaces/product.interface';
import {InputFieldsComponentView} from './input-fields/input-fields-component.view';

export const shopifyHarryPotterOverlayView = (product: Product, showInputFields) => {
  const checkMarkIcon = `
    <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g opacity=".65">
        <path d="m14.183 5.133-7.334 7.334a.605.605 0 0 1-.466.2.605.605 0 0 1-.467-.2L2.583 9.133a.644.644 0 0 1 0-.933.644.644 0 0 1 .933 0l2.867 2.867L13.249 4.2a.644.644 0 0 1 .934 0 .644.644 0 0 1 0 .933z" fill="#000"/>
        <mask id="a" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="2" y="4" width="13" height="9">
          <path d="m14.183 5.133-7.334 7.334a.605.605 0 0 1-.466.2.605.605 0 0 1-.467-.2L2.583 9.133a.644.644 0 0 1 0-.933.644.644 0 0 1 .933 0l2.867 2.867L13.249 4.2a.644.644 0 0 1 .934 0 .644.644 0 0 1 0 .933z" fill="currentColor"/>
        </mask>
      </g>
    </svg>
  `;
  const lineSeparatorIcon = `
    <svg xmlns="http://www.w3.org/2000/svg" width="1239" height="3" viewBox="0 0 1239 3" preserveAspectRatio="none">
      <g>
        <g>
          <path fill="currentColor" d="M4.839 1.954c40.771-.609 95.038.153 142.184 0 5.995-.019 3.66-.446 5.675-.48 3.958-.066 9.432.642 17.074.48 2.299-.049 7.24-.48 5.687-.48 16.742-.012 30.51.917 51.186.48 2.43-.051 7.086-.483 5.688-.48 4.265-.008 9.526.466 17.062.48 4.609.008 9.088-.51 17.05-.48 4.692.018 9.29.447 17.074.48 31.162.131 60.06-.28 90.997-.48 60.132-.388 120.81 0 181.995 0 59.93 0 119.103-.292 176.296 0 27.406.14 49.966.749 85.322.48 9.752-.074 18.71-.464 28.425-.48-1.6.003 2.844.466 5.7.48 9.348.042 9.68-.484 17.061-.48-1.303 0 2.595.466 5.676.48 1.908.008 16.043.106 17.074 0 8.164-.844 12.82 1.024 22.75 0 8.163-.844 12.82 1.024 22.749 0 5.142-.53 2.962-.071 11.374 0 6.896.058 10.238-.486 11.375-.48 5.77.037 12.334.472 22.75.48 10.497.008 24.123.035 34.112 0-.913.003 2.322-.465 5.699-.48 4.076-.018 9.633.474 17.05.48 61.779.054 128.546-.039 199.057 0 .853-1.713-27.382-.926-34.124-.96-42.394-.214-87.763.181-136.484 0-13.496-.05-25.771-.383-39.812-.479-105.18-.727-223.394-.052-335.565 0-19.467.008-38.425-.091-56.874 0-15.889.079-29.621.442-45.498.479-5.036.012-12.157.09-17.05 0-4.669-.085-5.629-.479-11.387-.479-10.202.001-11.967.526-22.75.479-15.604-.067-34.254-.451-51.174-.479-93.699-.162-195.336-.309-295.742 0-9.336.028-19.183-.097-28.448 0-19.693.204-45.463.676-79.611 0C79.699.42 78.857.074 73.087.034 37.932-.209 50.255.957 23.88.147c0 0-36.79 2.073-19.041 1.807"/>
        </g>
      </g>
    </svg>
  `;
  const shareLinkSrc = URLS.OVERLAY_SHARE_LINK_ICON;
  const shareDeviceSrc = URLS.OVERLAY_SHARE_DEVICE_ICON;

  const twitterShareLinkSrc = URLS.OVERLAY_TWITTER_ICON;
  const whatsappShareLinkSrc = URLS.OVERLAY_WHATSAPP_ICON;
  const telegramShareLinkSrc = URLS.OVERLAY_TELEGRAM_ICON;
  const viberShareLinkSrc = URLS.OVERLAY_VIBER_ICON;

  return `
    <div id="shopify-product-details-container" class="app-shopify-harry-potter-template">
      <div class="app-shopify-product-overlay">
        <div class="app-shopify-product-overlay-controls app-product-overlay-controls">
          <div class="overlay-button overlay-button-close">
            <span></span>
          </div>
        </div>
        <div class="app-shopify-product-content-wrapper">
          <div class="app-shopify-product-carousel">
            <div class="app-shopify-image-carousel-container">
              <div id="app-image-carousel" class="splide">
                <div class="splide__arrows">
                  <button class="splide__arrow splide__arrow--prev">
                    <span class="overlay-arrow overlay-arrow-prev"></span>
                  </button>
                  <button class="splide__arrow splide__arrow--next">
                    <span class="overlay-arrow overlay-arrow-next"></span>
                  </button>
                </div>
                <div class="splide__track">
                  <ul class="splide__list"></ul>
                </div>
              </div>
              
              <div class="see_inside-button-wrapper">
                <div id="see_inside-button" class="is-hidden">
                  ${L10nUtils.l10n('shopify-product-overlay.see-inside-btn-text')}
                </div>
              </div>
            </div>
            
            <div class="app-shopify-thumbnail-carousel-container">
              <div id="app-thumbnail-carousel" class="splide">
                <div id="app-shopify-thumbnail-splide-arrows" class="splide__arrows">
                  <button class="splide__arrow splide__arrow--prev">
                    <span class="overlay-arrow overlay-arrow-prev"></span>
                  </button>
                  <button class="splide__arrow splide__arrow--next">
                    <span class="overlay-arrow overlay-arrow-next"></span>
                  </button>
                </div>
                <div class="splide__track">
                  <ul class="splide__list">
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div class="app-shopify-product-details-container">
            <div class="text-wrapper allow-scroll">
              <div class="share-mobile-container">
                <div id="share-mobile" class="is-hidden" role="button" tabindex="0">
                  <span class="share-mobile-text">${L10nUtils.l10n('product-details-overlay.share-button.text')}</span>
                  <img src="${shareDeviceSrc}">
                </div>
              </div>
              <div class="app-shopify-product-details-header">
                <div class="app-shopify-product-title">${product.name}</div>
              </div>
              <div class="app-shopify-product-line-separator">${lineSeparatorIcon}</div>
              <div class="app-shopify-product-price">
                <span class="app-shopify-product-discount-price-value"></span>
                <span class="app-shopify-product-price-value"></span>
                <div class="app-shopify-product-line-separator">${lineSeparatorIcon}</div>
              </div>
              ${product.input_fields?.length && showInputFields ? InputFieldsComponentView(product) : ''}
              <div class="app-shopify-variants-container"></div>
              <div class="app-shopify-quantity-picker-container"></div>
              <div class="app-shopify-out-of-stock-container"></div>
              <div class="app-shopify-buttons-container"></div>
              <div class="app-shopify-description-container"></div>
              <div class="app-share-links is-hidden">
                <img class="share-button-whatsapp share-button is-hidden" src="${whatsappShareLinkSrc}" role="button" tabindex="0">
                <img class="share-button-telegram share-button is-hidden" src="${telegramShareLinkSrc}" role="button" tabindex="0">
                <img class="share-button-viber share-button is-hidden" src="${viberShareLinkSrc}" role="button" tabindex="0">
                <img class="share-button-twitter share-button is-hidden" src="${twitterShareLinkSrc}" role="button" tabindex="0">
                <div class="share-link-holder">
                  <img class="share-button-copy_link share-button is-hidden" src="${shareLinkSrc}" role="button" tabindex="0">
                  <div class="share-success-message">
                    ${checkMarkIcon}
                    <span>${L10nUtils.l10n('product-details-overlay.share.notification')}</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="text-fade"></div>
          </div>
        </div>
        <div id="share-mobile-popup" class="is-hidden">
          <div class="app-popup">
            <div class="share-button-imgs-wrapper">
              <img class="share-button-whatsapp share-button is-hidden" src="${whatsappShareLinkSrc}">
              <img class="share-button-telegram share-button is-hidden" src="${telegramShareLinkSrc}">
              <img class="share-button-viber share-button is-hidden" src="${viberShareLinkSrc}">
              <img class="share-button-twitter share-button is-hidden" src="${twitterShareLinkSrc}">
              <img class="share-button-copy_link share-button is-hidden" src="${shareLinkSrc}">
            </div>
          </div>
          <div class="app-popup-backdrop"></div>
        </div>
        <div id="app-shopify-cart-container" aria-hidden="true"></div>
      </div>
    </div>
  `;
};
