import {URLS} from '../api/urls';
import {overlayEventsHandler} from '../custom_event_utils';
import {SPHERE_EVENT_NAMES} from '../event-names';

interface JourneyOverlayNavigationBarProps {
  container: HTMLElement;
  autoplay: boolean;
  onPrev: Function;
  onToggleAutoPlay: Function;
  onNext: Function;
  onExit: Function;
}

export class JourneyOverlayNavigationBar {
  private shareView: HTMLElement;
  private container: HTMLElement;
  private navigationView: HTMLElement;
  private onPrev: Function;
  private onToggleAutoPlay: Function;
  private onNext: Function;
  private onExit: Function;
  private isShareViewVisible: boolean = false;

  autoplay: boolean;

  constructor({container, autoplay, onPrev, onToggleAutoPlay, onNext, onExit}: JourneyOverlayNavigationBarProps) {
    this.autoplay = autoplay;
    this.onPrev = onPrev;
    this.onToggleAutoPlay = onToggleAutoPlay;
    this.onNext = onNext;
    this.onExit = onExit;
    this.shareView = this.createShareView();
    this.navigationView = this.createNavigationView();
    this.container = container;

    container.appendChild(this.navigationView);
    overlayEventsHandler.listen(SPHERE_EVENT_NAMES.OVERLAY.INTERACTION, this.togglePlayButtonState.bind(this));
  }

  private createNavigationView() {
    const navContainer = document.createElement('div');
    navContainer.classList.add('journey-overlay-navigation-container');

    const textContainer = document.createElement('div');
    textContainer.classList.add('text-container');
    navContainer.appendChild(textContainer);

    const prevText = this.createText('PREV');
    textContainer.appendChild(prevText);
    const playPauseText = this.createText(this.autoplay ? 'PAUSE' : 'PLAY');
    textContainer.appendChild(playPauseText);
    const nextText = this.createText('NEXT');
    textContainer.appendChild(nextText);

    const navigationBar = document.createElement('div');
    navigationBar.classList.add('navigation-bar');
    navContainer.appendChild(navigationBar);

    navigationBar.appendChild(this.createButton(URLS.JOURNEY_SHARE_ICON, this.handleShareClick.bind(this)));

    const centerButtonsContainer = document.createElement('div');
    centerButtonsContainer.classList.add('center-buttons-container');

    centerButtonsContainer.appendChild(this.createButton(URLS.JOURNEY_PREV_ICON, this.onPrev.bind(null, true)));

    const playButton = this.createButton(
      URLS.JOURNEY_PLAY_ICON,
      () => {
        this.onToggleAutoPlay();
        this.togglePlayButtonState();
      },
      'play-button'
    );
    const pauseIcon = document.createElement('img');
    pauseIcon.classList.add('pause-icon');
    pauseIcon.src = URLS.JOURNEY_PAUSE_ICON;
    playButton.appendChild(pauseIcon);
    centerButtonsContainer.appendChild(playButton);
    centerButtonsContainer.appendChild(this.createButton(URLS.JOURNEY_NEXT_ICON, this.onNext.bind(null, true)));

    navigationBar.appendChild(centerButtonsContainer);

    const exitButton = this.createButton(URLS.JOURNEY_EXIT_ICON, this.onExit);
    navigationBar.appendChild(exitButton);

    return navContainer;
  }

  private togglePlayButtonState() {
    const buttonIcon = this.container.querySelector('.play-button');
    const buttonText = this.container.querySelector<HTMLElement>('.text.PLAY, .text.PAUSE');

    buttonText.innerText = !this.autoplay ? 'PLAY' : 'PAUSE';
    buttonIcon.classList.toggle('is-playing', this.autoplay);
  }

  private createShareView() {
    const navContainer = document.createElement('div');
    navContainer.classList.add('journey-overlay-navigation-container');

    const navigationBarContainer = document.createElement('div');
    navigationBarContainer.classList.add('navigation-bar');
    navigationBarContainer.style.backgroundColor = '#fff';
    navContainer.appendChild(navigationBarContainer);

    const shareButtonsContainer = document.createElement('div');
    shareButtonsContainer.classList.add('share-buttons-container');

    const shareUrl = encodeURIComponent(window.location.href);

    shareButtonsContainer.appendChild(
      this.createButton(URLS.JOURNEY_WHATSAPP_ICON, () => {
        window.open(`https://api.whatsapp.com/send?text=${shareUrl}`, '_blank');
      })
    );
    shareButtonsContainer.appendChild(
      this.createButton(URLS.JOURNEY_TWITTER_ICON, () => {
        window.open(`https://twitter.com/intent/tweet?url=${shareUrl}`, '_blank');
      })
    );
    shareButtonsContainer.appendChild(
      this.createButton(URLS.JOURNEY_TELEGRAM_ICON, () => {
        window.open(`https://t.me/share/url?url=${shareUrl}`, '_blank');
      })
    );
    shareButtonsContainer.appendChild(
      this.createButton(URLS.JOURNEY_VIBER_ICON, () => {
        window.open(`viber://forward?text=${shareUrl}`, '_blank');
      })
    );
    shareButtonsContainer.appendChild(
      this.createButton(URLS.JOURNEY_LINK_ICON, () => {
        navigator.clipboard.writeText(window.location.href).then(() => {
          alert('Link copied to clipboard');
        });
      })
    );
    navigationBarContainer.appendChild(shareButtonsContainer);
    navigationBarContainer.appendChild(
      this.createButton(URLS.JOURNEY_CLOSE_SHARE_ICON, this.handleShareClick.bind(this))
    );
    return navContainer;
  }

  private createButton(iconUrl: string, onClick: Function, additionalClass = '') {
    const button = document.createElement('div');
    button.classList.add('button-container');

    if (additionalClass) {
      const classes = [additionalClass];
      if (this.autoplay) {
        classes.push('is-playing');
      }
      button.classList.add(...classes);
    }

    const icon = document.createElement('img');
    icon.src = iconUrl;
    icon.classList.add('button-icon');

    button.addEventListener('click', () => onClick());
    button.appendChild(icon);
    return button;
  }

  private createText(label: string) {
    const text = document.createElement('div');
    text.classList.add('text', label);
    text.innerText = label;
    return text;
  }

  private handleShareClick() {
    if (this.autoplay) {
      this.onToggleAutoPlay();
      this.togglePlayButtonState();
    }
    this.isShareViewVisible = !this.isShareViewVisible;
    if (this.isShareViewVisible) {
      this.navigationView.replaceWith(this.shareView);
    } else {
      this.shareView.replaceWith(this.navigationView);
    }
  }
}
