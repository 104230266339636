export const button = ({
  text,
  className = '',
  secondary = false,
  type = 'button'
}) => {
  return `
    <button 
      class="account-overlay-button ${secondary ? 'account-button-overlay-secondary' : 'account-button-overlay-primary hp-custom-button'} ${className && className}"
      type="${type}"
    >
      ${!secondary ?
        `<span class="btn-border">
          <span class="left-border">
            <span class="square"></span>
          </span>
          <span class="right-border">
            <span class="square"></span>
          </span>
        </span>` : ''
      }
      <span>${text}</span>
    </button>
  `;
};
