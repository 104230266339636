export class SelectFieldComponent {
  private readonly selectEl: HTMLSelectElement;

  constructor(private selectWrapper: HTMLElement, onChange?: (value: string) => void) {
    this.selectEl = this.selectWrapper.querySelector<HTMLSelectElement>('select');
    this.selectEl.addEventListener('change', () => {
      if (onChange) {
        onChange(this.value);
      }
    });
  }

  get value() {
    return this.selectEl.value;
  }

  validate() {
    return this.selectEl.reportValidity();
  }
}
