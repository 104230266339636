import {PRODUCT_PAGE_ROUTES, PRODUCT_VIDEO_PROVIDER} from '../shared/constants';

export interface Product {
  id: number;
  age: string;
  buttons: {
    amazon: string;
    custom: string;
    // Only for HP client
    custom_pin: string;
    ebay: string;
  };
  identifier: string;
  is_polybags: string; // 'yes', 'no' or null
  name: string;
  brand: string;
  description: string;
  features: string;
  edition: string;
  collection: string;
  released: string;
  discounted_price_gbp: string;
  discounted_price_usd: string;
  discounted_price_eur: string;
  label: string;
  pieces: number;
  discounted_price_in_user_currency_str: string;
  price_in_user_currency_str: string;
  price_gbp: string;
  price_usd: string;
  price_eur: string;
  preview_pictures_enabled: boolean;
  preview_pictures: Array<ProductImage>;
  product_images: Array<ProductImage>;
  product_videos?: Array<ProductVideo>; // could be 'undefined'
  video_first: boolean;
  year: number;
  release_countdown: ProductReleaseCountdownData;
  rakuten_info: {
    buy_link: string;
    is_fallback: boolean;
  };
  availability: boolean;
  conditions_of_business: string;
  pdp_alternative_title: string;
  pdp_title: string;
  shipping: boolean;
  category_name: string;
  show_product_gallery_view: boolean;
  input_fields: Array<{
    name: string;
    identifier: string;
  }>;
  theme_name?: HP_OVERLAY_THEMES;
  per_order_limit?: number;
  seo_title: string;

  // only for UAL client
  course_title: string;
  graduate_profile_link: string;
  graduate_website_link: string;
  graduate_instagram_link: string;
  graduate_linkedin_link: string;
  email_link: string;
  graduate_portfolio_pdf: string;

  // only for Khalili client
  inventory_number: string;
  time_period: string;
  location: string;
  product_size: string;
  color_description: string;
}

export interface ProductImageVariants {
  VARIANT_LARGE_WEBP: string; // "/pubtool-images/gb4i4vf7qai26l7jhsk4kba5n43p",
  VARIANT_MEDIUM_WEBP?: string; // "/pubtool-images/gb4i4vf7qai26l7jhsk4kba5n43p",
  VARIANT_SMALL_WEBP?: string; // "/pubtool-images/gb4i4vf7qai26l7jhsk4kba5n43p",
  VARIANT_THUMBNAIL_WEBP?: string; // "/pubtool-images/gb4i4vf7qai26l7jhsk4kba5n43p",
  VARIANT_THUMBNAIL_PNG_600?: string; // "/pubtool-images/gb4i4vf7qai26l7jhsk4kba5n43p"
}

export interface ProductImage {
  id: number; // 70698;
  image_name: string; // "75153_alt2.png"
  shopify_id: number;
  variants: ProductImageVariants;
  seo_title: string;
  accessibility_description: string;
}

export interface ProductReleaseCountdownData {
  pre_release: boolean;
  server_time: string; // '2020-09-02T13:18:15.000Z'
  release_time: string; // '2020-09-04T10:05:15.000Z'
  release_title: string;
  release_description: string;
}

export class CountdownTimeValues {
  days: string;
  hours: string;
  minutes: string;
  seconds: string;
}

export interface ProductVideo {
  provider: PRODUCT_VIDEO_PROVIDER;
  video_url: string; // 'https://www.youtube.com/watch?v=l1_9zIpc3hE',
  seo_title: string;
  accessibility_description: string;
}

export interface ProductNavigationData {
  planogramName: string;
  productIdentifier: string;
  productName?: string;
  overlayView?: PRODUCT_PAGE_ROUTES;
}

export enum HP_OVERLAY_THEMES {
  ROBE = 'Personalised Robe',
  SEEKER_TSHIRT = 'Personalised House Seeker T-Shirt',
  TRIWIZARD_SHIRT = 'Personalised Triwizard Shirt',
  CREST_KEYRING = 'Personalised Hogwarts Crest Keyring',
  LETTER_KEYCHAIN = 'Personalised Acceptance Letter Charm',
  NOTEBOOK = 'Personalised Embossed Notebook',
  TRUNK = 'Gift Trunk',
  FROG = 'Chocolate Frog',
  ACCEPTANCE_LETTER = 'Personalised Hogwarts Acceptance letter',
  HOGWARTS_TRUNK_US = 'Hogwarts trunk US',
  DEFAULT = 'default_theme'
}
