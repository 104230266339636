import {PAGE_LOADING_TYPES} from './shared/constants';

interface PageLoadingSpeedFunctions {
  LOADING: (type: string) => string;
  LOADED: (type: string) => string;
  LOADING_TIME: (type: string) => string;
}

const PAGE_LOADING_SPEED: PageLoadingSpeedFunctions = {
  LOADING: type => `${type}Loading`,
  LOADED: type => `${type}Loaded`,
  LOADING_TIME: type => `${type}LoadingTime`
};

export class PageLoadingSpeed {
  private static clearMeasure(type: PAGE_LOADING_TYPES, id = '') {
    window.performance.clearMarks(`${PAGE_LOADING_SPEED.LOADING(type)}${id}`);
    window.performance.clearMarks(`${PAGE_LOADING_SPEED.LOADED(type)}${id}`);
    window.performance.clearMeasures(`${PAGE_LOADING_SPEED.LOADING_TIME(type)}${id}`);
  }

  static startMeasure(type: PAGE_LOADING_TYPES, id = '') {
    window.performance.mark(`${PAGE_LOADING_SPEED.LOADING(type)}${id}`);
  }

  static completeMeasure(type: PAGE_LOADING_TYPES, id = '') {
    window.performance.mark(`${PAGE_LOADING_SPEED.LOADED(type)}${id}`);
    window.performance.measure(
      `${PAGE_LOADING_SPEED.LOADING_TIME(type)}${id}`,
      `${PAGE_LOADING_SPEED.LOADING(type)}${id}`,
      `${PAGE_LOADING_SPEED.LOADED(type)}${id}`
    );
    const {duration} = window.performance.getEntriesByName(
      `${PAGE_LOADING_SPEED.LOADING_TIME(type)}${id}`
    )[0];
    const durationInSeconds = (duration / 1000).toFixed(2) + 's';

    console.debug(`Loading time for ${type}: ${durationInSeconds}`);

    PageLoadingSpeed.clearMeasure(type, id);
  }
}
