import type { CylinderPoint, Modulo } from 'shared/utils/modulo.util';
import { CylinderBox } from 'shared/utils/modulo.util';

import { assertDefined } from './debug';

const BUCKET_GROWTH_RATE = 0.4;
const BUCKET_MERGE_THRESHOLD = 0.5;

function middlePoint(a: number, b: number, fallback: number) {
  const clampedA = a === -Infinity ? -fallback : a;
  const clampedB = b === +Infinity ? fallback : b;
  return 0.5 * (clampedA + clampedB);
}

const ZERO_SIZE = {
  x: 0,
  y: 0,
};

interface Branch<M extends number, Data> {
  children: Branch<M, Data>[];
  parent: Branch<M, Data> | undefined;
  box: CylinderBox<M>;
  reducedData: Data;
  points: CylinderPoint<M>[];
  data: Data[];
  size: number;
}

interface RatingSearchResult<Data> {
  rating: number;
  data: Data | undefined;
}

export default class CylinderQuadTree<M extends number, Data> implements Modulo<M> {
  root: Branch<M, Data>;

  private bucketSize = 1;

  constructor(
    public divisor: M,
    private leafReducer: (children: Data[]) => Data,
    private defaultData: () => Data,
    fullArea: CylinderBox<M>,
  ) {
    this.root = {
      children: [],
      parent: undefined,
      box: fullArea,
      reducedData: defaultData(),
      points: [],
      data: [],
      size: 0,
    };
    this.updateBucketSize();
  }

  private pickChild(branch: Branch<M, Data>, point: CylinderPoint<M>) {
    const child = branch.children.find(child => child.box.contains(point));
    assertDefined(child, 'Invalid tree structure');
    return child;
  }

  private splitLeaf(branch: Branch<M, Data>) {
    const splitAnchor = branch.box.center();
    splitAnchor.y = middlePoint(...branch.box.y, this.divisor * 0.5);
    branch.children = CylinderBox.splitIntoQuadrants(branch.box, splitAnchor).map(box => ({
      children: [],
      parent: branch,
      box,
      points: [],
      data: [],
      reducedData: this.defaultData(),
      size: 0,
    }));
    for (let i = 0; i < branch.points.length; i++) {
      const child = this.pickChild(branch, branch.points[i]);
      child.points.push(branch.points[i]);
      child.data.push(branch.data[i]);
    }
    branch.children.forEach(child => {
      child.reducedData = this.leafReducer(child.data);
    });
    branch.points.splice(0, branch.points.length);
    branch.data.splice(0, branch.data.length);
  }

  private mergeLeaves(branch: Branch<M, Data>) {
    branch.children.forEach(it => {
      if (it.children.length > 0) this.mergeLeaves(it);
    });
    branch.data = branch.children.reduce<Data[]>((data, it) => data.concat(it.data), []);
    branch.points = branch.children.reduce<CylinderPoint<M>[]>(
      (points, it) => points.concat(it.points),
      [],
    );
    branch.children = [];
  }

  private findLeaf(point: CylinderPoint<M>) {
    let branch = this.root;
    while (branch.children.length > 0) branch = this.pickChild(branch, point);
    return branch;
  }

  private updateReducedData(branch: Branch<M, Data> | undefined) {
    let it = branch;
    if (it !== undefined && it.children.length === 0) {
      it.reducedData = this.leafReducer(it.data);
      it.size = it.data.length;
      it = it.parent;
    }
    while (it !== undefined) {
      it.reducedData = this.leafReducer(it.children.map(it => it.reducedData));
      it.size = it.children.reduce((totalSize, it) => totalSize + it.size, 0);
      it = it.parent;
    }
  }

  find(point: CylinderPoint<M>, filter: (data: Data) => boolean) {
    const leaf = this.findLeaf(point);
    return leaf.data.find(filter);
  }

  private updateBucketSize() {
    this.bucketSize = Math.max(1, Math.ceil(this.root.size ** BUCKET_GROWTH_RATE));
  }

  insert(point: CylinderPoint<M>, data: Data) {
    const leaf = this.findLeaf(point);
    leaf.points.push(point);
    leaf.data.push(data);
    if (leaf.points.length > this.bucketSize) this.splitLeaf(leaf);
    this.updateReducedData(leaf);
    this.updateBucketSize();
  }

  remove(point: CylinderPoint<M>, filter: (data: Data) => boolean) {
    const leaf = this.findLeaf(point);
    const i = leaf.data.findIndex(filter);
    leaf.points.splice(i, 1);
    leaf.data.splice(i, 1);
    this.updateReducedData(leaf);
    this.updateBucketSize();
    if (leaf.parent !== undefined && leaf.parent.size < this.bucketSize * BUCKET_MERGE_THRESHOLD)
      this.mergeLeaves(leaf.parent);
  }

  updateData(point: CylinderPoint<M>, update: (it: Data) => Data) {
    const leaf = this.findLeaf(point);
    leaf.points.forEach((it, i) => {
      if (it.isEqual(point)) leaf.data[i] = update(leaf.data[i]);
    });
    this.updateReducedData(leaf);
  }

  private findLowestRatingDfs(
    branch: Branch<M, Data>,
    rate: (data: Data, box: CylinderBox<M>) => number,
    maximumRating: number,
  ): RatingSearchResult<Data> {
    let best: RatingSearchResult<Data> = {
      rating: maximumRating,
      data: undefined,
    };
    // TODO: sort branches by rating before running findLowestRatingDfs on them
    for (const child of branch.children) {
      if (rate(child.reducedData, child.box) >= best.rating) continue;
      const childBest = this.findLowestRatingDfs(child, rate, best.rating);
      if (childBest.data !== undefined && childBest.rating < best.rating) best = childBest;
    }
    const box = new CylinderBox<M>(this.divisor);
    for (let i = 0; i < branch.data.length; i++) {
      const data = branch.data[i];
      box.fromCenterAndSize(branch.points[i], ZERO_SIZE);
      const rating = rate(data, box);
      if (rating < best.rating) {
        best.rating = rating;
        best.data = data;
      }
    }
    return best;
  }

  findLowestRating(rate: (data: Data, box: CylinderBox<M>) => number): Data | undefined {
    return this.findLowestRatingDfs(this.root, rate, +Infinity).data;
  }

  private slowFindDfs(filter: (it: Data) => boolean, branch: Branch<M, Data>): Data | undefined {
    for (const child of branch.children) {
      const result = this.slowFindDfs(filter, child);
      if (result !== undefined) return result;
    }
    for (const it of branch.data) {
      if (filter(it)) return it;
    }
    return undefined;
  }

  slowFind(filter: (it: Data) => boolean) {
    return this.slowFindDfs(filter, this.root);
  }

  slowFilter(filter: (it: Data) => boolean) {
    const result = new Array<Data>();
    const queue = new Array<Branch<M, Data>>();
    queue.push(this.root);
    while (queue.length > 0) {
      const branch = queue.pop()!;
      for (const child of branch.children) queue.push(child);
      for (const it of branch.data) {
        if (filter(it)) result.push(it);
      }
    }
    return result;
  }
}
