import { assertStatement } from "shared/utils/debug";

import { floatEq } from "shared/utils/float-util";

function clamp(value: number, min: number, max: number) {
  return Math.min(max, Math.max(min, value));
}

function farthestFrom(value: number, options: number[]) {
  return options.reduce((farthest, it) =>
    Math.abs(it - value) < Math.abs(farthest - value) ? farthest : it
  );
}

function positiveModulo(dividend: number, divisor: number) {
  return ((dividend % divisor) + divisor) % divisor;
}

export interface Modulo<M extends number> {
  readonly divisor: M;
}

export class Range<M extends number> implements Modulo<M> {
  readonly divisor: M;

  /** valid values: [0; M] */
  start: number;
  /** valid values: [0; M] */
  length: number;

  end(): number {
    return new Arithmetic(this.divisor).normalize(this.start + this.length);
  }

  center(): number {
    return new Arithmetic(this.divisor).normalize(
      this.start + this.length * 0.5
    );
  }

  constructor(divisor: M) {
    this.divisor = divisor;
    this.start = divisor;
    this.length = 0;
  }

  set(start: number, length: number) {
    this.start = start;
    this.length = length;
    return this;
  }

  fromStartEnd(start: number, end: number) {
    this.start = start;
    this.length = new Arithmetic(this.divisor).rightDistance(start, end);
    return this;
  }

  copy(r: Range<M>) {
    this.start = r.start;
    this.length = r.length;
    return this;
  }

  clone() {
    return new Range(this.divisor).copy(this);
  }

  negate() {
    this.start = this.end();
    this.length = this.divisor - this.length;
    return this;
  }

  intersects(r: Range<M>): boolean {
    const arithmetic = new Arithmetic(this.divisor);
    return (
      arithmetic.rightDistance(this.start, r.start) < this.length ||
      arithmetic.rightDistance(r.start, this.start) < r.length
    );
  }

  contains(x: number): boolean {
    return (
      new Arithmetic(this.divisor).rightDistance(this.start, x) <= this.length
    );
  }

  clamp(x: number): number {
    if (this.contains(x)) return x;
    else return this.clampToEdge(x);
  }

  clampToEdge(x: number): number {
    const arithmetic = new Arithmetic(this.divisor);
    if (
      arithmetic.rightDistance(this.end(), x) <
      arithmetic.rightDistance(x, this.start)
    )
      return this.end();
    else return this.start;
  }

  farthestFrom(x: number): number {
    const arithmetic = new Arithmetic(this.divisor);
    const halfCylinderAway = arithmetic.normalize(x + this.divisor * 0.5);
    if (this.contains(halfCylinderAway)) return halfCylinderAway;
    if (arithmetic.distance(x, this.start) < arithmetic.distance(x, this.end()))
      return this.end();
    else return this.start;
  }

  /** Note: isn't associative */
  static boundingRange<M extends number>(
    divisor: M,
    ranges: Range<M>[]
  ): Range<M> {
    const arithmetic = new Arithmetic(divisor);

    const orderedRanges = ranges
      .flatMap((r) => {
        if (r.start + r.length < divisor) return [r.clone()];
        else
          return [
            new Range(divisor).set(r.start, divisor - r.start),
            new Range(divisor).set(0, r.end()),
          ];
      })
      .sort((a, b) => a.start - b.start)
      .reduce<Range<M>[]>((result, r) => {
        if (result.length === 0) {
          result.push(r);
          return result;
        }
        const last = result[result.length - 1];
        if (last.intersects(r))
          // merging like this only works because we've split ranges crossing M
          last.length = Math.max(
            last.length,
            arithmetic.rightDistance(last.start, r.end())
          );
        else result.push(r);
        return result;
      }, []);

    function rightDistance(a: Range<M>, b: Range<M>) {
      return Math.max(0, arithmetic.rightDistance(a.start, b.start) - a.length);
    }

    const first = orderedRanges[0];
    const last = orderedRanges[orderedRanges.length - 1];
    let largestDistance = rightDistance(last, first);
    const largestExclusion = new Range(divisor).fromStartEnd(
      last.end(),
      first.start
    );
    for (let i = 0; i < orderedRanges.length - 1; i++) {
      const left = orderedRanges[i];
      const right = orderedRanges[i + 1];
      const distance = rightDistance(left, right);
      if (distance > largestDistance) {
        largestDistance = distance;
        largestExclusion.fromStartEnd(left.end(), right.start);
      }
    }

    return largestExclusion.negate();
  }
}

export interface Vector {
  x: number;
  y: number;
}

export interface Box {
  min: Vector;
  max: Vector;
}

export class CylinderPoint<M extends number> implements Modulo<M> {
  readonly divisor: M;

  x: number;
  y: number;

  constructor(divisor: M) {
    this.divisor = divisor;
  }

  set(x: number, y: number) {
    const arithmetic = new Arithmetic(this.divisor);
    this.x = arithmetic.normalize(x);
    this.y = y;
    return this;
  }

  copy(p: CylinderPoint<M>) {
    this.x = p.x;
    this.y = p.y;
    return this;
  }

  clone() {
    return new CylinderPoint(this.divisor).copy(this);
  }

  add(v: Vector) {
    const arithmetic = new Arithmetic(this.divisor);
    this.x = arithmetic.normalize(this.x + v.x);
    this.y += v.y;
    return this;
  }

  sub(v: Vector) {
    const arithmetic = new Arithmetic(this.divisor);
    this.x = arithmetic.normalize(this.x - v.x);
    this.y -= v.y;
    return this;
  }

  divide(v: Vector) {
    const arithmetic = new Arithmetic(this.divisor);
    this.x = v.x === 0 ? 0 : arithmetic.normalize(this.x / v.x);
    this.y = v.y === 0 ? 0 : this.x / v.y;
    return this;
  }

  isEqual(v: Vector) {
    const arithmetic = new Arithmetic(this.divisor);
    const x1 = arithmetic.normalize(this.x);
    const x2 = arithmetic.normalize(v.x);

    return floatEq(x1, x2) && floatEq(this.y, v.y);
  }

  vectorTo<V extends Vector>(p: CylinderPoint<M>, v: V): V {
    const arithmetic = new Arithmetic(this.divisor);
    v.x = arithmetic.signedDistance(this.x, p.x);
    v.y = p.y - this.y;
    return v;
  }

  /** keeps X in [0; M] range  */
  toVector<V extends Vector>(v: V): V {
    const arithmetic = new Arithmetic(this.divisor);
    v.x = arithmetic.normalize(this.x);
    v.y = this.y;
    return v;
  }

  /** keeps X in [origin.x - M/2; origin.x + M/2]  */
  toVectorAround<V1 extends Vector, V2 extends Vector>(v: V1, origin: V2): V1 {
    const arithmetic = new Arithmetic(this.divisor);
    v.x = arithmetic.normalizeAround(this.x, origin.x);
    v.y = this.y;
    return v;
  }

  fromVector<V extends Vector>(v: V): CylinderPoint<M> {
    this.set(v.x, v.y);
    return this;
  }

  edit(func: (p: CylinderPoint<M>) => void) {
    func(this);
    return this;
  }

  distance(p: CylinderPoint<M>): number {
    const arithmetic = new Arithmetic(this.divisor);
    const xDistance = arithmetic.distance(this.x, p.x);
    const yDistance = this.y - p.y;
    return Math.sqrt(xDistance * xDistance + yDistance * yDistance);
  }

  toString() {
    return `[CylinderPoint (${this.x}, ${this.y}) mod ${this.divisor}]`;
  }

  get [Symbol.toStringTag]() {
    return this.toString();
  }
}

export class CylinderBox<M extends number> implements Modulo<M> {
  readonly divisor: M;

  x: Range<M>;
  y: [number, number];

  private static emptyY(): [number, number] {
    return [+Infinity, -Infinity];
  }

  constructor(divisor: M) {
    this.divisor = divisor;
    this.x = new Range(divisor);
    this.y = CylinderBox.emptyY();
  }

  start(): CylinderPoint<M> {
    return new CylinderPoint(this.divisor).set(this.x.start, this.y[0]);
  }

  end(): CylinderPoint<M> {
    return new CylinderPoint(this.divisor).set(this.x.end(), this.y[1]);
  }

  set(x: Range<M>, y: [number, number]) {
    this.x.copy(x);
    this.y[0] = y[0];
    this.y[1] = y[1];
    return this;
  }

  copy(b: CylinderBox<M>) {
    this.x.copy(b.x);
    this.y[0] = b.y[0];
    this.y[1] = b.y[1];
    return this;
  }

  clone() {
    return new CylinderBox(this.divisor).copy(this);
  }

  fromBox2(b: Box) {
    const arithmetic = new Arithmetic(this.divisor);
    this.x.set(arithmetic.normalize(b.min.x), b.max.x - b.min.x);
    [this.y[0], this.y[1]] = [b.min.y, b.max.y];
    return this;
  }

  fromCenterAndSize(center: Vector, size: Vector) {
    const absSize = { x: Math.abs(size.x), y: Math.abs(size.y) };
    const arithmetic = new Arithmetic(this.divisor);
    this.x.set(arithmetic.normalize(center.x - absSize.x * 0.5), absSize.x);
    this.y[0] = center.y - absSize.y * 0.5;
    this.y[1] = this.y[0] + absSize.y;
    return this;
  }

  fromStartEnd(start: CylinderPoint<M>, end: CylinderPoint<M>) {
    this.x.fromStartEnd(start.x, end.x);
    this.y[0] = start.y;
    this.y[1] = end.y;
    return this;
  }

  /** keeps the center X in [-M/2; M/2] range */
  toBox2<B extends Box>(b: B): B {
    const arithmetic = new Arithmetic(this.divisor);
    const halfLengthX = this.x.length * 0.5;
    const centerX = arithmetic.normalizeAroundZero(this.x.start + halfLengthX);
    b.min.x = centerX - halfLengthX;
    b.max.x = centerX + halfLengthX;
    b.min.y = this.y[0];
    b.max.y = this.y[1];
    return b;
  }

  toBoxAround<B extends Box, V extends Vector>(b: B, origin: V): B {
    const arithmetic = new Arithmetic(this.divisor);
    const halfLengthX = this.x.length * 0.5;
    const centerX = arithmetic.normalizeAround(
      this.x.start + halfLengthX,
      origin.x
    );
    b.min.x = centerX - halfLengthX;
    b.max.x = centerX + halfLengthX;
    b.min.y = this.y[0];
    b.max.y = this.y[1];
    return b;
  }

  center(): CylinderPoint<M> {
    return new CylinderPoint(this.divisor).set(
      this.x.center(),
      (this.y[0] + this.y[1]) * 0.5
    );
  }

  size<V extends Vector>(v: V): V {
    v.x = this.x.length;
    v.y = this.y[1] - this.y[0];
    return v;
  }

  intersectsBox(box: CylinderBox<M>) {
    const verticallyIntersects = this.y[1] > box.y[0] && this.y[0] < box.y[1];
    return verticallyIntersects && this.x.intersects(box.x);
  }

  contains(point: CylinderPoint<M>) {
    const verticallyIn = this.y[0] <= point.y && point.y <= this.y[1];
    return verticallyIn && this.x.contains(point.x);
  }

  clamp(p: CylinderPoint<M>): CylinderPoint<M> {
    return p.clone().set(this.x.clamp(p.x), clamp(p.y, this.y[0], this.y[1]));
  }

  farthestFrom(p: CylinderPoint<M>): CylinderPoint<M> {
    return p.clone().set(this.x.farthestFrom(p.x), farthestFrom(p.y, this.y));
  }

  toString() {
    return `[CylinderBox (${this.x.start}, ${this.y[0]}) -> (${this.x.end()}, ${
      this.y[1]
    }) mod ${this.divisor}]`;
  }

  get [Symbol.toStringTag]() {
    return this.toString();
  }

  /** Note: is not associative */
  static boundingBox<M extends number>(divisor: M, boxes: CylinderBox<M>[]) {
    if (boxes.length === 0) return new CylinderBox(divisor);
    const x = Range.boundingRange(
      divisor,
      boxes.map((b) => b.x)
    );
    const y = boxes
      .map((b) => b.y)
      .reduce((result, r) => {
        result[0] = Math.min(result[0], r[0]);
        result[1] = Math.max(result[1], r[1]);
        return result;
      }, CylinderBox.emptyY());
    return new CylinderBox<M>(boxes[0].divisor).set(x, y);
  }

  static splitIntoQuadrants<M extends number>(
    box: CylinderBox<M>,
    splitAnchor: CylinderPoint<M>
  ): [CylinderBox<M>, CylinderBox<M>, CylinderBox<M>, CylinderBox<M>] {
    const divisor = box.divisor;
    const start = box.start();
    const end = box.end();
    assertStatement(
      () => box.contains(splitAnchor),
      "Split anchor must be inside the box"
    );
    return [
      new CylinderBox(divisor).fromStartEnd(start, splitAnchor),
      new CylinderBox(divisor).fromStartEnd(
        new CylinderPoint(divisor).set(start.x, splitAnchor.y),
        new CylinderPoint(divisor).set(splitAnchor.x, end.y)
      ),
      new CylinderBox(divisor).fromStartEnd(splitAnchor, end),
      new CylinderBox(divisor).fromStartEnd(
        new CylinderPoint(divisor).set(splitAnchor.x, start.y),
        new CylinderPoint(divisor).set(end.x, splitAnchor.y)
      ),
    ];
  }
}

export class Arithmetic<M extends number> implements Modulo<M> {
  readonly divisor: M;

  constructor(divisor: M) {
    this.divisor = divisor;
  }

  /** in range [0; M] */
  normalize(a: number): number {
    return positiveModulo(a, this.divisor);
  }

  /** in range [origin - M/2; origin + M/2] */
  normalizeAround(a: number, origin: number): number {
    const offset = this.divisor * 0.5 - origin;
    return this.normalize(a + offset) - offset;
  }

  /** in range [-M/2; M/2] */
  normalizeAroundZero(a: number): number {
    return this.normalizeAround(a, 0);
  }

  distance(a: number, b: number): number {
    return Math.min(this.normalize(a - b), this.normalize(b - a));
  }

  signedDistance(a: number, b: number): number {
    const straight = b - a;
    const left = -a - this.divisor + b;
    const right = this.divisor - a + b;
    return [straight, left, right].reduce((result, path) =>
      Math.abs(result) < Math.abs(path) ? result : path
    );
  }

  /** distance for "moving" only in positive direction */
  rightDistance(a: number, b: number): number {
    return this.normalize(b - a);
  }
}
